import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { RouterModule } from "@angular/router";
import { WidgetsModule } from "../widgets/widgets.module";
import { TranslateModule } from "@ngx-translate/core";
import { AccessRouteModule } from "./access-routes";
import { AccountService } from "../shared/services/account.service";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout.component";
import { RequestPasswordComponent } from "./request-password/request-password.component";
import { CompleteRegisterComponent } from "./complete-register/complete-register.component";
import { CoachUpdatePasswordComponent } from "./coach-update-password/coach-update-password.component";
import { DialogComponent } from "./login/dialog/dialog.component";
import { MfaLoginComponent } from "./login/mfa-login/mfa-login.component";
import { TopNavModule } from "../top-nav/top-nav.module";
import { ChooseAdventureComponent } from "./choose-adventure/choose-adventure.component";
import { CountdownComponent } from "./countdown/countdown.component";
import { ProvisioningService } from "app/shared/services/provisioning.service";

// Angular Material Modules
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { UIComponentsModule } from "@cariloop/ui-components";
import { MobileAppCtaComponent } from "./login/mobile-app-cta/mobile-app-cta.component";
import { CtaMobileComponent } from "./login/cta-mobile/cta-mobile.component";
import { PasswordFieldComponent } from "app/shared/components/password-field/password-field.component";

@NgModule({
  imports: [
    TranslateModule.forChild(),
    CommonModule,
    RouterModule,
    AccessRouteModule,
    FormsModule,
    WidgetsModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    TopNavModule,
    MatDialogModule,
    UIComponentsModule,
    PasswordFieldComponent,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    RequestPasswordComponent,
    CompleteRegisterComponent,
    CoachUpdatePasswordComponent,
    CountdownComponent,
    ChooseAdventureComponent,
    DialogComponent,
    MfaLoginComponent,
    MobileAppCtaComponent,
    CtaMobileComponent,
  ],
  exports: [
    LoginComponent,
    LogoutComponent,
    RequestPasswordComponent,
    CompleteRegisterComponent,
  ],
  providers: [AccountService, ProvisioningService],
})
export class AccessModule {}
