import { inject, Injectable } from "@angular/core";
import type { MfaType } from "app/access/login/mfa-login/mfa-type.type";
import {
  type LoginCredentials,
  MfaService,
} from "app/shared/services/mfa.service";
import { BehaviorSubject, of } from "rxjs";
import { catchError, map, startWith, switchMap, tap } from "rxjs/operators";

export type OnResendMfaEmailSuccess = (newAccountMfaCodeToken: string) => void;

export type ResendMfaEmailSubjectParams = {
  loginCredentials: LoginCredentials;
  mfaType: MfaType;
  onResendMfaEmailSuccess: OnResendMfaEmailSuccess;
};

@Injectable()
export class ResendMfaEmailService {
  readonly #mfaService = inject(MfaService);

  readonly #resendCodeSubject =
    new BehaviorSubject<null | ResendMfaEmailSubjectParams>(null);
  readonly #resendCodeAction$ = this.#resendCodeSubject.asObservable();

  readonly resendCode$ = this.#resendCodeAction$.pipe(
    switchMap((variables) => {
      if (variables === null) {
        return of({ state: "idle" as const });
      }

      const { loginCredentials, mfaType, onResendMfaEmailSuccess } = variables;

      if (mfaType === "none" || mfaType === "sms") {
        return of({
          state: "error" as const,
          error: new Error("Email MFA is not enabled. Won't send code."),
          variables,
        });
      }

      const request$ = this.#mfaService.sendMfaEmail(loginCredentials).pipe(
        map((httpResponse) => ({
          state: "success" as const,
          response: httpResponse,
          variables,
        })),
        tap(({ response: { accountMfaCodeToken } }) => {
          onResendMfaEmailSuccess(accountMfaCodeToken);
        }),
      );

      return request$.pipe(
        startWith({
          state: "pending" as const,
        }),
        catchError((errorResponse) => {
          return of({
            state: "error" as const,
            error: errorResponse,
            variables,
          });
        }),
      );
    }),
  );

  handleResendCode(subjectParams: ResendMfaEmailSubjectParams) {
    this.#resendCodeSubject.next(subjectParams);
  }
}
