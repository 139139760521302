import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { UIComponentsModule } from '@cariloop/ui-components';
import { GiftLandingPageService } from 'app/gift-redemption/gift-landing-page/gift-landing-page.service';
import type { Gift } from 'app/shared/types/gift.type';
import type { Subscription } from 'rxjs';

@Component({
  selector: 'app-gift-landing-page-component',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgOptimizedImage, UIComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./gift-landing-page.component.scss'],
  templateUrl: './gift-landing-page.component.html',
})
export class GiftLandingPageComponent implements OnInit, OnDestroy {
  readonly #giftRedemptionService = inject(GiftLandingPageService);

  @Input({ required: true })
  gift: Gift;

  protected readonly isNowBeforeLaunchDate$ =
    this.#giftRedemptionService.isNowBeforeLaunchDate$;

  #redeemGiftRedirectSubscription: null | Subscription = null;

  ngOnInit() {
    this.#redeemGiftRedirectSubscription =
      this.#giftRedemptionService.redeemGiftRedirect$.subscribe();
  }

  ngOnDestroy() {
    this.#redeemGiftRedirectSubscription?.unsubscribe();
  }

  handleRedeemGiftClick() {
    this.#giftRedemptionService.redeemGiftSubject.next();
  }
}
