import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { GiftLandingPageComponent } from 'app/gift-redemption/gift-landing-page/gift-landing-page.component';
import type { AccountCreationFormSubmittedEventEmitterData } from 'app/register/account-creation/account-creation.component';
import { RegisterModule } from 'app/register/register.module';
import { GiftService } from 'app/shared/services/gift.service';
import { OrganziationService } from 'app/shared/services/organization.service';
import { ProvisioningService } from 'app/shared/services/provisioning.service';
import { environment } from 'environments/environment';
import type { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

const CREATE_ACCOUNT_STEP = 'create-account';
const EMAIL_VERIFICATION_STEP = 'email-verification';
const BACKUP_CONTACT_STEP = 'backup-contact';

type Step =
  | typeof CREATE_ACCOUNT_STEP
  | typeof EMAIL_VERIFICATION_STEP
  | typeof BACKUP_CONTACT_STEP;

type State = {
  allSteps: Step[];
  currentStep: Step;
};

@Component({
  selector: 'app-gift-redemption',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    NgOptimizedImage,
    GiftLandingPageComponent,
    RegisterModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngIf="gift$ | async as gift">
      <app-gift-landing-page-component
        *ngIf="(isNewAccountRegistrationEnabled$ | async) === false"
        [gift]="gift"
      />

      <app-register-layout
        *ngIf="isNewAccountRegistrationEnabled$ | async"
        [gift]="gift"
        [ngSwitch]="state().currentStep"
        flowMode="gift"
      >
        <app-registration-header
          [currentStep]="currentStepIndex() + 1"
          [hideStepCounter]="currentStepIndex() === 0"
          [totalSteps]="numberOfSteps()"
        />

        <account-creation
          (formSubmitted)="handleAccountCreationSubmit($event)"
          *ngSwitchCase="'create-account'"
          [voucherCode]="voucherCode"
        />

        <app-email-verification
          (nextStep)="goToNextStep()"
          *ngSwitchCase="'email-verification'"
        />

        <app-backup-contact
          (nextStep)="goToNextStep()"
          *ngSwitchCase="'backup-contact'"
        />
      </app-register-layout>
    </ng-container>
  `,
})
export class GiftRedemptionComponent {
  protected readonly state = signal<State>({
    allSteps: [CREATE_ACCOUNT_STEP, EMAIL_VERIFICATION_STEP],
    currentStep: CREATE_ACCOUNT_STEP,
  });

  protected readonly currentStepIndex = computed(() => {
    const { allSteps, currentStep } = this.state();

    return allSteps.findIndex((step) => step === currentStep);
  });

  protected readonly numberOfSteps = computed(
    () => this.state().allSteps.length,
  );

  readonly #route = inject(ActivatedRoute);
  readonly #giftService = inject(GiftService);
  readonly #organizationService = inject(OrganziationService);
  readonly #provisioningService = inject(ProvisioningService);

  protected readonly isNewAccountRegistrationEnabled$: Observable<boolean> =
    this.#organizationService.getgiftOrgId().pipe(
      switchMap((giftOrgId) =>
        this.#provisioningService.getProvisioningByOrg(giftOrgId).pipe(
          filter(
            ({ newAccountRegistration }) =>
              newAccountRegistration !== undefined,
          ),
          map(({ newAccountRegistration: { value } }) => value),
        ),
      ),
    );

  protected readonly voucherCode =
    this.#route.snapshot.queryParamMap.get('voucherCode');

  protected readonly gift$ = this.#giftService.getByVoucherCode(
    this.voucherCode,
  );

  protected goToNextStep() {
    const { isRegistrationCompleted, nextStep } = this.#getNextStep();

    if (isRegistrationCompleted) {
      window.location.href = `${environment.PLAN_URL}/home`;
      return;
    }

    this.state.update((currentState) => ({
      ...currentState,
      currentStep: nextStep,
    }));
  }

  protected handleAccountCreationSubmit({
    emailType,
  }: AccountCreationFormSubmittedEventEmitterData) {
    const { isRegistrationCompleted, nextStep } = this.#getNextStep();
    if (isRegistrationCompleted) {
      throw new Error('There should be more steps after the account creation');
    }

    this.state.update((currentState) => ({
      ...currentState,
      allSteps:
        emailType === 'work'
          ? [...currentState.allSteps, BACKUP_CONTACT_STEP]
          : currentState.allSteps,
      currentStep: nextStep,
    }));
  }

  #getNextStep() {
    const currentIndex = this.currentStepIndex();
    const nextStep = this.state().allSteps[currentIndex + 1];
    const isRegistrationCompleted = nextStep === undefined;

    return {
      isRegistrationCompleted,
      nextStep,
    };
  }
}
