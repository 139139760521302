import { ValidatorFn, UntypedFormGroup, ValidationErrors, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { Directive } from '@angular/core';

const passwordMatchValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const password = control.get('password');
  // const password = control && control.parent ? control.parent.get('password') : null;
  const passwordConfirmation = control.get('passwordConfirmation');
  if (!password || !passwordConfirmation) {
    return null;
  }
  return password.value !== passwordConfirmation.value ?
    { 'passwordsDoNotMatch': true } : null;
};

@Directive({
  selector: '[appPasswordMatch]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }]
})
export class PasswordMatchDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return passwordMatchValidator(control);
  }
}
