import { Component, OnInit, signal } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AnalyticsService } from "@cariloop/analytics";
import { AccountRegistrationService } from "app/shared/services/account-registration.service";
import { AccountService } from "app/shared/services/account.service";
import { DevicePlatformService } from "app/shared/services/device-platform.service";
import { Account } from "app/shared/types/account.type";
import { CompleteRegisterStep } from "app/shared/types/complete-register-step.type";
import { RegisteredEmail } from "app/shared/types/register.types";
import { environment } from "environments/environment";

type State = {
  currentStep: CompleteRegisterStep | undefined;
  index: number;
  total: number;
};

@Component({
  selector: "app-complete",
  templateUrl: "./complete.component.html",
  styleUrls: ["./complete.component.scss"],
})
export class CompleteComponent implements OnInit {
  missingSteps: CompleteRegisterStep[] = [];
  registeredEmail = null;

  state = signal<State>({
    currentStep: undefined,
    index: 0,
    total: 0,
  });

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private accountRegistrationService: AccountRegistrationService,
    private analyticsService: AnalyticsService,
    private devicePlatformService: DevicePlatformService,
  ) {
    const { missingSteps } = this.route.snapshot.data;
    this.missingSteps = missingSteps;
    this.state.update((state) => ({ ...state, total: missingSteps.length }));
    this.nextStep();
  }

  isMobileWebView =
    this.route.snapshot.queryParamMap.get("isMobileWebView") === "true";

  ngOnInit() {
    const logged = this.accountService.getLogged();
    if (logged instanceof Error) {
      throw logged;
    }
    if (logged.email && logged.emailType === "work") {
      this.registeredEmail = {
        address: logged.email,
        type: logged.emailType,
      };
    }
  }

  nextStep() {
    const isRegistrationCompleted = this.missingSteps.length === 0;

    if (
      isRegistrationCompleted &&
      globalThis.FlutterChannelRegistrationStepsCompleted !== undefined
    ) {
      this.accountService.logout().subscribe();

      globalThis.FlutterChannelRegistrationStepsCompleted.postMessage(
        "registration steps completed",
      );

      return;
    }

    if (isRegistrationCompleted) {
      this.analyticsService.trackEvent("registration_completed", {
        deviceType: this.devicePlatformService.getPlatformForMixpanel(),
        accountId: (this.accountService.getLogged() as Account).id,
        userFlow: "update-existing-account",
      });

      this.redirectToApp();
      return;
    }

    const nextStep = this.missingSteps.shift();
    if (nextStep == "email-verification") {
      this.sendVerificationCode();
    }
    return this.state.update((state) => ({
      ...state,
      index: state.index + 1,
      currentStep: nextStep,
    }));
  }

  handleBackupContactSubmit(eventEmitterData: RegisteredEmail) {
    this.registeredEmail = eventEmitterData;
    this.nextStep();
  }

  sendVerificationCode() {
    const logged = this.accountService.getLogged();
    if (logged instanceof Error) {
      throw logged;
    }

    this.accountRegistrationService.resendVerificationCode(logged.id).subscribe(
      () => {},
      (err) => console.log(err),
    );
  }

  redirectToApp(): void {
    const roles = this.accountService.getRoles();
    const isCoach = roles.some((role) =>
      [
        "$coach_admin",
        "$coach",
        "$coach_assistant",
        "$coach_admin_assistant",
        "$coach_intake",
      ].includes(role),
    );
    const isAdmin = roles.some((role) => role === "$admin");
    const isEngagement = roles.some((role) => role === "$engagement");

    if (isCoach) {
      window.location.href = environment.COACH_URL + "/dashboard";
    } else if (isAdmin) {
      window.location.href =
        "https://" + window.location.hostname + "/admin/dashboard";
    } else if (isEngagement) {
      window.location.href = `${environment.AUTH_URL}/engagement/`;
    } else {
      window.location.href = `${environment.PLAN_URL}/home`;
    }
  }
}
