import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, inject, Input } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import type { NewAccountRegistrationStep } from "app/register/register.component";
import type { Gift } from "app/shared/types/gift.type";

@Component({
  selector: "app-register-layout",
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: "./register-layout.component.html",
  styleUrls: ["./register-layout.component.scss"],
})
export class RegisterLayoutComponent {
  readonly #translate = inject(TranslateService);

  @Input() flowMode: "generic" | "gift" | "existing" = "generic";

  @Input()
  readonly gift: null | Gift = null;

  @Input()
  readonly currentStep: null | NewAccountRegistrationStep = null;

  protected getArtAreaTitle() {
    if (this.flowMode === "generic" || this.flowMode === "existing") {
      return this.#translate.instant("REGISTER.ART_GENERIC_TITLE");
    }

    if (this.flowMode === "gift" && this.gift !== null) {
      return this.#translate.instant("REGISTER.ART_GIFT_TITLE", {
        senderFirstName: this.gift.sender.firstName,
        productTerm: `${this.gift.product.term} ${this.#translate.instant(
          this.gift.product.term === 1
            ? "PLURALIZATION.MONTH.SINGULAR"
            : "PLURALIZATION.MONTH.PLURAL",
        )}`,
      });
    }
  }

  public benefitsList: { text: string; icon: string }[] = [
    {
      text: "REGISTER.BENEFIT_1",
      icon: "verified",
    },
    {
      text: "REGISTER.BENEFIT_2",
      icon: "tactic",
    },
    {
      text: "REGISTER.BENEFIT_3",
      icon: "lab_profile",
    },
    {
      text: "REGISTER.BENEFIT_4",
      icon: "forum",
    },
  ];
}
