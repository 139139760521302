import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewChecked,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { AnalyticsService } from "@cariloop/analytics";
import { isValidEmail } from "@cariloop/ui-components";
import { TranslateService } from "@ngx-translate/core";
import { LANGUAGES } from "app/languages";
import { AccountRegistrationService } from "app/shared/services/account-registration.service";
import { AccountService } from "app/shared/services/account.service";
import { AppTranslateService } from "app/shared/services/app-translate.service";
import {
  DevicePlatformService,
  MixPanelPlatform,
} from "app/shared/services/device-platform.service";
import {
  ProvisioningPasswordComplexity,
  ProvisioningService,
} from "app/shared/services/provisioning.service";
import type { EmailType } from "app/shared/types/account.type";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";

export type AccountCreationFormSubmittedEventEmitterData = {
  email: string;
  password: string;
  emailType: EmailType;
};

@Component({
  selector: "account-creation",
  templateUrl: "./account-creation.component.html",
  styleUrls: ["./account-creation.component.scss"],
})
export class AccountCreationComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() isMobileWebView = false;
  @Input() voucherCode: string;
  @Input() isSSO: boolean;
  @Input() ssoEmail: string;

  @Output()
  formSubmitted =
    new EventEmitter<AccountCreationFormSubmittedEventEmitterData>();
  @ViewChild("emailInput", { static: true, read: ElementRef })
  emailRef: ElementRef;
  @ViewChild("emailTooltip", { static: true, read: ElementRef })
  tooltip: ElementRef;

  readonly errorState = signal<{
    isErrorActive: boolean;
    errorMessage:
      | null
      | "REGISTER.GENERIC_FORM.ERRORS.ALREADY_REGISTERED"
      | "REGISTER.GENERIC_FORM.ERRORS.CONNECTION"
      | "REGISTER.GENERIC_FORM.ERRORS.GENERIC_SERVER_ERROR"
      | "REGISTER.GENERIC_FORM.ERRORS.PASSWORD_COMMON"
      | "REGISTER.GENERIC_FORM.ERRORS.GENERIC";
  }>({ isErrorActive: false, errorMessage: null });

  emailInput: HTMLElement;
  emailChanged: boolean = false;
  buttonText = "";
  focusedElement: HTMLElement;
  hidePassword = true;
  isLoading = false;
  isValidEmail = isValidEmail;
  language: { [key: string]: string } = { value: "en", label: "English" };
  subscriptions: Subscription[] = [];
  passwordComplexity: ProvisioningPasswordComplexity;
  platform: MixPanelPlatform;
  webinarId = this.route.snapshot.queryParamMap.get("webinar");
  webinarTitle = this.route.snapshot.queryParamMap.get("webinarTitle");

  creationForm = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    email: new FormControl("", [
      Validators.required,
      this.createEmailPatternValidator(),
    ]),
    emailType: new FormControl(""),
    termsAndPrivacy: new FormControl(false, [Validators.requiredTrue]),
    password: new FormControl("", [Validators.required]),
  });

  constructor(
    private accountService: AccountService,
    private appTranslate: AppTranslateService,
    private translate: TranslateService,
    private accountRegistrationService: AccountRegistrationService,
    private provisioningService: ProvisioningService,
    private analyticsService: AnalyticsService,
    private devicePlatformService: DevicePlatformService,
    private route: ActivatedRoute,
    private titleService: Title,
    private destroyRef: DestroyRef,
  ) {
    this.setLanguage();
  }

  ngOnInit() {
    this.translate
      .stream("REGISTER.PAGE_TITLE")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pageTitle) => {
        this.titleService.setTitle(pageTitle + " | Cariloop");
      });
    this.buttonText = this.translate.instant(
      this.isSSO ? "REGISTER.CONTINUE" : "REGISTER.GENERIC_FORM.CREATE_BTN",
    );
    this.provisioningService.getProfileGeneral().subscribe((res) => {
      this.passwordComplexity = res.passwordComplexity.value;
    });

    if (this.emailRef) {
      this.emailInput = this.emailRef.nativeElement;
    }
    this.emailInput
      ?.querySelector("input")
      .addEventListener("blur", () =>
        this.creationForm.controls["email"].updateValueAndValidity(),
      );

    const languageSubscription = this.appTranslate
      .onLangChange()
      .subscribe((lang: string) => this.setNewLang(lang));
    this.subscriptions.push(languageSubscription);
    this.translate.stream("REGISTER.GENERIC_FORM.EMAIL.TOOLTIP").subscribe(
      // nosemgrep
      (translation) =>
        (this.tooltip.nativeElement.querySelector(".tooltip-text").innerHTML =
          translation),
    );

    this.platform = this.devicePlatformService.getPlatformForMixpanel();
    this.analyticsService.trackEvent("create-account", {
      platform: this.platform,
      source: "auth",
    });
  }

  ngAfterViewChecked() {
    if (this.isSSO && this.ssoEmail && !this.emailChanged) {
      this.creationForm.get("email").setValue(this.ssoEmail);
      this.emailChanged = true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.emailInput?.querySelector("input").removeAllListeners("blur");
    this.titleService.setTitle("Cariloop");
  }

  onSubmit() {
    const emailType = this.creationForm.get("emailType");
    emailType.addValidators(Validators.required);
    emailType.updateValueAndValidity();
    this.creationForm.markAllAsTouched();
    this.creationForm.updateValueAndValidity();
    const enrollmentCode =
      this.route.snapshot.queryParamMap.get("enrollmentCode");

    if (!this.creationForm.valid) {
      return;
    }
    this.creationForm.disable();
    this.isLoading = true;
    const data = {
      firstName: this.creationForm.get("firstName").value,
      lastName: this.creationForm.get("lastName").value,
      email: this.creationForm.get("email").value,
      emailType: this.creationForm.get("emailType").value,
      password: this.creationForm.get("password").value,
      language: this.language.value,
      termsAcceptanceDate: new Date(),
    };
    if (!this.isSSO) {
      this.accountRegistrationService
        .createAccount({
          firstName: this.creationForm.get("firstName").value,
          lastName: this.creationForm.get("lastName").value,
          email: this.creationForm.get("email").value,
          emailType: this.creationForm.get("emailType").value,
          password: this.creationForm.get("password").value,
          language: this.language.value,
          termsAcceptanceDate: new Date(),
          voucherCode: this.voucherCode,
          TOSLastModifiedDate: environment.TOS_LAST_MODIFIED_DATE,
          enrollmentCode,
          ...(this.webinarId && { webinarCreationId: this.webinarId }),
        })
        .subscribe({
          next: async (data: any) => {
            const loginRes = await this.accountService
              .login({
                email: this.creationForm.get("email").value,
                password: this.creationForm.get("password").value,
              })
              .toPromise();
            if (loginRes instanceof Error) {
              throw loginRes;
            }

            this.analyticsService.trackEvent("create-account-complete", {
              platform: this.platform,
              source: "auth",
              ...(this.webinarId && { webinarId: this.webinarId }),
              ...(this.webinarTitle && {
                webinartitle: decodeURIComponent(this.webinarTitle),
              }),
            });

            localStorage.setItem("login-source", JSON.stringify("register"));

            this.formSubmitted.emit({
              email: this.creationForm.get("email").value,
              password: this.creationForm.get("password").value,
              emailType: this.creationForm.get("emailType").value as EmailType,
            });
          },
          error: (error) => {
            this.isLoading = false;
            const getErrorMessage = () => {
              if (error instanceof HttpErrorResponse) {
                if (error.status === 422) {
                  return "REGISTER.GENERIC_FORM.ERRORS.ALREADY_REGISTERED" as const;
                }

                if (error.status === 504) {
                  return "REGISTER.GENERIC_FORM.ERRORS.CONNECTION" as const;
                }

                return "REGISTER.GENERIC_FORM.ERRORS.GENERIC_SERVER_ERROR" as const;
              }

              return "REGISTER.GENERIC_FORM.ERRORS.GENERIC" as const;
            };
            // we only want to show the error on the password not in the gneeral error screen so we will reenable
            // the form and set the error on the password field
            if (
              error instanceof HttpErrorResponse &&
              error.status === 400 &&
              error.error?.error?.message ===
                "Password is too common or has been reported as compromised"
            ) {
              this.creationForm.enable();

              this.creationForm.get("password").setErrors({
                knownBadPassword: true,
              });
              return;
            }
            this.errorState.set({
              isErrorActive: true,
              errorMessage: getErrorMessage(),
            });

            this.creationForm.enable();
          },
        });
    } else {
      const logged = this.accountService.getLogged();
      if (logged instanceof Error) {
        this.isLoading = false;
        this.errorState.set({
          isErrorActive: true,
          errorMessage: "REGISTER.GENERIC_FORM.ERRORS.GENERIC",
        });
        this.creationForm.enable();
        return false;
      }
      this.accountService
        .updateAttributes(logged.id, data)
        .pipe(
          switchMap((res) =>
            this.accountRegistrationService.resendVerificationCode(logged.id),
          ),
        )
        .subscribe(
          async () => {
            const loginRes = await this.accountService
              .login({
                email: this.creationForm.get("email").value,
                password: this.creationForm.get("password").value,
              })
              .toPromise();
            if (loginRes instanceof Error) {
              throw loginRes;
            }

            this.analyticsService.trackEvent("create-account-complete", {
              platform: this.platform,
              source: "auth",
            });

            localStorage.setItem("login-source", JSON.stringify("register"));
            this.formSubmitted.emit({
              email: this.creationForm.get("email").value,
              password: this.creationForm.get("password").value,
              emailType: this.creationForm.get("emailType").value as EmailType,
            });
          },
          (error) => {
            this.isLoading = false;
            this.errorState.set({
              isErrorActive: true,
              errorMessage: "REGISTER.GENERIC_FORM.ERRORS.GENERIC",
            });
            this.creationForm.enable();
          },
        );
    }
  }

  changeLang(lang: string) {
    const newLanguage = this.setNewLang(lang);
    this.appTranslate.updateLang(lang);
    return newLanguage;
  }

  setLanguage() {
    const localLanguage = this.accountService.getLocalLanguage();
    if (localLanguage) {
      this.language =
        LANGUAGES.find((lang) => lang.value === localLanguage?.value) ??
        LANGUAGES[0];
    } else {
      this.language =
        LANGUAGES.find(
          (lang) => lang.value === window.navigator.language.substring(0, 2),
        ) ?? LANGUAGES[0];
      this.appTranslate.updateLang(this.language.value);
    }
  }

  setNewLang(lang: string) {
    const newLanguage = LANGUAGES.find((l) => l.value === lang);
    if (newLanguage) {
      this.accountService.setLocalLanguage(newLanguage);
      this.language = newLanguage;
    }
    return newLanguage;
  }

  private createEmailPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (this.emailInput?.querySelector("input") === document.activeElement) {
        return null;
      }

      return !isValidEmail(value) ? { pattern: true } : null;
    };
  }
}
