<app-black-container>
  <div class="content">
    <h2 class="request-password__title">
      {{ 'REQUEST_PASSWORD.SET_PASSWORD' | translate }}
    </h2>
    <p class="request-password__paragraph">
      {{'REQUEST_PASSWORD.PARAGRAPH' | translate: {email: accountToUpdate.email
      || ''} }}
    </p>
    <app-md-error-handler [error]="error" [context]="'request-password'">
    </app-md-error-handler>
    <form (ngSubmit)="onSubmit(form)" #form="ngForm" appPasswordMatch>
      <app-password-field
        [label]="'LOGIN.PASSWORD' | translate"
        #passwordRef="ngModel"
        [(ngModel)]="credentials.password"
        autocomplete="new-password"
        id="password"
        type="password"
        name="password"
        [required]="true"
        [passwordComplexity]="accountToUpdate?.provisioningProfile?.passwordComplexity?.value"
      >
        <p *ngIf="passwordRef?.errors?.required">
          {{ 'REQUEST_PASSWORD.REQUIRED_FIELD' | translate }}
        </p>
      </app-password-field>
      <div class="terms-of-service-container">
        <div class="checkbox__terms">
          <mat-checkbox
            name="terms"
            required
            [(ngModel)]="termsAccepted"
            #termsAcceptedRef="ngModel"
            [ngClass]="{
            invalid:
              termsAcceptedRef.errors?.required &&
              termsAcceptedRef.touched
          }"
            aria-labelledby="terms-description"
          >
          </mat-checkbox>
          <p id="terms-description">
            <span class="request-password__check_box_terms"
              >{{ 'GENERAL.AGREE' | translate }}</span
            >
            <a
              class="request-password__check_box_terms"
              href="https://www.cariloop.com/terms-of-service/"
              target="_blank"
              >{{ 'GENERAL.TOS' | translate }}</a
            >
            <span class="request-password__check_box_terms"
              >{{ 'GENERAL.AND' | translate }}</span
            >
            <a
              class="request-password__check_box_terms"
              href="https://www.cariloop.com/privacy-policy/"
              target="_blank"
              >{{ 'GENERAL.PRIVACY' | translate }}</a
            >
          </p>
        </div>
        <div role="alert">
          <span
            class="checkbox__error-text"
            *ngIf="
              termsAcceptedRef.errors?.required &&
              termsAcceptedRef.touched
            "
          >
            {{ "GENERAL.TOS_ERROR" | translate }}
          </span>
        </div>
      </div>
      <c-button
        type="submit"
        class="full-button"
        [text]="'REQUEST_PASSWORD.SET_PASSWORD' | translate"
        buttonStyle="fill"
        buttonType="neutral"
        size="large"
      ></c-button>
    </form>
  </div>
</app-black-container>
