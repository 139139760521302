import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DisconnectionComponent } from './disconnection.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [DisconnectionComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatCardModule
  ],
  exports: [DisconnectionComponent],
  providers: [],
})
export class DisconnectionModule {}
