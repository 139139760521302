import { Component, computed, effect, inject, signal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsService } from "@cariloop/analytics";
import type { AccountCreationFormSubmittedEventEmitterData } from "app/register/account-creation/account-creation.component";
import { AccountRegistrationService } from "app/shared/services/account-registration.service";
import { AccountService } from "app/shared/services/account.service";
import { DevicePlatformService } from "app/shared/services/device-platform.service";
import { Account } from "app/shared/types/account.type";
import type { RegisteredEmail } from "app/shared/types/register.types";
import { Token } from "app/shared/types/token.type";
import { environment } from "environments/environment";
import { Observable, Observer } from "rxjs";
import { map, switchMap } from "rxjs/operators";

const REGISTRATION_FLOWS = ["generic", "gift", "existing"];

const CREATE_ACCOUNT_STEP = "create-account";
const EMAIL_VERIFICATION_STEP = "email-verification";
const BACKUP_CONTACT_STEP = "backup-contact";
const ELIGIBILITY_CHECK_STEP = "eligibility-check";

export type NewAccountRegistrationStep =
  | typeof CREATE_ACCOUNT_STEP
  | typeof EMAIL_VERIFICATION_STEP
  | typeof BACKUP_CONTACT_STEP
  | typeof ELIGIBILITY_CHECK_STEP;

type State = {
  allSteps: NewAccountRegistrationStep[];
  currentStep: NewAccountRegistrationStep;
  registeredEmail: null | RegisteredEmail;
  wasInitialStateDerivedFromAccount: boolean;
};

function isStep(step: unknown): step is NewAccountRegistrationStep {
  return (
    typeof step === "string" &&
    [
      CREATE_ACCOUNT_STEP,
      EMAIL_VERIFICATION_STEP,
      BACKUP_CONTACT_STEP,
      ELIGIBILITY_CHECK_STEP,
    ].includes(step)
  );
}

function getAccountDependentState(
  account: Account,
  {
    isStateDerivedFromAccount,
    wasAccountCreatedAtCoachAddCase,
    hasCode,
  }: {
    isStateDerivedFromAccount: boolean;
    wasAccountCreatedAtCoachAddCase: boolean;
    hasCode: boolean;
  },
): State {
  const { emailVerifiedDate, emailType, memberId, email } = account;

  let allStepsBeforeFiltering = [
    CREATE_ACCOUNT_STEP,
    emailVerifiedDate === undefined ? EMAIL_VERIFICATION_STEP : null,
    emailType === "work" ? BACKUP_CONTACT_STEP : null,
    memberId === undefined ? ELIGIBILITY_CHECK_STEP : null,
  ];

  if (hasCode) {
    allStepsBeforeFiltering = [
      CREATE_ACCOUNT_STEP,
      emailVerifiedDate === undefined ? EMAIL_VERIFICATION_STEP : null,
    ];
  }

  const allSteps = wasAccountCreatedAtCoachAddCase
    ? allStepsBeforeFiltering
        .filter(isStep)
        .filter((step) => step === BACKUP_CONTACT_STEP)
    : allStepsBeforeFiltering.filter(isStep);

  const firstStepAfterAccountCreation = allSteps.find(
    (step) => step !== CREATE_ACCOUNT_STEP,
  );

  return {
    allSteps,
    currentStep: firstStepAfterAccountCreation,
    registeredEmail:
      emailType === undefined
        ? null
        : {
            type: emailType,
            address: email,
          },
    wasInitialStateDerivedFromAccount: isStateDerivedFromAccount,
  };
}

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  isSSO: boolean = false;
  ssoEmail: string;
  readonly #route = inject(ActivatedRoute);
  readonly #accountService = inject(AccountService);
  readonly #accountRegistrationService = inject(AccountRegistrationService);
  readonly #router = inject(Router);
  readonly #devicePlatform = inject(DevicePlatformService);

  #wasCreatedFromCoachAddCase =
    this.#route.snapshot.queryParamMap.get("wasCreatedFromCoachAddCase") ===
    "true";

  state = signal<State>(this.#getInitialState());

  currentStepIndex = computed(() => {
    const { allSteps, currentStep } = this.state();

    return allSteps.findIndex((step) => step === currentStep);
  });
  totalSteps = computed(() => this.state().allSteps.length);
  flow$ = this.#route.queryParams.pipe<"generic" | "gift" | "existing">(
    map((params) =>
      REGISTRATION_FLOWS.includes(params["flow"]) ? params["flow"] : "generic",
    ),
  );

  isMobileWebView =
    this.#route.snapshot.queryParamMap.get("isMobileWebView") === "true";

  webinar = this.#route.snapshot.queryParamMap.get("webinar");
  webinarTitle = this.#route.snapshot.queryParamMap.get("webinarTitle");

  isMobile = window.innerWidth < 768;

  #sendEmailVerificationCode() {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    this.#accountRegistrationService
      .resendVerificationCode(accountResult.id)
      .subscribe({
        error: (error) => {
          console.log(error);
        },
      });
  }

  constructor(
    private analyticsService: AnalyticsService,
    private accountService: AccountService,
  ) {
    this.#route.queryParams.subscribe((params) => {
      if (params.token && params.id) {
        const { token, id } = params;
        const account: Account = new Account();
        account.id = id;
        const tokenObject: Token = {
          id: token,
          ttl: "43200",
          user: account,
        };
        return this.#accountService
          .findBytoken(account, token)
          .pipe(
            switchMap(
              (accountRes: Account) =>
                new Observable((observer: Observer<boolean>) => {
                  tokenObject.user = accountRes;
                  this.#accountService.setSessionInfo(tokenObject, accountRes);
                  this.#accountService.trackLogin(accountRes);
                  this.isSSO = true;
                  observer.next(true);
                  observer.complete();
                }),
            ),
          )
          .subscribe(() => {
            const account = this.#accountService.getLogged();
            if (account instanceof Error) {
              return false;
            }
            this.ssoEmail = account.email;
          });
      }
    });
    const { missingSteps } = this.#route.snapshot.data;
    if (
      missingSteps?.length > 0 &&
      !missingSteps.includes("email-verification") &&
      this.state().allSteps.includes("email-verification")
    ) {
      this.goToNextStep(true);
      if (
        !missingSteps.includes("backup-contact") &&
        this.state().allSteps.includes("backup-contact")
      ) {
        this.goToNextStep(true);
      }
    }

    this.trackRegisterEvent();

    effect(() => {
      const { currentStep, wasInitialStateDerivedFromAccount } = this.state();

      if (
        currentStep === "email-verification" &&
        wasInitialStateDerivedFromAccount
      ) {
        this.#sendEmailVerificationCode();
      }
    });
  }

  goToNextStep(isSkip: boolean = false) {
    const currentIndex = this.currentStepIndex();
    const nextStep = this.state().allSteps[currentIndex + 1];
    const isRegistrationCompleted = nextStep === undefined;

    if (
      isRegistrationCompleted &&
      globalThis.FlutterChannelRegistrationStepsCompleted !== undefined
    ) {
      this.#accountService.logout().subscribe({
        next: (res) => {
          globalThis.FlutterChannelRegistrationStepsCompleted.postMessage(
            "registration steps completed",
          );
        },
      });

      return;
    }

    if (isRegistrationCompleted) {
      this.handleRegistrationCompleted();
      return;
    }

    this.state.update((currentState) => ({
      ...currentState,
      currentStep: nextStep,
    }));

    if (!isSkip) {
      this.trackRegisterEvent();
    }
  }

  async handleRegistrationCompleted() {
    const currentDate = new Date();
    const account = this.#accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }
    const org = await this.accountService.getAccountOrg(account.id).toPromise();
    localStorage.removeItem("login-source");
    this.analyticsService.trackEvent("registration_completed", {
      deviceType: this.isMobileWebView
        ? "mobile"
        : this.isMobile
          ? "mobile web"
          : "web",
      accountId: (this.accountService.getLogged() as Account).id,
      ...(this.webinar && { webinarId: this.webinar }),
      ...(this.webinarTitle && {
        webinarTitle: decodeURIComponent(this.webinarTitle),
      }),
      userFlow: "create-new-account",
    });

    if (this.webinar) {
      window.location.href = `${environment.AUTH_URL}/webinar/${this.webinar}?registration=complete`;
      return;
    } else if (
      org?.contractStartDate &&
      new Date(org.contractStartDate) > currentDate
    ) {
      this.analyticsService.trackEvent(
        "inactive_employer_redirected_from_register",
        {
          accountId: account.id,
          deviceType: this.#devicePlatform.getPlatformForMixpanel(),
        },
      );
      this.#router.navigate(["not-ready"], { relativeTo: this.#route });
      return;
    }
    window.location.href = `${environment.PLAN_URL}/home`;
  }

  handleAccountCreationSubmit(
    data: AccountCreationFormSubmittedEventEmitterData,
  ) {
    const accountResult = this.#accountService.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    const hasCode = this.#route.snapshot.queryParamMap.has("enrollmentCode");
    this.state.update((currentState) => ({
      ...currentState,
      ...getAccountDependentState(accountResult, {
        hasCode,
        isStateDerivedFromAccount: false,
        wasAccountCreatedAtCoachAddCase: this.#wasCreatedFromCoachAddCase,
      }),
    }));
    this.trackRegisterEvent();
    if (globalThis.FlutterChannelAccountCreationFinished !== undefined) {
      globalThis.FlutterChannelAccountCreationFinished.postMessage(
        JSON.stringify(data),
      );
    }
  }

  #getInitialState(): State {
    const accountResult = this.#accountService.getLogged();
    if (accountResult instanceof Error) {
      return {
        allSteps: [CREATE_ACCOUNT_STEP],
        currentStep: CREATE_ACCOUNT_STEP,
        registeredEmail: null,
        wasInitialStateDerivedFromAccount: false,
      };
    }
    const hasCode = this.#route.snapshot.queryParamMap.has("enrollmentCode");
    return getAccountDependentState(accountResult, {
      hasCode,
      isStateDerivedFromAccount: true,
      wasAccountCreatedAtCoachAddCase: this.#wasCreatedFromCoachAddCase,
    });
  }

  trackRegisterEvent() {
    const account = this.accountService.getLogged() as Account;
    const platform = this.isMobileWebView
      ? "mobile"
      : this.isMobile
        ? "mobile web"
        : "web";
    const eventParams = {
      accountId: account?.id,
      currentStep: this.state().currentStep,
      type: platform,
      userFlow: "create-new-account",
    };

    this.analyticsService.trackEvent("register-new-flow", eventParams);
  }
}
