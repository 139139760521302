import { Component, OnInit, inject } from "@angular/core";
import { AnalyticsService } from "@cariloop/analytics";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { DevicePlatformService } from "app/shared/services/device-platform.service";
import { environment } from "environments/environment";

const IMAGES_PATH: "../../../../assets/img" = "../../../../assets/img";
const DEV_QR: "lower-env-qr-code.svg" = "lower-env-qr-code.svg";
const PROD_QR: "prod-qr-code.svg" = "prod-qr-code.svg";

@Component({
  selector: "app-cta-mobile",
  templateUrl: "./cta-mobile.component.html",
  styleUrls: ["./cta-mobile.component.scss"],
})
export class CtaMobileComponent implements OnInit {
  readonly #analyticsService = inject(AnalyticsService);
  readonly #translate = inject(TranslateService);
  readonly #devicePlatformService = inject(DevicePlatformService);

  lang = "en";

  ngOnInit(): void {
    this.lang = this.#translate.currentLang || this.#translate.getDefaultLang();
    this.#translate.onLangChange.subscribe((lang: LangChangeEvent) => {
      this.lang = lang.lang;
    });
  }

  downloadAppClicked(type: "apple" | "google") {
    const eventType =
      type === "apple"
        ? "apple_app_store_clicked"
        : "google_play_store_clicked";
    const deviceType = this.#devicePlatformService.getDeviceType();

    this.#analyticsService.trackEvent(eventType, {
      timestamp: new Date().toISOString(),
      source: "auth",
      deviceType,
    });
  }

  protected getQrUrl(): string {
    if (environment.envName === "production") {
      return `${IMAGES_PATH}/${PROD_QR}`;
    }

    return `${IMAGES_PATH}/${DEV_QR}`;
  }
}
