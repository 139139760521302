import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  UrlTree,
} from "@angular/router";
import { AccountService } from "app/shared/services/account.service";
import { UrbanSitterService } from "app/shared/services/urban-sitter.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UrbanSitterGuard {
  constructor(
    private accountService: AccountService,
    private router: Router,
    public urbanSitterService: UrbanSitterService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isLogged = this.checkIfLogged();
    if (route.queryParams.urbanSitter && isLogged)
      return this.router.createUrlTree(["loading-state"], {
        queryParams: route.queryParams,
      });
    else return true;
  }

  private checkIfLogged() {
    return !(this.accountService.getLogged() instanceof Error);
  }
}
