<app-register-layout [ngSwitch]="state().currentStep" flowMode="existing">
  <app-registration-header
    [isMobileWebView]="isMobileWebView"
    [currentStep]="state().index"
    [totalSteps]="state().total"
  />

  <app-email-verification
    (nextStep)="nextStep()"
    *ngSwitchCase="'email-verification'"
    [isExistingPath]="true"
  />

  <app-existing-backup-contact
    (nextStep)="handleBackupContactSubmit($event)"
    *ngSwitchCase="'backup-contact'"
  />

  <app-eligibility-check
    (formSubmitted)="nextStep()"
    *ngSwitchCase="'eligibility-check'"
    [registeredEmail]="registeredEmail"
  />
</app-register-layout>
