/**
 * Credential Type
 */
export class Credential {
    public email: string;
    public username?: string;
    public password: string;
    public confirm?: string;
    public deviceId?: string;
    public mfaCode?: string;
    public trustDevice?: boolean;
}
