import { Component, ElementRef, Input, NgZone, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "app/shared/services/account.service";
import { ProvisioningService } from "app/shared/services/provisioning.service";
import { StorageService } from "app/shared/services/storage.service";
import { UrbanSitterService } from "app/shared/services/urban-sitter.service";
import { environment } from "environments/environment";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";

@Component({
  selector: "app-loading-state",
  templateUrl: "./loading-state.component.html",
  styleUrls: ["./loading-state.component.scss"],
})
export class LoadingStateComponent {
  @Input() id = "hearts-loader";
  animation: AnimationItem;
  @ViewChild("loader") loader: ElementRef;
  redirectUrl = "";

  constructor(
    private routerSnapshot: ActivatedRoute,
    private storage: StorageService,
    private urbanSitterService: UrbanSitterService,
    private provisioningService: ProvisioningService,
    private accountService: AccountService,
    private ngZone: NgZone,
    private readonly router: Router,
  ) {
    this.redirectUrl =
      this.routerSnapshot.snapshot.queryParams?.redirectUrl ?? "";
  }

  ngAfterViewInit(): void {
    this.animation = lottie.loadAnimation({
      container: this.loader.nativeElement,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "assets/loading-hearts.json",
    });
    this.validateLocalStorage();
  }

  ngOnDestroy(): void {
    this.animation.destroy();
  }

  validateLocalStorage() {
    if (
      this.redirectUrl === "" &&
      this.routerSnapshot.snapshot.queryParams?.urbanSitter === undefined
    ) {
      return;
    }

    const intervalId = setInterval(() => {
      const token = this.storage.getItem("access_token");
      if (token === null || token === "") {
        return;
      }

      if (this.routerSnapshot.snapshot.queryParams?.urbanSitter) {
        clearInterval(intervalId);
        this.ssoToUrbanSitter();
        return;
      }

      if (this.redirectUrl !== "") {
        clearInterval(intervalId);
        window.location.assign(this.redirectUrl);
      }
    }, 1000);
  }

  async ssoToUrbanSitter() {
    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }

    const org = await this.accountService.getAccountOrg(account.id).toPromise();
    if (
      !org.hasUrbanSitter ||
      account.benefitId ||
      !(account.country === "US" || !account.country)
    ) {
      window.location.assign(environment.AUTH_URL);
    }
    this.provisioningService.getProvisioningByOrg(org.id).subscribe((res) => {
      if (res.urbanSitterMemberSSO?.value) {
        this.ngZone.runOutsideAngular(() => {
          this.urbanSitterService
            .connectAccount()
            .subscribe(({ token, refreshToken }) => {
              const urbanSitterForm =
                this.urbanSitterService.createUrbanSitterForm(
                  token,
                  refreshToken,
                );
              urbanSitterForm.submit();
            });
        });
      } else {
        window.location.assign(environment.AUTH_URL);
      }
    });
  }
}
