import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { environment } from "environments/environment";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { AccountService } from "./account.service";
import { BaseModelService } from "./base-model.service";
import { redirectPlanRegex } from "app/shared/constants/redirect-plan-regex.constant";
import { CaseService } from "./case.service";
import { ProvisioningService } from "./provisioning.service";
import { AccountCasesService } from "./account-cases.service";
interface RedirectOptions {
  redirect?: string;
  fromLogin?: boolean;
  comeFromAdmin: boolean;
}

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  private oauth2Params?: {
    clientId: string;
    state: string;
    redirectUri: string;
  };
  get isOauth() {
    return !!this.oauth2Params;
  }
  constructor(
    public http: BaseModelService,
    private caseService: CaseService,
    private accountService: AccountService,
    private accountCaseService: AccountCasesService,
    private provisioningService: ProvisioningService,
  ) {}
  init(snapshot: ActivatedRouteSnapshot) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { redirectUrl, client_id, state, redirect_uri } =
      snapshot.queryParams ?? {};
    if (client_id && state && redirect_uri) {
      this.oauth2Params = {
        clientId: client_id,
        redirectUri: redirect_uri,
        state,
      };
    }
  }

  onLogin() {
    const headers = new HttpHeaders().set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    const params = new HttpParams()
      .append("client_id", this.oauth2Params.clientId)
      .append("state", this.oauth2Params.state)
      .append("redirect_uri", this.oauth2Params.redirectUri)
      .append("response_type", "code");
    return this.http
      .post<{
        authorizationCode: string;
        redirectUri: string;
      }>(`${environment.BASE_URL}/oauth2/authorize`, {}, { headers, params })
      .pipe(
        tap((res) => {
          window.location.href = `${res.redirectUri}?authorizationCode=${res.authorizationCode}&code=${res.authorizationCode}&state=${this.oauth2Params.state}`;
        }),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        map((r) => false),
      );
  }

  getUserRedirect(options: RedirectOptions): Observable<string> {
    if (options.redirect) {
      options.redirect = decodeURIComponent(options.redirect);
      return this.buildRedirect(options);
    } else {
      return this.buildUrl(options);
    }
  }

  private buildUrl({ comeFromAdmin }: RedirectOptions) {
    const user = this.accountService.getLogged();
    if (user instanceof Error) {
      return of(environment.AUTH_URL);
    }
    return this.accountCaseService.getAccountCases(user.id, {}).pipe(
      switchMap((cases) => {
        const {
          isAdmin,
          isEnterprise,
          isEngagement,
          isAnalytics,
          isCms,
          isCoachAdmin,
          isUser,
          isCoach,
        } = this.extractRoles();
        let url = "";
        if (comeFromAdmin && isAdmin) {
          url = `${environment.ADMIN_URL}/dashboard`;
        } else if (isCoachAdmin || isCoach) {
          url = environment.COACH_URL + "/";
        } else if (isAdmin) {
          url = `${environment.ADMIN_URL}/dashboard`;
        } else if (isEngagement) {
          url = `${environment.AUTH_URL}/engagement/`;
        } else if (isAnalytics) {
          url = `${environment.AUTH_URL}/analytics/`;
        } else if (isCms) {
          url = `${environment.AUTH_URL}/cms/`;
        } else if (isEnterprise && isUser && cases?.length > 0) {
          url = `${environment.AUTH_URL}/choose-adventure`;
        } else if (isEnterprise) {
          url = `${environment.AUTH_URL}/enterprise`;
        }
        if (url) {
          return of(url);
        }
        return this.caseService.getCaseHomeUrl();
      }),
    );
  }

  private extractRoles() {
    const roles = this.accountService.getRoles();
    const isAdmin = roles.some((role) => role === "$admin");
    const isCoachAdmin = roles.some((role) => role === "$coach_admin");
    const isCoach = roles.some(
      (role) =>
        role === "$coach" ||
        role === "$coach_assistant" ||
        role === "$coach_intake",
    );
    const isUser = roles.includes("$user");
    const isEngagement = roles.includes("$engagement");
    const isEnterprise = roles.some((role) => role === "$enterprise");
    const isAnalytics = roles.includes("$analytics");
    const isCms = roles.includes("$cms");
    return {
      isAdmin,
      isCoachAdmin,
      isCoach,
      isUser,
      isEnterprise,
      isEngagement,
      isAnalytics,
      isCms,
    };
  }

  private buildPlanRedirect({ redirect, fromLogin }: RedirectOptions) {
    const planRedirect = redirectPlanRegex.test(redirect) ? redirect : "";
    const parts = planRedirect.split("/");
    const [, resource, id] = parts;
    const user = this.accountService.getLogged();
    if (user instanceof Error) {
      return of(environment.AUTH_URL);
    }
    if (fromLogin) {
      return of(environment.PLAN_URL + planRedirect);
    }
    let query = "";
    const queryParts = planRedirect.split("?");
    if (queryParts.length > 1) {
      query = queryParts.pop();
    }
    if (resource === "case") {
      return of(`${environment.PLAN_URL}/${resource}/${id}/home`);
    }
    if (resource === "discussion") {
      return this.caseService
        .getCaseIdFromPost(id)
        .pipe(
          switchMap((caseId) =>
            of(
              `${environment.PLAN_URL}/case/${caseId}/coaching/discussion/${id}?${query}`,
            ),
          ),
        );
    }
    return of(environment.PLAN_URL + planRedirect);
  }

  private buildRedirect(options: RedirectOptions) {
    const { redirect, comeFromAdmin } = options;
    const {
      isAdmin,
      isEnterprise,
      isEngagement,
      isAnalytics,
      isCms,
      isCoachAdmin,
      isCoach,
    } = this.extractRoles();
    if (comeFromAdmin && isAdmin) {
      return of(environment.ADMIN_URL + redirect);
    } else if (isCoachAdmin || isCoach) {
      return this.getCoachRedirectUrl(redirect).pipe(
        map((url) => environment.COACH_URL + url),
      );
    } else if (isAdmin) {
      return of(environment.ADMIN_URL + redirect);
    } else if (isEnterprise) {
      return of(`${environment.AUTH_URL}/enterprise/${redirect}`);
    } else if (isEngagement) {
      return of(`${environment.AUTH_URL}/engagement/${redirect}`);
    } else if (isAnalytics) {
      return of(`${environment.AUTH_URL}/analytics/${redirect}`);
    } else if (isCms) {
      return of(`${environment.AUTH_URL}/cms/${redirect}`);
    }
    return this.buildPlanRedirect(options);
  }
  private getCoachRedirectUrl(redirect: string): Observable<string> {
    return new Observable((observer) => {
      let url = "";
      const redirectParts = redirect.split("/");
      // Rewrite redirect to coach version of user portal page - discussions list
      if (/^\/case\/\w+$/.test(redirect)) {
        url = `/case/${redirectParts[2]}/care/discussions`;
        // Rewrite redirect to coach version of user portal page - documents
      } else if (/^\/case\/\w+\/files$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/documents";
        // Rewrite redirect to coach version of user portal page - care team
      } else if (/^\/case\/\w+\/share$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/team";
        // Rewrite redirect to coach version of user portal page - search
      } else if (/^\/case\/\w+\/search\/\w+$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/search";
        // Rewrite redirect to case notes for old case-details route - case details notes
      } else if (/^\/case-details\/\w+\/notes$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/notes";
      } else {
        url = redirect;
      }
      // Rewrite redirect to coach version of user portal page - discussion
      if (/^\/discussion\/\w+/.test(redirect)) {
        this.caseService.getCaseIdFromPost(redirectParts[2]).subscribe(
          (caseId: string) => {
            url = "/case/" + caseId + "/care/discussion/" + redirectParts[2];
            observer.next(url);
            observer.complete();
          },
          () => {
            observer.next(url);
            observer.complete();
          },
        );
      } else {
        observer.next(url);
        observer.complete();
      }
    });
  }
}
