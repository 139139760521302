<section class="disconnection-container">
  <img src="assets/img/disconnection.svg" class="logo" />
  <h1 class="title mat-h5">{{'DISCONNECTION.TITLE' | translate}}</h1>
  <div class="body-content">
    <p class="mat-body-1">
      {{'DISCONNECTION.PARAGRAPH_1' | translate}}
    </p>
    <p class="mat-body-1">
      {{'DISCONNECTION.PARAGRAPH_2' | translate}}
    </p>
    <p class="mat-body-1">
      {{'DISCONNECTION.PARAGRAPH_3' | translate}}
    </p>
    <button
      mat-raised-button
      color="primary"
      type="button"
      [attr.aria-label]="'DISCONNECTION.RETRY_ARIA' | translate"
      (click)="checkServerStatus()">
      {{  (10 - timerCounter > 1 ? 'DISCONNECTION.RETRY' : 'DISCONNECTION.RETRY_SINGULAR') | translate: {time: 10 - timerCounter} }}
    </button>
  </div>
</section>