import { Component, DestroyRef, OnInit } from "@angular/core";
import { ErrorStateMatcher } from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { INVALID_PASSWORDS } from "app/shared/constants/invalid-passwords.constant";
import { PasswordConfirmationErrorMatcher } from "app/shared/error-matchers/password-confirmation.matcher";
import { switchMap } from "rxjs/operators";
import { AppState } from "../../app.service";
import { AccountCasesService } from "../../shared/services/account-cases.service";
import { AccountService } from "../../shared/services/account.service";
import { AppTranslateService } from "../../shared/services/app-translate.service";
import { EventsService } from "../../shared/services/events.service";
import { ErrorReportingService } from "../../shared/services/error-reporting.service";
import { AccountCases } from "../../shared/types/account-case.type";
import { Account } from "../../shared/types/account.type";
import { AppStateType } from "../../shared/types/app-state-type";
import { Case } from "../../shared/types/case.type";
import { Credential } from "../../shared/types/credential.type";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NgForm } from "@angular/forms";

/**
 * @Component request password
 */
@Component({
  selector: "request-password",
  styleUrls: ["./request-password.scss"],
  templateUrl: "./request-password.html",
})
export class RequestPasswordComponent implements OnInit {
  public credentials: Credential = new Credential();
  public error: string;
  public isLoading = false;
  public isPasswordValid = true;
  public accountToUpdate: Account = new Account();
  public _case: Case;
  public loginCode: string;
  public errorMatcher: ErrorStateMatcher;
  public termsAccepted = false;
  private isCode = false;

  constructor(
    private appService: AppState,
    private accountService: AccountService,
    private eventsService: EventsService,
    private accountCasesService: AccountCasesService,
    private translate: AppTranslateService,
    private router: Router,
    private errorReportingService: ErrorReportingService,
    private route: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private destroyRef: DestroyRef,
  ) {
    this.errorMatcher = new PasswordConfirmationErrorMatcher();
  }

  public ngOnInit(): void {
    this.translateService
      .stream("REQUEST_PASSWORD.PAGE_TITLE")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pageTitle) => {
        this.titleService.setTitle(pageTitle + " | Cariloop");
      });
    this.route.params.subscribe((params) => {
      this.loginCode = params["id"];
      this.isCode = this.route.snapshot.queryParams["code"] ? true : false;
      const appState: AppStateType = {
        authenticated: false,
        path: {
          root: "reset",
        },
      };
      this.appService.appStateUpdate(appState);
      this.getAccountByLoginCode();
    });
  }

  public onSubmit(form: NgForm): void {
    if (form.invalid) {
      form.controls.password.markAsTouched();
      form.controls.terms.markAsTouched();
      return;
    }
    this.error = null;
    this.isLoading = true;
    this.changePassword();
  }

  public validatePassword(): boolean {
    const { password } = this.credentials;
    return (this.isPasswordValid = password
      ? !INVALID_PASSWORDS[password]
      : true);
  }

  public changePassword(): void {
    this.accountService
      .change(this.credentials, this.loginCode, new Date(), this.isCode)
      .pipe(
        switchMap(() =>
          this.accountService.login({
            email: this.accountToUpdate.email,
            password: this.credentials.password,
          }),
        ),
      )
      .subscribe(
        () =>
          this.router.navigate(["login"], {
            queryParamsHandling: "preserve",
          }),
        (error) => this.errorHandler(error),
      );
  }

  public createLinkClickedEvent(): void {
    this.eventsService
      .addResetPasswordLinkAccessEvent(this.loginCode, this.isCode)
      .subscribe(
        (res) => console.log("Success"),
        (err) => console.log(err),
      );
  }

  public getAccountCase() {
    this.accountCasesService
      .getAccountCases(this.accountToUpdate.id, {
        where: {
          accountId: this.accountToUpdate.id,
        },
        order: "role",
      })
      .subscribe(
        (accountCases: AccountCases[]) => {
          if (
            accountCases &&
            Array.isArray(accountCases) &&
            accountCases.length > 0
          ) {
            accountCases = accountCases.filter((ac) => !ac.deletedAt);
            window.location.href = this.accountService.getHomeUrl();
          } else {
            const error = new Error(
              `No cases found for: ${this.accountToUpdate.id}`,
            );
            this.errorHandler(error);
          }
        },
        (err: Error) => this.errorHandler(err),
      );
  }

  private getAccountByLoginCode() {
    this.accountService.getAccountByLoginCode(this.loginCode).subscribe(
      (res) => {
        if (res && res.id) {
          this.createLinkClickedEvent();
          this.setUserLanguage(res.language);
          this.accountToUpdate = res;
        } else {
          const isEnterprise = Number(
            this.route.snapshot.queryParamMap.get("isEnterprise") || 0,
          );
          if (isEnterprise) {
            const displayText =
              this.route.snapshot.queryParamMap.get("displayText");
            this.router.navigate(["/expired-invite"], {
              queryParams: { displayText },
            });
            return;
          }
          this.router.navigate(["/login"]);
        }
      },
      (err) => this.errorHandler(err),
    );
  }

  private setUserLanguage(lang = "en"): void {
    this.translate.updateLang(lang);
  }

  private errorHandler(err): void {
    this.isLoading = false;
    this.error = err;
    if (err.status === 404) {
      this.router.navigate(["login"], {
        queryParams: { source: "code-expired" },
      });
    } else if (err.status !== 400 && err.status !== 451) {
      this.errorReportingService.send(err);
    }
  }
}
