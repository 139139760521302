import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioModule,
} from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";
import { UIComponentsModule } from "@cariloop/ui-components";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "app/pipes/pipes.module";
import { ExistingBackupContactComponent } from "app/register/backup-contact/existing/existing-backup-contact.component";
import { EligibilityCheckComponent } from "app/register/eligibility-check/eligibility-check.component";
import { AccountCreationComponent } from "./account-creation/account-creation.component";
import { BackupContactComponent } from "./backup-contact/backup-contact.component";
import { CompleteComponent } from "./complete/complete.component";
import { EmailVerificationComponent } from "./email-verification/email-verification.component";
import { PasswordFieldComponent } from "../shared/components/password-field/password-field.component";
import { RegisterLayoutComponent } from "./register-layout/register-layout.component";
import { RegisterRoutesModule } from "./register-routes.module";
import { RegisterComponent } from "./register.component";
import {
  RegistrationHeaderComponent,
  RegistrationHeaderLogoComponent,
  RegistrationHeaderStepCounterComponent,
} from "./registration-header/registration-header.component";
import { SsoErrorComponent } from "./sso-error/sso-error.component";

@NgModule({
  declarations: [
    RegisterComponent,
    EligibilityCheckComponent,
    RegistrationHeaderComponent,
    RegistrationHeaderLogoComponent,
    RegistrationHeaderStepCounterComponent,
    AccountCreationComponent,
    BackupContactComponent,
    EmailVerificationComponent,
    CompleteComponent,
    ExistingBackupContactComponent,
    SsoErrorComponent,
  ],
  imports: [
    PasswordFieldComponent,
    CommonModule,
    TranslateModule.forChild(),
    UIComponentsModule,
    CommonModule,
    UIComponentsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatInputModule,
    PipesModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatRadioModule,
    RegisterRoutesModule,
    MatLegacyProgressSpinnerModule,
    NgOptimizedImage,
    RegisterLayoutComponent,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
  ],
  exports: [
    RegisterLayoutComponent,
    RegistrationHeaderComponent,
    BackupContactComponent,
    EmailVerificationComponent,
    AccountCreationComponent,
    RegistrationHeaderLogoComponent,
  ],
})
export class RegisterModule {}
