import { Component, OnInit, Input, OnChanges, SimpleChange } from '@angular/core';
import { StorageService } from 'app/shared/services/storage.service';

@Component({
  selector: 'app-md-error-handler',
  templateUrl: './md-error-handler.component.html',
  styleUrls: ['./md-error-handler.component.scss']
})
export class MdErrorHandlerComponent implements OnInit, OnChanges {

  @Input() public error: any;
  @Input() public context: any;
  public message: any;
  public errorObject: any;
  public errorCode: number;
  public msg: Object[] = [];

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit() {
  }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    const error = changes['error'].currentValue;
    if (error && error.status !== 200) {
      if (!(error instanceof Error)) {
        const parsedJson = error?.error?.error;
        if (parsedJson?.statusCode) {
          this.errorObject = parsedJson;
          this.errorCode = this.errorObject.statusCode;
          this.filterError();
          console.log(this.errorObject.statusCode);
          this.msg[0] = ({
            statusCode: this.errorObject.statusCode,
            severity: 'error', summary: 'Error: ',
            detail: this.message
          });
        } else {
          this.msg[0] = ({
            severity: 'error', summary: 'Error: ',
            detail: 'Unexpected Error'
          });
        }
      } else {
        this.message = error;
        this.msg[0] = ({
          severity: 'error', summary: '',
          detail: this.message
        });
      }
    } else {
      this.message = '';
      this.msg = [];
    }
  }

  public parseJson(json): any {
    try {
      const parsedJson = JSON.parse(json);
      return parsedJson;
    } catch (error) {
      return {};
    }
  }

  public filterError() {
    if (this.errorCode > 401 && this.errorCode < 500) {
      if (this.errorObject?.message === 'Login failed') {
        this.message = 'Unable to log in. Try again.';
      } else {
        this.message = this.errorObject?.error?.details ?
          this.extractMessages(this.errorObject.messages) : this.errorObject.message;
        if (
          this.errorObject?.message === 'Not found' &&
          this.context === 'forgot-password'
        ) {
          this.message = 'The email entered can not be found. Please try submitting the email again.';
        }
      }
    } else if (this.errorCode >= 500) {
      this.message = this.errorObject.message;
    } else if (this.errorCode === 401) {
      this.storageService.removeItem('access_token');
      this.storageService.removeItem('logged');
      if (this.errorObject?.message === 'Login failed') {
        this.message = 'Unable to log in. Try again.';
      } else {
        this.message = this.errorObject?.message + ' -. ';
      }
    } else if (this.errorCode === 400 && this.errorObject?.error) {
      this.message = this.errorObject.error?.message + ' -. ';
    } else {
      this.message = this.errorObject.message + ' -. ';
    }
  }

  public extractMessages(messages): string {
    let message = '';
    for (let key in messages) {
      if (messages.hasOwnProperty(key)) {
        let element = messages[key];
        message += this.changeMessage(element[0] + '. ');
      }
    }
    return message;
  }

  public changeMessage(message: string): string {
    let newMessage = '';
    switch (message) {
      case 'Email already exists':
        newMessage = 'The email entered is already registered';
        break;
      default:
        newMessage = message;
        break;
    }
    return newMessage;
  }

}
