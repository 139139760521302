import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "app/shared/services/account.service";
import { emailRegex, phonePattern } from "@cariloop/ui-components";
import { HttpErrorResponse } from "@angular/common/http";
import {
  DevicePlatformService,
  MixPanelPlatform,
} from "app/shared/services/device-platform.service";
import { AnalyticsService } from "@cariloop/analytics";
import { Router } from "@angular/router";

type ErrorType = "email-already-used" | "generic-error" | "gateway-timeout";

type State = {
  hasServerSideError: boolean;
  errorType: ErrorType;
  isSubmitting: boolean;
};

@Component({
  selector: "app-backup-contact",
  templateUrl: "./backup-contact.component.html",
  styleUrls: ["./backup-contact.component.scss"],
})
export class BackupContactComponent implements OnInit {
  @Output() nextStep = new EventEmitter<void>();
  backupForm: FormGroup = new FormGroup({
    phone: new FormControl(""),
    email: new FormControl("", [Validators.pattern(emailRegex)]),
    method: new FormControl(true),
  });
  state: State = {
    hasServerSideError: false,
    errorType: undefined,
    isSubmitting: false,
  };
  platform: MixPanelPlatform;

  constructor(
    private accountService: AccountService,
    private analyticsService: AnalyticsService,
    private devicePlatformService: DevicePlatformService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      this.router.navigate(["/"], { queryParamsHandling: "preserve" });
      return;
    }

    const { id } = accountResult;

    this.backupForm.get("method").valueChanges.subscribe((val) => {
      if (val) {
        this.backupForm.controls["email"].setValidators([
          Validators.required,
          Validators.pattern(emailRegex),
        ]);
        this.backupForm.get("phone").clearValidators();
        this.backupForm.get("phone").updateValueAndValidity();
        this.backupForm.controls["email"].updateValueAndValidity();
      } else {
        this.backupForm.controls["phone"].setValidators([
          Validators.required,
          Validators.pattern(phonePattern),
        ]);
        this.backupForm.get("email").clearValidators();
        this.backupForm.get("email").updateValueAndValidity();
        this.backupForm.controls["phone"].updateValueAndValidity();
      }
    });

    this.platform = this.devicePlatformService.getPlatformForMixpanel();

    this.analyticsService.trackEvent("back-up-contact", {
      platform: this.platform,
      source: "auth",
      accountId: id,
      userFlow: "create-new-account",
    });
  }

  onContinue() {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    const { id } = accountResult;

    const data = {};
    if (!this.backupForm.value.method) {
      data["phone"] = this.backupForm.value.phone;
    } else {
      data["email2"] = this.backupForm.value.email;
      data["email2Type"] = "personal";
    }

    this.state = {
      hasServerSideError: false,
      errorType: undefined,
      isSubmitting: true,
    };

    this.accountService.updateAttributes(id, data).subscribe({
      next: () => {
        this.analyticsService.trackEvent("back-up-contact-complete", {
          platform: this.platform,
          source: "auth",
          accountId: id,
          userFlow: "create-new-account",
        });

        this.nextStep.next();
        this.state = {
          ...this.state,
          isSubmitting: false,
        };
      },
      error: (errorResponse: HttpErrorResponse) => {
        const error = {
          ...errorResponse.error.error,
        };
        let errorType: ErrorType = "generic-error";

        if (
          error.statusCode === 422 &&
          error.message === "Secondary email is already in use."
        ) {
          errorType = "email-already-used";
        } else if (errorResponse.status === 504) {
          errorType = "gateway-timeout";
        }

        this.state = {
          ...this.state,
          isSubmitting: false,
          hasServerSideError: true,
          errorType,
        };
      },
    });
  }
}
