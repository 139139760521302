<section class="eligibility-check-container">
  <div>
    <h1 class="eligibility-check-header">
      {{ "REGISTER.ELIGIBILITY_CHECK.HEADER" | translate }}
    </h1>
    <p class="eligibility-check-step-explanation">
      {{ "REGISTER.ELIGIBILITY_CHECK.EXPLANATION" | translate }}
    </p>
  </div>

  <c-banner
    *ngIf="state().hasEligibilityError"
    [style]="'warning'"
    iconPositionX="top"
  >
    <div class="banner-message-container">
      <span class="banner-message__message">
        <span class="banner-message__title">
          {{ "REGISTER.ELIGIBILITY_CHECK.FAIL_TITLE" | translate }}
        </span>
        <ng-container
          *ngIf="
            workEmailCheckFailed && personalInfoCheckFailed;
            else singleMethodFailureText
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.ALL_FAIL_MESSAGE_PART_1" | translate }}
          <a class="banner-message__link" href="mailto: support@cariloop.com">
            {{
              "REGISTER.ELIGIBILITY_CHECK.ALL_FAIL_MESSAGE_PART_2" | translate
            }}
          </a>
          {{ "REGISTER.ELIGIBILITY_CHECK.ALL_FAIL_MESSAGE_PART_3" | translate }}
        </ng-container>
        <ng-template #singleMethodFailureText>
          {{
            "REGISTER.ELIGIBILITY_CHECK.METHOD_FAIL_MESSAGE_PART_1" | translate
          }}
          <ng-container *ngIf="workEmailCheckFailed">
            <a
              tabindex="0"
              class="banner-message__link"
              (click)="
                selectEligibilityCheckMethod($event, 'personalInformation')
              "
              (keyup.enter)="
                selectEligibilityCheckMethod($event, 'personalInformation')
              "
            >
              {{
                "REGISTER.ELIGIBILITY_CHECK.WORK_EMAIL_FAIL_MESSAGE" | translate
              }}
            </a>
          </ng-container>
          <ng-container *ngIf="personalInfoCheckFailed">
            <a
              tabindex="0"
              class="banner-message__link"
              (click)="selectEligibilityCheckMethod($event, 'workEmail')"
              (keyup.enter)="selectEligibilityCheckMethod($event, 'workEmail')"
            >
              {{
                "REGISTER.ELIGIBILITY_CHECK.PERSONAL_INFORMATION_FAIL_MESSAGE"
                  | translate
              }}
            </a>
          </ng-container>
        </ng-template>
      </span>
    </div>
  </c-banner>

  <form
    (ngSubmit)="handleSubmit()"
    [formGroup]="eligibilityCheckForm"
    class="eligibility-check-form"
    id="eligibility-check-form"
  >
    <c-banner
      *ngIf="state().hasServerSideError"
      [style]="'error'"
      message="{{ 'REGISTER.GENERIC_FORM.ERRORS.GENERIC' | translate }}"
    />

    <div>
      <label
        class="eligibility-check-verification-method-label"
        id="verification-method-label"
      >
        {{ "REGISTER.ELIGIBILITY_CHECK.VERIFICATION_METHOD_LABEL" | translate }}
      </label>
      <mat-radio-group
        [disabled]="state().isSubmitting"
        aria-labelledby="verification-method-label"
        formControlName="method"
      >
        <mat-radio-button
          checked="true"
          class="eligibility-check-radio"
          value="workEmail"
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.WORK_EMAIL" | translate }}
          <span class="eligibility-check-radio-label-addendum">
            {{ "REGISTER.ELIGIBILITY_CHECK.PREFERRED" | translate }}
          </span>
        </mat-radio-button>
        <mat-radio-button
          class="eligibility-check-radio"
          value="personalInformation"
        >
          {{
            "REGISTER.ELIGIBILITY_CHECK.PERSONAL_INFORMATION.OPTION" | translate
          }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div
      *ngIf="(selectedMethod$ | async) === 'workEmail'"
      formGroupName="workEmailGroup"
    >
      <c-input-text
        [disabled]="state().isSubmitting"
        class="eligibility-check-email-input"
        formControlName="workEmail"
        label="{{ 'REGISTER.ELIGIBILITY_CHECK.WORK_EMAIL' | translate }}"
        type="email"
      >
        <ng-container
          *ngIf="
            eligibilityCheckForm.get('workEmailGroup.workEmail')?.errors
              ?.required !== undefined
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.REQUIRED_EMAIL_ADDRESS" | translate }}
        </ng-container>
        <ng-container
          *ngIf="
            eligibilityCheckForm.get('workEmailGroup.workEmail')?.errors
              ?.pattern !== undefined
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.INVALID_EMAIL_ADDRESS" | translate }}
        </ng-container>
      </c-input-text>
      <p class="work-email-info">
        {{ "REGISTER.ELIGIBILITY_CHECK.WORK_EMAIL_INFO" | translate }}
      </p>
    </div>
    <div
      *ngIf="(selectedMethod$ | async) === 'personalInformation'"
      formGroupName="personalEmailGroup"
      class="personal-info-form"
    >
      <div class="full-name">
        <c-input-text
          class="name-input"
          name="firstName"
          type="text"
          required
          [label]="'REGISTER.GENERIC_FORM.FIRST_NAME' | translate"
          formControlName="firstName"
        >
          <p
            *ngIf="
              eligibilityCheckForm.get('personalEmailGroup.firstName').errors
            "
          >
            {{ "REGISTER.GENERIC_FORM.ERRORS.FIRST_NAME" | translate }}
          </p>
        </c-input-text>

        <c-input-text
          class="name-input"
          name="lastName"
          type="text"
          required
          [label]="'REGISTER.GENERIC_FORM.LAST_NAME' | translate"
          formControlName="lastName"
        >
          <p
            *ngIf="
              eligibilityCheckForm.get('personalEmailGroup.lastName').errors
            "
          >
            {{ "REGISTER.GENERIC_FORM.ERRORS.LAST_NAME" | translate }}
          </p>
        </c-input-text>
      </div>
      <c-input-text
        #zipCodeInput
        id="zipCodeInput"
        class="name-input"
        name="zipCode"
        type="text"
        [label]="
          'REGISTER.ELIGIBILITY_CHECK.PERSONAL_INFORMATION.ZIP_CODE' | translate
        "
        formControlName="zipCode"
      >
        <p
          *ngIf="
            eligibilityCheckForm.get('personalEmailGroup.zipCode').errors
              ?.required
          "
        >
          {{
            "REGISTER.ELIGIBILITY_CHECK.ERRORS.ZIP_CODE_REQUIRED" | translate
          }}
        </p>
        <p
          *ngIf="
            eligibilityCheckForm.get('personalEmailGroup.zipCode').errors
              ?.pattern &&
            !eligibilityCheckForm.get('personalEmailGroup.zipCode').errors
              ?.required
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.ERRORS.ZIP_CODE_INVALID" | translate }}
        </p>
      </c-input-text>
      <div class="date-picker">
        <c-input-datepicker
          required
          name="date"
          startView="month"
          xPosition="end"
          language="en"
          formControlName="dateOfBirth"
          [max]="today"
          [label]="
            'REGISTER.ELIGIBILITY_CHECK.PERSONAL_INFORMATION.DOB' | translate
          "
        ></c-input-datepicker>
        <p
          class="error"
          *ngIf="
            eligibilityCheckForm.get('personalEmailGroup.dateOfBirth').errors
              ?.required &&
            (eligibilityCheckForm.get('personalEmailGroup.dateOfBirth')
              .touched ||
              isDOBTouched) &&
            !eligibilityCheckForm.get('personalEmailGroup.dateOfBirth').errors
              ?.matDatepickerParse
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.ERRORS.DOB_REQUIRED" | translate }}
        </p>
        <p
          class="error"
          *ngIf="
            eligibilityCheckForm.get('personalEmailGroup.dateOfBirth').errors
              ?.matDatepickerParse &&
            (eligibilityCheckForm.get('personalEmailGroup.dateOfBirth')
              .touched ||
              isDOBTouched)
          "
        >
          {{ "REGISTER.ELIGIBILITY_CHECK.ERRORS.DOB_INVALID" | translate }}
        </p>
      </div>
    </div>
  </form>

  <ng-container *ngIf="state().isSubmitting; then spinner; else submitButton" />
  <ng-template #spinner>
    <mat-spinner diameter="40" />
  </ng-template>
  <ng-template #submitButton>
    <button
      class="eligibility-check-form-button"
      form="eligibility-check-form"
      type="submit"
    >
      {{ "REGISTER.ELIGIBILITY_CHECK.CONTINUE" | translate }}
    </button>
  </ng-template>
</section>
