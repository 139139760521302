import { Component, Input, OnInit } from "@angular/core";
import { LanguageType } from "@cariloop/ui-components/lib/models/language.interface";
import { LANGUAGES } from "app/languages";
import { AccountService } from "app/shared/services/account.service";
import { AppTranslateService } from "app/shared/services/app-translate.service";

@Component({
  selector: "app-registration-header-logo",
  template: `
    <img
      alt="Cariloop Logo"
      class="registration-header-cariloop-logo"
      height="32"
      src="assets/icon/Cariloop-Logo-Black.svg"
      width="109"
    />
  `,
  styleUrls: ["./registration-header-logo.component.scss"],
})
export class RegistrationHeaderLogoComponent {}

@Component({
  selector: "app-registration-step-counter",
  template: `
    <p class="step-counter">
      {{ "REGISTER.STEP_COUNTER" | translate: { currentStep, totalSteps } }}
    </p>
  `,
  styleUrls: ["./registration-step-counter.component.scss"],
})
export class RegistrationHeaderStepCounterComponent {
  @Input()
  currentStep: number;

  @Input()
  totalSteps: number;
}

@Component({
  selector: "app-registration-header",
  templateUrl: "./registration-header.component.html",
  styleUrls: ["./registration-header.component.scss"],
})
export class RegistrationHeaderComponent implements OnInit {
  @Input()
  currentStep: number;

  @Input()
  totalSteps: number;

  @Input()
  hideStepCounter: boolean = false;

  browserLanguage: LanguageType = {
    value: "en",
    label: "English",
  };

  @Input()
  isMobileWebView = false;

  constructor(
    private readonly accountService: AccountService,
    private readonly appTranslateService: AppTranslateService,
  ) {}

  ngOnInit() {
    const localStorageLanguage = localStorage.getItem("cariloop:language");
    if (localStorageLanguage === null || localStorageLanguage === "") {
      this.browserLanguage =
        LANGUAGES.find(
          ({ value }) => value === window.navigator.language.substring(0, 2),
        ) ?? LANGUAGES[0];

      this.appTranslateService.updateLang(this.browserLanguage.value);

      return;
    }

    const parsedLanguage = JSON.parse(localStorageLanguage);
    this.browserLanguage =
      LANGUAGES.find(({ value }) => value === parsedLanguage?.value) ??
      LANGUAGES[0];
  }

  changeLanguage(language: string) {
    const newLanguage = LANGUAGES.find(({ value }) => value === language);

    this.accountService.setLocalLanguage(newLanguage);

    this.appTranslateService.updateLang(language);

    return newLanguage;
  }
}
