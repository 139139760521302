<div class="main-container">
  <div *ngIf="isExistingPath" class="banner">
    <img src="assets/icon/info-banner.svg" alt="" />
    <p>
      {{ "REGISTER.VERIFY_EMAIL.BANNER.PARAGRAPH_1" | translate }}
      <b>{{ "REGISTER.VERIFY_EMAIL.BANNER.BOLD_1" | translate }}</b>
      ,
      {{ "REGISTER.VERIFY_EMAIL.BANNER.AND" | translate }}
      <b>{{ "REGISTER.VERIFY_EMAIL.BANNER.BOLD_2" | translate }}</b>
      ,
      {{ "REGISTER.VERIFY_EMAIL.BANNER.PARAGRAPH_2" | translate }}
      <a href="https://cariloop.com/privacy-policy">
        {{ "REGISTER.VERIFY_EMAIL.BANNER.PRIVACY" | translate }}
      </a>
      {{ "REGISTER.VERIFY_EMAIL.BANNER.AND" | translate }}
      <a href="https://cariloop.com/terms-of-service">
        {{ "REGISTER.VERIFY_EMAIL.BANNER.TERMS" | translate }}
      </a>
      .
      {{ "REGISTER.VERIFY_EMAIL.BANNER.PARAGRAPH_3" | translate }}
    </p>
  </div>
  <div class="description-container">
    <div class="title">
      {{ "REGISTER.VERIFY_EMAIL.VERIFY" | translate }}
    </div>
    <div class="text">
      {{
        "REGISTER.VERIFY_EMAIL.DESCRIPTION"
          | translate: { email: account.email }
      }}
    </div>
  </div>
  <form [formGroup]="form" class="form-container">
    <div *ngIf="error" class="error-container">
      <c-banner
        class="error-banner system-error-banner"
        *ngIf="error === 'system-error'"
        [style]="'error'"
        [inline]="false"
        [message]="
          'REGISTER.GENERIC_FORM.ERRORS.GENERIC-GATEWAY-TIMEOUT' | translate
        "
        [showCloseButton]="false"
      ></c-banner>
      <c-banner
        *ngIf="error === 'generic'"
        iconPositionX="top"
        [style]="'error'"
        [inline]="false"
        class="error-banner generic-error-banner"
      >
        <div class="banner-message">
          <span>
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-1" | translate
            }}
          </span>
          <span>
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-2" | translate
            }}
          </span>
          <a class="banner-link" href="mailto:support@cariloop.com">
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-3" | translate
            }}
          </a>
        </div>
      </c-banner>
      <c-banner
        class="error-banner invalid-code-error-banner"
        *ngIf="error === 'invalid-code'"
        [style]="'error'"
        [inline]="false"
        [message]="'REGISTER.VERIFY_EMAIL.ERRORS.INVALID_CODE' | translate"
        [showCloseButton]="false"
      ></c-banner>
    </div>
    <div class="input-container">
      <div class="input-title">
        {{ "REGISTER.VERIFY_EMAIL.INPUT" | translate }}
      </div>
      <c-input-text
        [disabled]="isLoading"
        formControlName="code"
        class="input"
        name="code"
        type="number"
        [showCounterArrows]="false"
      >
        <mat-error
          *ngIf="
            code.invalid &&
            (code.dirty || code.touched) &&
            code.errors?.required
          "
        >
          {{ "REGISTER.VERIFY_EMAIL.ERRORS.REQUIRED" | translate }}
        </mat-error>
        <mat-error
          *ngIf="
            code.invalid &&
            (code.dirty || code.touched) &&
            (code.errors?.minlength || code.errors?.maxlength)
          "
        >
          {{ "REGISTER.VERIFY_EMAIL.ERRORS.LENGTH" | translate }}
        </mat-error>
      </c-input-text>
    </div>
    <c-button
      *ngIf="!isLoading"
      class="btn full-button verify-email-button"
      text="{{ 'REGISTER.VERIFY_EMAIL.CONTINUE' | translate }}"
      buttonType="action"
      buttonStyle="fill"
      size="large"
      [buttonDisabled]="!form.valid"
      (onClick)="verifyEmail()"
    ></c-button>
    <mat-spinner *ngIf="isLoading" diameter="40" />
  </form>
  <div class="resend">
    {{ "REGISTER.VERIFY_EMAIL.RESEND.TEXT" | translate }}
    <span class="link" (click)="resendCode()">
      {{ "REGISTER.VERIFY_EMAIL.RESEND.LINK_1" | translate }}
    </span>
    {{ "REGISTER.VERIFY_EMAIL.RESEND.OR" | translate }}
    <a class="link" href="mailto:support@cariloop.com">
      {{ "REGISTER.VERIFY_EMAIL.RESEND.LINK_2" | translate }}
    </a>
    <span>.</span>
  </div>
  <app-toast
    id="toast"
    [message]="toastProperties.toastMessage | translate"
    [showsToast]="toastProperties.showToast"
    [state]="toastProperties.toastType"
  ></app-toast>
</div>
