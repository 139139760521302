<div class="register">
  <div
    [ngClass]="{
      'register__art-area--gift': flowMode === 'gift'
    }"
    class="register__art-area"
  >
    <div class="art-area-container">
      <img
        alt="Cariloop Logo"
        class="art-area__logo art-area__logo--white"
        height="32"
        ngSrc="assets/icon/Cariloop-Logo-White.svg"
        width="109"
      />
      <div class="art-area-section">
        <img
          *ngIf="flowMode === 'gift'"
          alt="Gift boxes"
          class="art-area-section__img"
          height="93"
          ngSrc="assets/img/Gift_Boxes.svg"
          width="241"
        />
        <h1 class="art-area-section__title">
          {{ getArtAreaTitle() | translate }}
        </h1>
        <p class="art-area-section__text">
          {{ "REGISTER.ART_GENERIC_DESCRIPTION" | translate }}
        </p>
      </div>
    </div>
  </div>
  <div class="register__form-area">
    <div class="form-area--form">
      <ng-content></ng-content>
    </div>
  </div>
  <footer *ngIf="currentStep === 'create-account'" class="registration-footer">
    <span>
      {{ "REGISTER.FOOTER_PART_1" | translate }}
      <a
        class="registration-footer-phone"
        href="tel:9723255836"
        title="Call Cariloop support"
      >
        (972) 325-5836
      </a>
      {{ "REGISTER.FOOTER_PART_2" | translate }}
      <a class="registration-footer-phone" href="mailto:support@cariloop.com">
        support&#64;cariloop.com
      </a>
    </span>
  </footer>
</div>
