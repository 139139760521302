import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-sign-callback',
  templateUrl: './sign-callback.component.html',
  styleUrls: ['./sign-callback.component.scss']
})
export class SignCallbackComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    setInterval(() => {
      window.parent.postMessage('Processed', environment.AUTH_URL);
    }, 1000)
  }

}
