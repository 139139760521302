<app-black-container>
  <div class="content">
    <p class="mat-h6 title"  *ngIf="screen==='login'">
      {{ "REQUEST_PASSWORD.UPDATE" | translate: { email: coach.email } }}
    </p>
    <p class="mat-h6 title"  *ngIf="screen==='mfa'">
      {{ 'MFA.TITLE' | translate }}
    </p>
    <p class="mat-h6 title"  *ngIf="screen==='mfaError'">
      {{ mfaErrorLabel | translate }}
    </p>
    <form *ngIf="screen==='login'" (ngSubmit)="onSubmit()" #form="ngForm" appPasswordMatch>
      <div class="password">
        <mat-form-field
          appearance="outline"
          value="null"
          class="input-md-custom form-field full-width"
          color="accent"
          [ngClass]="{'textError': passwordRef && passwordRef.control.hasError('invalidPassword') && passwordRef.control.touched}"
        >
          <mat-label>{{ "REQUEST_PASSWORD.PASS" | translate }}</mat-label>
          <input
            appearance="outline"
            name="password"
            [(ngModel)]="credentials.password"
            type="password"
            #passwordRef="ngModel"
            matInput
            appPasswordValidation
            [capitalLetters]="provisioningProfile?.passwordComplexity?.value.capitalLetters"
            [commonPasswords]="provisioningProfile?.passwordComplexity?.value.commonPasswords"
            [numbers]="provisioningProfile?.passwordComplexity?.value.numbers"
            [symbols]="provisioningProfile?.passwordComplexity?.value.symbols"
            [length]="provisioningProfile?.passwordComplexity?.value.length"
            required
          />
          <mat-error *ngIf="passwordRef?.errors?.required">
            {{ "FORM_ERRORS.REQUIRED_FIELD" | translate }}
          </mat-error>
          <mat-error *ngIf="credentials.password && passwordRef.errors?.invalidPassword">
            {{ passwordRef.getError('textError') }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="password">
        <mat-form-field
          appearance="outline"
          value="null"
          class="input-md-custom form-field full-width"
          color="accent"
        >
          <mat-label>{{
            "REQUEST_PASSWORD.PASS_CONFIRM" | translate
          }}</mat-label>
          <input
            appearance="outline"
            id="passwordConfirmation"
            name="passwordConfirmation"
            matInput
            [(ngModel)]="credentials.confirm"
            #confirmationRef="ngModel"
            [errorStateMatcher]="errorMatcher"
            type="password"
            required
          />
          <mat-error *ngIf="confirmationRef?.errors?.required">
            {{ "FORM_ERRORS.REQUIRED_FIELD" | translate }}
          </mat-error>
          <mat-error
            *ngIf="credentials.confirm && form?.errors?.passwordsDoNotMatch"
          >
            {{ "FORM_ERRORS.PASSWORDS_DO_NOT_MATCH" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [attr.aria-label]="'REQUEST_PASSWORD.DONE_ARIA' | translate"
        [disabled]="!form.valid || isLoading"
        class=""
      >
        {{ "REQUEST_PASSWORD.DONE" | translate }}
      </button>
    </form>
    <form
      utocomplete="off"
      (ngSubmit)="onSubmitMfa()"
      #mfaForm="ngForm"
      class="login-form"
      *ngIf="screen==='mfa'"
    >
      <div class="input-container">
        <span>{{ 'MFA.HINT' | translate }}</span><br />&nbsp;
      </div>
      <div class="input-container">
        <mat-form-field appearance="outline" value="null" class="full-width" color="accent">
          <mat-label>{{ 'MFA.CODE' | translate }}</mat-label>
          <input
            type="text"
            matInput
            [attr.aria-label]="'MFA.CODE' | translate"
            [(ngModel)]="mfaCode"
            maxlength="6"
            name="mfaCode"
          />
        </mat-form-field>
      </div>
      <div class="input-container">
        <mat-checkbox name="mfaTrustDevice" [(ngModel)]="mfaTrustDevice">
          {{ 'MFA.REMEMBER_DEVICE' | translate }}
        </mat-checkbox>
        <br />&nbsp;
      </div>
      <div class="forgot-password-container full-width">
        <button
          mat-raised-button
          class="full-width sign-in-button"
          [attr.aria-label]="'MFA.CONTINUE' | translate"
          [disabled]="mfaCode.length !== 6"
          color="primary"
        >
          {{ 'MFA.CONTINUE' | translate }}
        </button>
      </div>
      <div class="forgot-password-container full-width">
        <button
          mat-button
          color="accent"
          class="full-width sign-in-button"
          [attr.aria-label]="'MFA.CANCEL' | translate"
          (click)="screen='login';false"
        >
          {{ 'MFA.CANCEL' | translate }}
        </button>
      </div>
      <div class="input-container" style="text-align: center">
        &nbsp;<br />
        <span>{{ 'MFA.DID_NOT_RECEIVE' | translate }}</span><br />&nbsp;
      </div>
      <div class="forgot-password-container full-width">
        <button
          mat-button
          color="accent"
          class="full-width sign-in-button"
          [attr.aria-label]="'MFA.REQUEST_NEW' | translate"
          (click)="requestNewCode();false"
        >
          {{ 'MFA.REQUEST_NEW' | translate }}
        </button>
      </div>
      <div class="input-container" style="text-align: center">
        &nbsp;<br />&nbsp;<br />
        <app-countdown
          (onEnd)="screen='mfaError';mfaErrorLabel = 'MFA.TIMEOUT_ERROR';"
          [limit]="mfaLimit"
        ></app-countdown>
        <br />&nbsp;
      </div>
    </form>
    <form
      (ngSubmit)="onSubmitMfa()"
      #mfaForm="ngForm"
      class="login-form"
      *ngIf="screen==='mfaError'"
    >
      <div class="forgot-password-container full-width">
        <button
          mat-button
          color="accent"
          class="full-width sign-in-button"
          [attr.aria-label]="'MFA.BACK' | translate"
          (click)="back();false"
        >
          {{ 'MFA.BACK' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-black-container>
