import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";
if (["staging", "develop", "production"].includes(environment.envName)) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.envName,
    release: environment.version,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if (environment.production) {
  enableProdMode();
}

if (environment.envName !== "local" && environment.envName !== "bizdev") {
  const gtag = document.createElement("script");
  gtag.setAttribute("async", "true");
  gtag.setAttribute(
    "src",
    `https://www.googletagmanager.com/gtag/js?id=${environment.gaId}`,
  );
  document.head.appendChild(gtag);
  const script = document.createElement("script");
  script.innerHTML = `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());`;
  document.head.appendChild(script);
}

platformBrowserDynamic().bootstrapModule(AppModule);
