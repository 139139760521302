import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'app/../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BaseModelService } from './base-model.service';
import { map } from 'rxjs/operators';

@Injectable()
export class OrganziationService {
  private headers: HttpHeaders = new HttpHeaders();

  /**
   * Constructor
   */
  constructor(
    public http: BaseModelService
  ) {
    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  public getSSOConfig(orgId: string): Observable<any> {
    const url = `${environment.BASE_URL}organizations/${orgId}/getSSOConfig`;
    return this.http.get(url, { headers: this.headers });
  }

  public getgiftOrgId(): Observable<string> {
    const url = `${environment.BASE_URL}organizations/get-gift-org-id`;
    const stages = [
      map((res: {orgId: string}) => res.orgId),
    ];
    return this.http.get(url, { headers: this.headers }, stages);
  }
}
