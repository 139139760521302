import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {AccountService} from './account.service';
import {BaseModelService} from './base-model.service';
import {getHttpUrlEncoder} from './custom-query-encoder.service';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UrbanSitterService {
  private headers: HttpHeaders = new HttpHeaders();
  private baseUrl: string = '';

  constructor(
    public http: BaseModelService,
    private accountService: AccountService,
  ) {
    this.headers.append('Accept', 'application/json');
    this.headers.append('Content-Type', 'application/json');
    this.baseUrl = `${environment.BASE_URL}urbansitter`;
  }

  public connectAccount(): Observable<{
    token: string;
    refreshToken: string;
  }> {
    let params = getHttpUrlEncoder();
    this.headers = this.headers.set(
      'Authorization',
      String(this.accountService.getToken()),
    );
    let options = {headers: this.headers, params: params};
    return this.http.post(this.baseUrl + '/connect', {}, options).pipe(
      catchError(e => {
        console.error(e);
        return of(e);
      }),
    );
  }

  public createUrbanSitterForm(token: string, refreshToken) {
    const form = this.createElement('form', {
      id: 'urban-sitter',
      action: environment.URBANSITTER_URL,
      method: 'post',
    }) as HTMLFormElement;
    const tokenInput = this.createElement('input', {
      id: 'token',
      type: 'hidden',
      value: token,
      name: 'token',
    }) as HTMLInputElement;
    const refreshTokenInput = this.createElement('input', {
      id: 'refresh-token',
      type: 'hidden',
      value: refreshToken,
      name: 'refreshToken',
    }) as HTMLInputElement;
    const urbanSitterForm = this.appendChildren(form, [
      tokenInput,
      refreshTokenInput,
    ]);
    document.body.appendChild(urbanSitterForm);
    return urbanSitterForm;
  }

  private createElement(
    elementType: string,
    attributes: {[key: string]: string},
  ): HTMLElement {
    const element = document.createElement(elementType);
    for (const attr in attributes) {
      element.setAttribute(attr, attributes[attr]);
    }
    return element;
  }

  private appendChildren(
    element: HTMLFormElement,
    children: HTMLInputElement[],
  ): HTMLFormElement {
    for (const child of children) {
      element.appendChild(child);
    }
    return element;
  }
}
