import {
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AnalyticsService } from "@cariloop/analytics";
import { environment } from "app/../environments/environment";
import { BaseModelService } from "app/shared/services/base-model.service";
import { ErrorReportingService } from "app/shared/services/error-reporting.service";
import { HandledObservableError } from "app/shared/types/handled-observable-error.type";
import { Cookie } from "ng2-cookies";
import { Observable, Subject, throwError } from "rxjs";
import { of } from "rxjs/";
import { catchError, map, switchMap } from "rxjs/operators";
import { AppState } from "../../app.service";
import { Account } from "../types/account.type";
import { Credential } from "../types/credential.type";
import type { EligibilityCheckInfo } from "../types/eligibility-check-info.type";
import { Language } from "../types/language.type";
import { Token } from "../types/token.type";
import { ProvisioningService } from "./provisioning.service";
import { StorageService } from "./storage.service";

@Injectable()
export class AccountService {
  public subject = new Subject<boolean>();
  private baseUrl = "";
  private token: Token;
  private account: null | Account;
  private ownerAccount: Account;
  private headers: HttpHeaders = new HttpHeaders();
  private config = require("../../account/config.json");

  /**
   * Constructor
   */
  constructor(
    public http: BaseModelService,
    private appState: AppState,
    private storageService: StorageService,
    private analyticsService: AnalyticsService,
    private readonly errorReportingService: ErrorReportingService,
  ) {
    this.headers = this.headers.append("Accept", "application/json");
    this.headers = this.headers.append("Content-Type", "application/json");
    this.baseUrl = environment.BASE_URL + this.config.api.endPoint;
  }

  /**
   * Return Login Observable
   */
  public login(account: Credential): Observable<Account | Error> {
    return this.http
      .post(this.baseUrl + "/enhancedLogin", account, { headers: this.headers })
      .pipe(
        map((res: Token) => {
          this.token = res;
          this.account = this.token.user;
          this.trackLogin(this.account);
          return this.setSessionInfo(this.token, this.account);
        }),
      );
  }

  /**
   * Return Login Observable
   */
  public mfaLogin(account: {
    code: string;
    mfaToken: string;
    trustDevice: boolean;
  }): Observable<Account | Error> {
    return this.http
      .post(this.baseUrl + "/mfaLogin", account, { headers: this.headers })
      .pipe(
        map((res: Token) => {
          const mfaDeviceId = res["mfaDeviceId"];
          if (mfaDeviceId) {
            this.storageService.setItem("mfaDeviceId", mfaDeviceId);
          }
          this.token = res;
          this.account = this.token.user;
          this.trackLogin(this.account);
          return this.setSessionInfo(this.token, this.account);
        }),
      );
  }

  /**
   * Return Login Observable
   */
  public resendMfaCode(token: string): Observable<Account | Error> {
    return this.http.post(
      this.baseUrl + "/resendMfaCode",
      { token },
      { headers: this.headers },
    );
  }

  /**
   * Return account
   */
  public setSessionInfo(token: Token, account: Account): Account | Error {
    this.storageService.setItem("logged", account);
    this.storageService.setItem("access_token", token);
    this.storageService.setItem("siteMessages", account.siteMessages || {});
    this.account = account;
    this.token = token;
    Cookie.set("userId", account.id, null, "/", "cariloop.com");
    return account;
  }

  public setLocalLanguage(language: Language): void {
    this.storageService.setItem("language", language);
  }

  public getLocalLanguage() {
    return this.storageService.getItem("language");
  }

  public removeLocalLanguage(): void {
    this.storageService.removeItem("language");
  }

  /**
   * Return account
   */
  public setAccountToken(account: Account): Account {
    if (account && !account.roles && this.account && this.account.roles) {
      account.roles = [...this.account.roles];
    }
    const { id, email, firstName, lastName, language, loginCode, roles } =
      account;
    Cookie.set("userId", account.id, null, "/", "cariloop.com");
    const newAccount = Object.assign(new Account(), {
      id,
      email,
      firstName,
      lastName,
      language,
      loginCode,
      roles,
    });
    this.storageService.setItem("logged", newAccount);
    this.account = newAccount;
    return account;
  }

  public setOwnerAccount(account: Account): void {
    this.ownerAccount = account;
  }

  public getOwnerAccount(): Account {
    return this.ownerAccount;
  }

  public unSetOwneraccount(): void {
    this.ownerAccount = null;
  }

  /**
   * Return Logout Observable
   */
  public logout(): Observable<null> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/logout`;
    const headers = { headers: this.headers };
    return this.http.post(url, "", headers).pipe(
      map(() => {
        this.cleanLocalStorage();
        this.unSetOwneraccount();
        this.appState.removeLoadStatus();
        return null;
      }),
    );
  }

  public cleanLocalStorage(): void {
    this.account = null;
    this.token = null;
    this.storageService.removeItem("access_token");
    this.storageService.removeItem("logged");
    Cookie.deleteAll();
  }

  /**
   * Return Reset Password Observable
   */
  public reset(email: string): Observable<null> {
    const url = `${this.baseUrl}/resetPassword`;
    return this.http.post(url, { email }, { headers: this.headers });
  }

  /**
   * Return Reset Account Observable
   */
  public change(
    credentials: Credential,
    id: string,
    termsAcceptanceDate: Date,
    isLoginCode: boolean = false,
  ): Observable<Account> {
    const data = {
      loginCode: id,
      password: credentials.password,
      confirmation: credentials.confirm,
      isLoginCode,
      termsAcceptanceDate,
      TOSLastModifiedDate: environment.TOS_LAST_MODIFIED_DATE,
    };
    const url = `${this.baseUrl}/setNewPassword`;
    return this.http.post(url, data, { headers: this.headers });
  }

  public changeCoachPassword(
    accountId: string,
    password: string,
  ): Observable<Account> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${accountId}/set-coach-password`;
    const options = { headers: this.headers };
    return this.http.post(url, { password }, options);
  }

  /**
   * Return Token
   */
  public getToken(): string | Error {
    const token = this.token
      ? this.token
      : this.storageService.getItem("access_token");
    return token ? token.id : new Error("No Access");
  }

  /**
   * Return Account Roles
   */
  public getRoles(): Array<string> {
    const account = this.account
      ? this.account
      : this.storageService.getItem("logged");
    const hasRoles = account && account.roles;
    if (hasRoles && typeof hasRoles[0] === "string") {
      return account.roles;
    }
    const roles = hasRoles ? account.roles.map((role) => role.name) : [];
    return roles;
  }

  private getAccountFallback(): null | Account {
    const token: null | Token | string =
      this.storageService.getItem<Token>("access_token");
    const isTokenObject = token !== null && typeof token !== "string";

    if (isTokenObject && token.user !== undefined) {
      return this.setAccountToken(token.user);
    }
    this.cleanLocalStorage();
    return null;
  }

  /**
   * Return Logged Account
   */
  public getLogged(): Error | Account {
    const account: null | Account | string =
      this.account ?? this.storageService.getItem<Account>("logged");

    const isAccountObject = account !== null && typeof account !== "string";
    if (isAccountObject && account.id !== undefined && account.id !== "") {
      return account;
    }

    return this.getAccountFallback() ?? new Error("No Access");
  }

  public updateAttributes(id: string, data: any): Observable<Account> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${id}`;
    return this.http.patch(url, data, { headers: this.headers });
  }

  /**
   * Return account
   */
  public findById(accountId): Observable<Account> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const params = new HttpParams().append(
      "access_token",
      String(this.getToken()),
    );
    const url = this.baseUrl + "/" + accountId;
    return this.http.get(url, { headers: this.headers, params });
  }

  public findBytoken(account: Account, token: string): Observable<Account> {
    this.headers = this.headers.set("Authorization", String(token));
    const params = new HttpParams().append(
      "filter",
      JSON.stringify({ include: "roles" }),
    );
    const url = this.baseUrl + "/" + account.id;
    return this.http.get(url, { headers: this.headers, params }, [
      map((account: Account) => {
        account.roles = account.roles.map((role) => role.name);
        return account;
      }),
    ]);
  }

  public setKeepAlive(): Observable<any> {
    const url = this.baseUrl + "/keepAlive";
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    return this.http.post(url, {}, { headers: this.headers });
  }

  public getHeartbeat(): Observable<any> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = this.baseUrl + "/heartbeat";
    return this.http.get(url, { headers: this.headers });
  }

  public authHeartbeat(next): Observable<boolean> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = this.baseUrl + "/heartbeat";
    const stages = [
      map(() => {
        next(true);
        return true;
      }),
      catchError(() => {
        next(false);
        return of(false);
      }),
    ];
    return this.http.get(url, { headers: this.headers }, stages);
  }

  /**
   * Return completeRegisterByCode Account Observable
   */
  public completeRegisterByCode(code: string, data): Observable<Account> {
    const url = `${this.baseUrl}/completeRegisterByCode`;
    return this.http.post(url, { code, data }, { headers: this.headers });
  }

  public getLastCaseVisited(
    id: string,
  ): Observable<{ props: { pathName: string } }> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${id}/lastVisit`;
    return this.http.get(url, { headers: this.headers });
  }

  public getAccountByLoginCode(code: string): Observable<Account> {
    const url = `${this.baseUrl}/getByLoginCode`;
    return this.http.post(url, { code }, { headers: this.headers });
  }

  public hasAccessToTransaction(accountId, transactionId): Observable<boolean> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${accountId}/hasAccessToTransaction`;
    return this.http.post(url, { transactionId }, { headers: this.headers }, [
      map((res: boolean) => res),
    ]);
  }

  public getAccountOrganizations(accountId: string): Observable<any> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${accountId}/AccountOrganizations`;
    return this.http.get(url, { headers: this.headers });
  }

  public trackLogin(account: Account) {
    if (account.id) {
      this.analyticsService.identifyUser(account.id);
      this.analyticsService.setUser({
        roles: account.roles,
        id: account.id,
        language: account.language,
      });
      this.analyticsService.trackEvent("login_success", {
        userId: account.id,
        email: account.email,
      });
    }
  }

  public getHomeUrl() {
    return `${environment.PLAN_URL}/home`;
  }

  public getAccountOrg(id: string): Observable<any> {
    this.headers = this.headers.set("Authorization", String(this.getToken()));
    const url = `${this.baseUrl}/${id}/getOrganization`;
    return this.http.get(url, { headers: this.headers });
  }

  eligibilityCheck(data: EligibilityCheckInfo) {
    const accountResult = this.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    return this.http
      .post<void>(
        `${this.baseUrl}/${accountResult.id}/eligibility-check`,
        data,
        {
          headers: {
            Authorization: String(this.getToken()),
            "Content-Type": "application/json",
          },
        },
      )
      .pipe(catchError((error) => this.handleError(error)));
  }

  private handleError(errorResponse: HttpErrorResponse) {
    const message =
      errorResponse.error instanceof ErrorEvent
        ? // A client-side or network error occurred. Handle it accordingly.
          `An error ocurred: ${errorResponse.error.message}`
        : // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong.
          `Backend returned code ${errorResponse.status}: ${errorResponse.message}`;

    this.errorReportingService.send(errorResponse);

    const error: HandledObservableError = {
      status:
        errorResponse.error instanceof ErrorEvent ? null : errorResponse.status,
      message,
    };

    return throwError(error);
  }

  public getPhoto(account: Account): string {
    return account?.photo
      ? `${environment.BASE_URL}assets/account_${account.id}/download/${encodeURIComponent(account.photo.name)}?access_token=${this.getToken()}`
      : "assets/img/AvatarIcon.png";
  }
}
