import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "app/../environments/environment";
import { AccountService } from "./account.service";
import { AccountCases } from "../types/account-case.type";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { BaseModelService } from "./base-model.service";
import { Case } from "../types/case.type";

@Injectable()
export class AccountCasesService {
  private baseUrl = "";
  private headers: HttpHeaders = new HttpHeaders();
  private query: Object = {};

  constructor(
    public http: BaseModelService,
    private accountService: AccountService,
  ) {
    this.headers = this.headers.append("Accept", "application/json");
    this.headers = this.headers.append("Content-Type", "application/json");
    this.baseUrl = environment.BASE_URL + "accountcases";
  }

  public searchOwners(accountId: string): Observable<Case[]> {
    this.query = {
      where: { accountId },
      order: "role",
    };
    const params = new HttpParams().append(
      "filter",
      JSON.stringify(this.query),
    );
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    const url = `${environment.BASE_URL}accounts/${accountId}/memberCases`;
    return this.http.get(url, { headers: this.headers, params });
  }

  public getAccountCases(
    accountId: string,
    filter: Object,
  ): Observable<AccountCases[]> {
    this.query = filter;
    const params = new HttpParams().append(
      "filter",
      JSON.stringify(this.query),
    );
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    const url = `${environment.BASE_URL}accounts/${accountId}/account-cases`;
    return this.http.get(url, { headers: this.headers, params });
  }
}
