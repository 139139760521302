import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';
import { AccountService } from './account.service';
import { Observable } from 'rxjs';
import { Event } from '../types/event.type';
import { BaseModelService } from './base-model.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class EventsService {

  private baseUrl = `${environment.BASE_URL}AppEvents`;
  private headers: HttpHeaders = new HttpHeaders();

  constructor(public http: BaseModelService, private accountService: AccountService) {
    this.headers = this.headers.append('Accept', 'application/json');
    this.headers = this.headers.append('Content-Type', 'application/json');
  }

  public track(event, props?) {
    const newEvent: Event = new Event();
    newEvent.event = event;
    newEvent.props = props;
    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      return console.log('Can not access user instance');
    } else {
      newEvent.eventBy = account.id;
    }
    this.addEvent(newEvent).subscribe();
  }

  public addResetPasswordLinkAccessEvent(loginCode: string, code: boolean): Observable<any> {
    const url = `${this.baseUrl}/resetPasswordEvent`;
    return this.http.post(url, { loginCode, code }, { headers: this.headers });
  }

  private addEvent(eventInfo: Event): Observable<any> {
    this.headers = this.headers.set('Authorization', String(this.accountService.getToken()));
    const url = this.baseUrl;
    return this.http.post(url, eventInfo, { headers: this.headers });
  }
}
