import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChooseAdventureComponent } from "./choose-adventure/choose-adventure.component";
import { CoachUpdatePasswordComponent } from "./coach-update-password/coach-update-password.component";
import { CompleteRegisterComponent } from "./complete-register/complete-register.component";
import { flutterExistingRegistrationWebViewGuard } from "./flutter-existing-registration-web-view.guard";
import { LoginComponent } from "./login/login.component";
import { LogoutComponent } from "./logout.component";
import { RequestPasswordComponent } from "./request-password/request-password.component";
import { UrbanSitterGuard } from "./urban-sitter.guard";
import { ValidateSessionGuard } from "./validate-session.guard";

const AccessRoutes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [
      flutterExistingRegistrationWebViewGuard,
      UrbanSitterGuard,
      ValidateSessionGuard,
    ],
  },
  { path: "logout", component: LogoutComponent },
  { path: "new-password", component: CoachUpdatePasswordComponent },
  { path: "requestPassword/:id", component: RequestPasswordComponent },
  { path: "confirm/:id", component: RequestPasswordComponent },
  { path: "reset/:id", component: RequestPasswordComponent },
  { path: "case/:id/register/:code", component: CompleteRegisterComponent },
  { path: "case/:id", component: CompleteRegisterComponent },
  { path: "choose-adventure", component: ChooseAdventureComponent },
  {
    path: "enrollment",
    loadChildren: () =>
      import("app/enrollment-code/enrollment-code.module").then(
        (m) => m.EnrollmentCodeModule,
      ),
  },
  {
    path: "expired-invite",
    loadChildren: () =>
      import("app/expired-invite/expired-invite.module").then(
        (m) => m.ExpiredInviteModule,
      ),
  },
  {
    path: "register",
    loadChildren: () =>
      import("app/register/register.module").then((m) => m.RegisterModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(AccessRoutes)],
  exports: [RouterModule],
})
export class AccessRouteModule {}
