<div class="mobile-app-cta-backdrop" *ngIf="!isPopupDismissed">
  <div class="content vertical-center">
    <div class="info vertical-center small-gap">
      <div class="logo vertical-center">
        <img src="assets/img/white-loop.svg" alt="Logo" />
      </div>
      <h1>{{ "LOGIN.MOBILE_WEB_CTA.GET_APP" | translate }}</h1>

      <p class="description">
        {{ "LOGIN.MOBILE_WEB_CTA.GET_APP_DESCRIPTION" | translate }}
      </p>
    </div>
    <div class="buttons vertical-center">
      <c-button
        [text]="'LOGIN.MOBILE_WEB_CTA.ACTION_BUTTON' | translate"
        buttonType="action"
        buttonStyle="fill"
        size="large"
        (onClick)="onUseApp()"
      ></c-button>
      <c-button
        [text]="'LOGIN.MOBILE_WEB_CTA.DISMISSAL_BUTTON' | translate"
        buttonType="neutral"
        buttonStyle="outline"
        size="large"
        (onClick)="onContinueInBrowser()"
      ></c-button>
    </div>
  </div>
</div>
