import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { environment } from "environments/environment";
import { of } from "rxjs/";
import { catchError, map } from "rxjs/operators";
import { ProvisioningService } from "../services/provisioning.service";

@Injectable({
  providedIn: "root",
})
export class RegisterProvisioningGuard {
  constructor(private provisioningService: ProvisioningService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.provisioningService.getProfileGeneral().pipe(
      map((profile) => {
        if (profile?.newAccountRegistrationGeneralAccess?.value) {
          return true;
        }
        window.location.href = `${environment.AUTH_URL}/login`;
        return false;
      }),
      catchError(() => {
        window.location.href = `${environment.AUTH_URL}/login`;
        return of(false);
      }),
    );
  }
}
