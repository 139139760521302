import { HttpUrlEncodingCodec, HttpParams } from '@angular/common/http';

class CustomQueryEncoderHelper extends HttpUrlEncodingCodec {
  encodeKey(k: string): string {
    k = super.encodeKey(k);
    return k.replace(/\+/gi, '%2B');
  }
  encodeValue(v: string): string {
    v = super.encodeValue(v);
    return v.replace(/\+/gi, '%2B');
  }
}

export const getHttpUrlEncoder = () => {
  return new HttpParams({ encoder: new CustomQueryEncoderHelper() });
}
