import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, UrlTree } from "@angular/router";
import { AnalyticsService } from "@cariloop/analytics";
import { AppState } from "app/app.service";
import { AccountRegistrationService } from "app/shared/services/account-registration.service";
import { AccountService } from "app/shared/services/account.service";
import { CaseService } from "app/shared/services/case.service";
import { DevicePlatformService } from "app/shared/services/device-platform.service";
import { RedirectService } from "app/shared/services/redirect.service";
import { Account } from "app/shared/types/account.type";
import { AppStateType } from "app/shared/types/app-state-type";
import { Token } from "app/shared/types/token.type";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { of } from "rxjs/";
import { catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ValidateSessionGuard {
  constructor(
    private accountService: AccountService,
    private caseService: CaseService,
    private appService: AppState,
    private redirectService: RedirectService,
    private accountRegistrationService: AccountRegistrationService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private devicePlatformService: DevicePlatformService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.redirectService.init(route);
    const appState: AppStateType = {
      authenticated: false,
      path: {
        root: "sessionGuard",
      },
    };
    // Detect if is admin domain
    const isAdminUrl = window.location.hostname.includes("admin.cariloop.com");
    if (isAdminUrl) {
      window.location.href = `${environment.AUTH_URL}/login?admin=1`;
    }
    // Get all query params
    // Validate sso login
    if (route.queryParams.token && route.queryParams.id) {
      this.appService.appStateUpdate(appState);
      return this.ssoLogin(route);
    }
    // Detect if there is a current session
    const isLogged = this.checkIfLogged();
    if (isLogged) {
      this.appService.appStateUpdate(appState);
      return this.validateSession(route);
    }
    // Go to login screen http://local-plan.cariloop.com/login?token=dqLS29LTTcCj9rYY3LfUFZCOfW7bXSycs09jIq40gickY75DqCsK5BAPS1udaHXE&id=5c8bc5d05037cdc936a29ef8;
    return true;
  }

  private ssoLogin(next: ActivatedRouteSnapshot) {
    const { token, id, redirect, urbanSitter } = next.queryParams;
    const account: Account = new Account();
    account.id = id;
    const tokenObject: Token = {
      id: token,
      ttl: "43200",
      user: account,
    };
    return this.accountService.findBytoken(account, token).pipe(
      switchMap((accountRes: Account | false) => {
        if (!accountRes) {
          return of(false);
        }
        tokenObject.user = accountRes;
        this.accountService.setSessionInfo(tokenObject, accountRes);
        this.accountService.trackLogin(accountRes);

        if (urbanSitter) {
          this.router.createUrlTree(["loading-state"], {
            queryParams: { urbanSitter: true },
          });
          return of(false);
        }
        return this.getRedirect(redirect, next);
      }),
      catchError(() => of(true)),
    );
  }

  private validateSession(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { redirect, redirectUrl } = route.queryParams;

    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }

    return this.accountService.getHeartbeat().pipe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap((res) =>
        this.accountRegistrationService.missingSteps(account.id).pipe(
          switchMap((res) => {
            if (res && res.length > 0) {
              this.router.navigate(["/register/complete"], {
                queryParams: route.queryParams,
              });
              this.appService.appStateUpdate({
                authenticated: false,
                path: {
                  root: "register",
                },
              });
              return of(false);
            }
            return of(true);
          }),
        ),
      ),
      switchMap((res) => {
        if (!res) {
          return of(res);
        }
        return this.accountService.getAccountOrg(account.id).pipe(
          switchMap((org) => {
            if (this.shouldRedirectToEmployerNotActivePage(org)) {
              this.analyticsService.trackEvent(
                "inactive_employer_redirected_from_login",
                {
                  accountId: account.id,
                  deviceType:
                    this.devicePlatformService.getPlatformForMixpanel(),
                },
              );
              this.router.navigate(["/register/not-ready"]);
              return of(false);
            }
            return of(true);
          }),
        );
      }),
      switchMap((res) => {
        if (!res) {
          return of(res);
        }
        if (account.shouldCreateCase) {
          return this.caseService.getCreateCaseUrl().pipe(
            switchMap((url) => {
              window.location.href = url;
              return of(false);
            }),
          );
        }
        return this.getRedirect(redirect || redirectUrl, route);
      }),
      catchError(() => {
        this.accountService.cleanLocalStorage();
        this.appService.appStateUpdate({
          authenticated: false,
          path: {
            root: "login",
          },
        });
        return of(true);
      }),
    );
  }

  private getRedirect(
    redirect: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    // If coming from OAuth
    if (this.redirectService.isOauth) {
      return this.redirectService.onLogin();
    }
    const comeFromAdmin = route.queryParams["admin"] ?? false;
    return this.redirectService
      .getUserRedirect({
        redirect,
        comeFromAdmin,
      })
      .pipe(
        switchMap((url) => {
          window.location.href = url;
          return of(false);
        }),
      );
  }

  private checkIfLogged() {
    return !(this.accountService.getLogged() instanceof Error);
  }

  private shouldRedirectToEmployerNotActivePage(org): boolean {
    if (org?.contractStartDate) {
      const roles = this.accountService.getRoles() ?? [];
      const contractStartDate = new Date(org.contractStartDate),
        today = new Date();

      contractStartDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      return (
        contractStartDate > today &&
        roles.length == 1 &&
        roles.includes("$user")
      );
    }
    return false;
  }
}
