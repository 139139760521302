import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  signal,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from "@angular/forms";
import { AnalyticsService } from "@cariloop/analytics";
import { emailPattern, phonePattern } from "@cariloop/ui-components";
import { AccountService } from "app/shared/services/account.service";
import {
  DevicePlatformService,
  MixPanelPlatform,
} from "app/shared/services/device-platform.service";
import type { EmailType } from "app/shared/types/account.type";
import type { RegisteredEmail } from "app/shared/types/register.types";
import { EMPTY } from "rxjs";
import { catchError, startWith } from "rxjs/operators";

type AlternateContactMethod = "personalEmail" | "phoneNumber";
type ErrorType = "email-already-used" | "generic-error" | "gateway-timeout";

type State = {
  hasServerSideError: boolean;
  errorType: ErrorType;
  isSubmitting: boolean;
};

const INITIAL_STATE: State = {
  hasServerSideError: false,
  errorType: undefined,
  isSubmitting: false,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // We need this to change inner material styles and follow the design.
  encapsulation: ViewEncapsulation.None,
  selector: "app-existing-backup-contact",
  templateUrl: "./existing-backup-contact.component.html",
  styleUrls: ["./existing-backup-contact.component.scss"],
})
export class ExistingBackupContactComponent implements OnInit {
  @Output()
  nextStep = new EventEmitter<RegisteredEmail>();

  emailAddress = this.getAccountInfo().email;
  accountId = this.getAccountInfo().id;
  platform: MixPanelPlatform;

  state = signal<State>(INITIAL_STATE);

  existingBackupContactForm = this.formBuilder.group<{
    emailType: EmailType;
    alternateContactMethodGroup: FormGroup<{
      alternateContactMethod: FormControl<AlternateContactMethod>;
      personalEmail: FormControl<string>;
      phoneNumber: FormControl<string>;
    }>;
  }>({
    emailType: "personal",
    alternateContactMethodGroup: this.formBuilder.group({
      alternateContactMethod: "personalEmail" as AlternateContactMethod,
      personalEmail: ["", [Validators.pattern(emailPattern)]],
      phoneNumber: ["", [Validators.pattern(phonePattern)]],
    }),
  });

  selectedEmailType$ = this.existingBackupContactForm
    .get("emailType")
    .valueChanges.pipe(
      startWith(this.existingBackupContactForm.get("emailType").value),
    );

  selectedAlternateContactMethod$ = this.existingBackupContactForm
    .get("alternateContactMethodGroup")
    .get("alternateContactMethod")
    .valueChanges.pipe(
      startWith(
        this.existingBackupContactForm
          .get("alternateContactMethodGroup")
          .get("alternateContactMethod").value,
      ),
    );

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly analyticsService: AnalyticsService,
    private readonly accountService: AccountService,
    private readonly devicePlatformService: DevicePlatformService,
  ) {}

  ngOnInit(): void {
    this.platform = this.devicePlatformService.getPlatformForMixpanel();

    this.analyticsService.trackEvent("back-up-contact", {
      platform: this.platform,
      source: "auth",
      accountId: this.accountId,
      userFlow: "update-existing-account",
    });
  }

  handleSubmit() {
    this.state.set(INITIAL_STATE);

    const emailType = this.existingBackupContactForm.get("emailType").value;
    const alternateContactMethod = this.existingBackupContactForm
      .get("alternateContactMethodGroup")
      .get("alternateContactMethod").value;

    this.updateValidators(emailType, alternateContactMethod);

    // This is needed because `c-input-text`s only shows their inner `mat-error` if the input is `touched`
    this.existingBackupContactForm.markAllAsTouched();

    if (!this.existingBackupContactForm.valid) {
      return;
    }

    this.state.update((currentState) => ({
      ...currentState,
      isSubmitting: true,
    }));
    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }
    this.accountService
      .updateAttributes(
        account.id,
        this.getRequestDataFromForm(emailType, alternateContactMethod),
      )
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          const error = {
            ...errorResponse.error.error,
          };
          let errorType: ErrorType = "generic-error";

          if (
            error.statusCode === 422 &&
            error.message === "Secondary email is already in use."
          ) {
            errorType = "email-already-used";
          } else if (errorResponse.status === 504) {
            errorType = "gateway-timeout";
          }

          this.state.update((currentState) => ({
            ...currentState,
            isSubmitting: false,
            hasServerSideError: true,
            errorType,
          }));

          return EMPTY;
        }),
      )
      .subscribe({
        next: () => {
          this.analyticsService.trackEvent("back-up-contact-complete", {
            platform: this.platform,
            source: "auth",
            accountId: this.accountId,
            userFlow: "update-existing-account",
          });

          this.state.update((currentState) => ({
            ...currentState,
            isSubmitting: false,
          }));

          this.nextStep.emit({
            type: emailType,
            address: this.emailAddress,
          });
        },
      });
  }

  private getAccountInfo(): { email: string; id: string } {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    return { email: accountResult.email, id: accountResult.id };
  }

  private updateValidators(
    emailType: EmailType,
    alternateContactMethod: AlternateContactMethod,
  ) {
    if (emailType === "personal") {
      return;
    }

    const personalEmailInput = this.existingBackupContactForm
      .get("alternateContactMethodGroup")
      .get("personalEmail");

    const phoneNumberInput = this.existingBackupContactForm
      .get("alternateContactMethodGroup")
      .get("phoneNumber");

    if (alternateContactMethod === "personalEmail") {
      phoneNumberInput.clearValidators();
      phoneNumberInput.updateValueAndValidity();

      personalEmailInput.setValidators([
        Validators.pattern(emailPattern),
        Validators.required,
      ]);
      personalEmailInput.updateValueAndValidity();
    }

    if (alternateContactMethod === "phoneNumber") {
      personalEmailInput.clearValidators();
      personalEmailInput.updateValueAndValidity();

      phoneNumberInput.setValidators([
        Validators.pattern(phonePattern),
        Validators.required,
      ]);
      phoneNumberInput.updateValueAndValidity();
    }
  }

  private getRequestDataFromForm(
    emailType: EmailType,
    alternateContactMethod: AlternateContactMethod,
  ) {
    const email = this.existingBackupContactForm
      .get("alternateContactMethodGroup")
      .get("personalEmail").value;
    const phone = this.existingBackupContactForm
      .get("alternateContactMethodGroup")
      .get("phoneNumber").value;

    const data: any = { emailType };
    if (emailType !== "personal") {
      if (alternateContactMethod === "personalEmail") {
        data.email2 = email;
        data.email2Type = "personal";
      } else {
        data.phone = phone;
      }
    }

    return data;
  }
}
