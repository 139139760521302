/*
 * Angular 2 decorators and services
 */
import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Account } from "./shared/types/account.type";
import { AccountService } from "./shared/services/account.service";
import { AppState } from "./app.service";
import { TranslateService } from "@ngx-translate/core";
import { AppTranslateService } from "./shared/services/app-translate.service";
import { Language } from "./shared/types/language.type";
import { AppStateType } from "./shared/types/app-state-type";
import { environment } from "../environments/environment";
import { LANGUAGES } from "./languages";
import * as Sentry from "@sentry/angular";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { ErrorReportingService } from "./shared/services/error-reporting.service";

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: "app",
  styleUrls: ["./app.component.scss"],
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit {
  public isLogged: boolean;
  public loggedUser: Account;
  public isMobile = false;
  public availableLangs: Array<Language> = [];
  public rootName = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appState: AppState,
    private accountService: AccountService,
    private titleService: Title,
    private translate: TranslateService,
    private appTranslate: AppTranslateService,
    private errorReportingService: ErrorReportingService,
    private gaService: GoogleAnalyticsService,
  ) {
    this.errorSubscription();
    this.languageSubscription();
    this.setRaygunContext();
    this.setLanguage();
  }

  public ngOnInit() {
    if (window.innerWidth < 700) {
      this.isMobile = true;
    }
    this.routerSubscription();
    this.appStateSubscription();
    this.isLogged = this.checkIfLogged();
  }

  public errorSubscription(): void {
    this.appState.onStatusChange().subscribe((status) => {
      if (status === "inactive") {
        this.confirmServerStatus();
      } else if (status === "active") {
        console.log("Server status: Ok");
      }
    });
  }

  public setLanguage(): void {
    const currentUser = this.accountService.getLogged();
    const memoryLang = this.accountService.getLocalLanguage();
    let currentLang: string;
    this.translate.setDefaultLang("en");
    if (memoryLang && memoryLang.value) {
      currentLang = memoryLang.value;
    } else {
      currentLang = currentLang =
        !(currentUser instanceof Error) && currentUser.language
          ? currentUser.language
          : this.translate.getBrowserLang();
    }
    this.switchLanguage(currentLang);
  }

  public languageSubscription(): void {
    this.appTranslate.onLangChange().subscribe((lang) => {
      this.switchLanguage(lang);
    });
  }

  confirmServerStatus(): void {
    setTimeout(() => {
      this.validateStatus();
    }, 500);
  }

  private validateStatus(): void {
    this.accountService.getHeartbeat().subscribe(
      (res) => {
        console.log("Server status: Ok");
      },
      (err) => {
        if (err.status === 0 || err.status >= 503) {
          this.router.navigate(["/disconnection"]);
        } else {
          this.appState.status({ status: 200 });
          this.router.navigate(["/login"]);
        }
      },
    );
  }

  public filterLanguages(
    currentLang: string,
    langList: Array<Language>,
  ): Array<Language> {
    return langList.filter((lang) => {
      return lang.value !== currentLang;
    });
  }

  public switchLanguage(language = "en"): void {
    const isSupportedLang = LANGUAGES.some((lang) => lang.value == language);
    if (!isSupportedLang) {
      language = "en";
    }
    this.availableLangs = this.filterLanguages(
      language || this.translate.getDefaultLang(),
      LANGUAGES,
    );
    this.translate.use(language);
  }

  public routerSubscription(): void {
    this.router.events.subscribe((event: any) => {
      this.isLogged = this.checkIfLogged();
      if (event instanceof NavigationEnd) {
        let excludeEnv = ["local", "bizdev", "alpha", "beta"];
        if (!excludeEnv.includes(environment.envName)) {
          // Google analytics User navigation tracking
          const path = this.getCurrentPath(this.route).replace(
            /\:[a-zA-Z0-9]+/g,
            "*",
          );
          this.gaService.pageView(path);
        }
      }
    });
  }

  public appStateSubscription(): void {
    this.appState.onAppStateUpdate().subscribe((update) => {
      this.manageAppState(update);
    });
  }

  private getCurrentPath(route: ActivatedRoute): string {
    const path =
      route.routeConfig && route.routeConfig.path
        ? "/" + route.routeConfig.path
        : "";
    if (route.children && route.children.length > 0) {
      return path + this.getCurrentPath(route.children[0]);
    } else {
      return path;
    }
  }

  manageAppState(state: AppStateType) {
    this.rootName = state.path.root;
    this.isLogged = this.checkIfLogged();
    this.titleService.setTitle("Cariloop");
  }

  public setRaygunContext(): void {
    this.isLogged = this.checkIfLogged();
    if (!this.isLogged) {
      return;
    }
    Sentry.setUser({
      id: this.loggedUser.id,
      isAnonymous: false,
      organizationId: this.loggedUser.organizationId,
    });
  }

  public onResize(event) {
    this.isMobile = event.target.innerWidth < 700;
  }

  private checkIfLogged() {
    const getLoggedResult = this.accountService.getLogged();
    if (getLoggedResult instanceof Error) {
      return false;
    }

    this.loggedUser = getLoggedResult;
    return true;
  }

  private errorHandler(err) {
    this.appState.status(err);
    if (err.status === 401) {
      this.accountService.cleanLocalStorage();
      this.router.navigate(["/login"]);
    }
    console.log(err);
    this.errorReportingService.send(err);
  }
}
