import { Platform } from "@angular/cdk/platform";
import { Injectable, inject } from "@angular/core";
import { StorageService } from "./storage.service";

export type DeviceType = "ios" | "android" | "browser" | "other";
export type MixPanelPlatform = "native" | "mobile" | "web";

@Injectable({
  providedIn: "root",
})
export class DevicePlatformService {
  readonly #platform = inject(Platform);
  readonly #storageService = inject(StorageService);

  getDeviceType(): DeviceType {
    switch (true) {
      case this.#platform.IOS:
        return "ios";
      case this.#platform.ANDROID:
        return "android";
      case this.#platform.isBrowser:
        return "browser";
      default:
        return "other";
    }
  }

  isMobileDevice(): boolean {
    const deviceType = this.getDeviceType();
    return deviceType === "ios" || deviceType === "android";
  }

  getPlatformForMixpanel(): MixPanelPlatform {
    switch (true) {
      case this.isNative():
        return "native";
      case this.#platform.IOS:
      case this.#platform.ANDROID:
        return "mobile";
      default:
        return "web";
    }
  }

  isNative() {
    return this.#storageService.getItem<string>("platform") === "native";
  }
}
