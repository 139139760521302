import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AccountService } from "./account.service";
import { BaseModelService } from "./base-model.service";

export interface ProvisioningAddCaseValue {
  showOld: boolean;
  showNew: boolean;
  default?: "old" | "new";
}

export interface ProvisioningPasswordComplexity {
  capitalLetters: boolean;
  commonPasswords: boolean;
  length: number;
  numbers: boolean;
  symbols: boolean;
}

export interface ProvisioningProfile {
  addCase?: {
    value: ProvisioningAddCaseValue;
  };
  adminLibrary?: {
    value: boolean;
  };
  passwordComplexity?: {
    value: ProvisioningPasswordComplexity;
  };
  urbanSitterMemberSSO?: {
    value: boolean;
  };
  giftCariloop?: {
    value:
      | { enabled: false }
      | {
          enabled: true;
          redeemFlowDate: string;
        };
  };
  newAccountRegistration?: {
    value: boolean;
  };
  newAccountRegistrationGeneralAccess?: {
    value: boolean;
  };
  mobileAdoptionCTA?: {
    value: boolean;
  };
}

@Injectable({
  providedIn: "root",
})
export class ProvisioningService {
  private baseUrl = "";
  private headers = new HttpHeaders();
  private lastAccountId: string;
  private provisioningProfile: ProvisioningProfile;
  private publicProvisionUrl = "";

  /**
   * Constructor
   */
  constructor(
    private http: BaseModelService,
    private accountService: AccountService,
  ) {
    this.headers.append("Accept", "application/json");
    this.headers.append("Content-Type", "application/json");
    this.baseUrl = `${environment.BASE_URL}accounts`;
    this.publicProvisionUrl = `${environment.BASE_URL}provisioningprofiles/public`;
  }

  public getProvisioningProfile(id: string): Observable<ProvisioningProfile> {
    if (this.lastAccountId === id && this.provisioningProfile) {
      return of(this.provisioningProfile);
    }
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    const url = `${this.baseUrl}/${id}/getProvisioningProfile`;
    const params = new HttpParams();
    return this.http
      .get<ProvisioningProfile>(url, { headers: this.headers, params })
      .pipe(
        tap((val) => {
          this.lastAccountId = id;
          this.provisioningProfile = val;
        }),
      );
  }

  public getProvisioningByOrg(orgId: string, filter?: { keys: string[] }) {
    const url = `${environment.BASE_URL}organizations/getprovisioningprofilebyorglimited?orgId=${orgId}`;
    const params = new HttpParams().set("filter", JSON.stringify(filter ?? {}));
    return this.http.get<ProvisioningProfile>(url, {
      headers: this.headers,
      params,
    });
  }

  public getProfileGeneral() {
    const url = `${environment.BASE_URL}/provisioningprofiles/public`;
    return this.http.get<ProvisioningProfile>(url, this.headers);
  }

  /**
   * Is responsible for public permissions
   * with General type.
   * @returns
   */
  public getPublicProvisioningProfiles() {
    return this.http.get<ProvisioningProfile>(this.publicProvisionUrl, {});
  }
}
