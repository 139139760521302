<div
  class="password-field"
  [ngClass]="{ 'password-field--invalid': control.invalid && control.touched }"
>
  <label *ngIf="label" for="password-field" class="password-field__label">
    {{ label }}
  </label>
  <div class="password-input-container">
    <input
      id="password-field"
      class="password-field__input"
      type="{{ isPasswordVisible ? 'text' : 'password' }}"
      [placeholder]="placeholder"
      [required]="required"
      [disabled]="disabled"
      [(ngModel)]="password"
      (ngModelChange)="changePassword($event)"
      [autocomplete]="autocomplete"
      (blur)="onTouched()"
    />
    <button
      [attr.aria-label]="
        (isPasswordVisible
          ? 'LOGIN.PASSWORD_VISIBILITY_ON'
          : 'LOGIN.PASSWORD_VISIBILITY_OFF'
        ) | translate
      "
      type="button"
      class="password-toogle-button"
      (click)="tooglePasswordVisibility()"
    >
      <span class="material-symbols-rounded password-toogle-button__icon">
        {{ isPasswordVisible ? "visibility" : "visibility_off" }}
      </span>
    </button>
  </div>
  <div
    aria-live="polite"
    *ngIf="shouldShowError()"
    class="password-field__error-message"
  >
    <ng-content></ng-content>
  </div>
  <div
    aria-live="polite"
    aria-atomic="true"
    *ngIf="password && control.errors?.passwordInvalid"
    class="password-strength-indicator"
  >
    <p class="password-strength-indicator__title">
      {{
        "REGISTER.GENERIC_FORM.PASSWORD.STRENGTH_INDICATOR_TITLE" | translate
      }}
    </p>
    <ul class="password-strength-indicator__list">
      <li
        class="password-strength-indicator__item"
        [ngClass]="{
          'password-strength-indicator__item--valid':
            passwordState.isLengthValid
        }"
      >
        <span class="sr-only">{{
          "FORM_ERRORS.PASSWORD_REQUIREMENT" | translate
        }}</span>
        <span
          aria-hidden="true"
          class="material-symbols-rounded password-strength-indicator__icon"
        >
          {{ passwordState.isLengthValid ? "check_circle" : "cancel" }}
        </span>
        <span class="password-strength-indicator__text">
          {{ "REGISTER.GENERIC_FORM.PASSWORD.LENGTH_VALIDATOR" | translate }}
        </span>
        <span class="sr-only">{{
          (passwordState.isLengthValid
            ? "FORM_ERRORS.STATUS_MET"
            : "FORM_ERRORS.STATUS_NOT_MET"
          ) | translate
        }}</span>
      </li>

      <li
        *ngIf="passwordComplexity.numbers"
        class="password-strength-indicator__item"
        [ngClass]="{
          'password-strength-indicator__item--valid': passwordState.hasNumber
        }"
      >
        <span class="sr-only">{{
          "FORM_ERRORS.PASSWORD_REQUIREMENT" | translate
        }}</span>
        <span
          aria-hidden="true"
          class="material-symbols-rounded password-strength-indicator__icon"
        >
          {{ passwordState.hasNumber ? "check_circle" : "cancel" }}
        </span>
        <span class="password-strength-indicator__text">
          {{ "REGISTER.GENERIC_FORM.PASSWORD.NUMBER_VALIDATOR" | translate }}
        </span>
        <span class="sr-only">{{
          (passwordState.hasNumber
            ? "FORM_ERRORS.STATUS_MET"
            : "FORM_ERRORS.STATUS_NOT_MET"
          ) | translate
        }}</span>
      </li>

      <li
        *ngIf="passwordComplexity.symbols"
        class="password-strength-indicator__item"
        [ngClass]="{
          'password-strength-indicator__item--valid':
            passwordState.hasSpecialChar
        }"
      >
        <span class="sr-only">{{
          "FORM_ERRORS.PASSWORD_REQUIREMENT" | translate
        }}</span>
        <span
          aria-hidden="true"
          class="material-symbols-rounded password-strength-indicator__icon"
        >
          {{ passwordState.hasSpecialChar ? "check_circle" : "cancel" }}
        </span>
        <span class="password-strength-indicator__text">
          {{ "REGISTER.GENERIC_FORM.PASSWORD.SYMBOLS_VALIDATOR" | translate }}
        </span>
        <span class="sr-only">{{
          (passwordState.hasSpecialChar
            ? "FORM_ERRORS.STATUS_MET"
            : "FORM_ERRORS.STATUS_NOT_MET"
          ) | translate
        }}</span>
      </li>

      <li
        *ngIf="passwordComplexity.capitalLetters"
        class="password-strength-indicator__item"
        [ngClass]="{
          'password-strength-indicator__item--valid':
            passwordState.hasUpperCase && passwordState.hasLowerCase
        }"
      >
        <span class="sr-only">{{
          "FORM_ERRORS.PASSWORD_REQUIREMENT" | translate
        }}</span>
        <span
          aria-hidden="true"
          class="material-symbols-rounded password-strength-indicator__icon"
        >
          {{
            passwordState.hasUpperCase && passwordState.hasLowerCase
              ? "check_circle"
              : "cancel"
          }}
        </span>
        <span class="password-strength-indicator__text">
          {{
            "REGISTER.GENERIC_FORM.PASSWORD.UPPERCASE_AND_LOWERCASE_VALIDATOR"
              | translate
          }}
        </span>
        <span class="sr-only">{{
          (passwordState.hasUpperCase
            ? "FORM_ERRORS.STATUS_MET"
            : "FORM_ERRORS.STATUS_NOT_MET"
          ) | translate
        }}</span>
      </li>
    </ul>
  </div>
</div>
