import { Injectable } from "@angular/core";
import { Observable, pipe } from "rxjs/";
import { retry } from "rxjs/operators";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

interface HttpOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: "body";
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: "json";
  withCredentials?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class BaseModelService {
  constructor(public http: HttpClient) {}

  post<T>(
    url: string,
    body: any,
    options: HttpOptions,
    stages: Array<any> = [],
  ): Observable<T> {
    if (stages.length > 0) {
      const customPipe = pipe.apply(this, stages);
      return this.http.post<T>(url, body, options).pipe(customPipe);
    }
    return this.http.post<T>(url, body, options);
  }

  patch<T>(url: string, body: any, options: HttpOptions): Observable<T> {
    return this.http.patch<T>(url, body, options);
  }

  put<T>(url: string, body: any, options: HttpOptions): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  get<T>(url: string, options: any, stages: Array<any> = []): Observable<T> {
    const allStages = [retry(3), ...stages];
    const currentPipe = pipe.apply(this, allStages);
    return this.http.get(url, options).pipe(currentPipe);
  }

  delete<T>(url: string, options: HttpOptions): Observable<T> {
    return this.http.delete<T>(url, options);
  }
}
