import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  DestroyRef,
} from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { MatLegacyFormField as MatFormField } from "@angular/material/legacy-form-field";
import { ActivatedRoute, Router } from "@angular/router";
import { isValidEmail } from "@cariloop/ui-components";
import { environment } from "app/../environments/environment";
import type { MfaType } from "app/access/login/mfa-login/mfa-type.type";
import { OrganziationService } from "app/shared/services/organization.service";
import { ProvisioningService } from "app/shared/services/provisioning.service";
import { RedirectService } from "app/shared/services/redirect.service";
import { StorageService } from "app/shared/services/storage.service";
import { UrbanSitterService } from "app/shared/services/urban-sitter.service";
import type { CapiError } from "app/shared/types/capi-error.type";
import * as Sentry from "@sentry/angular";
import { AppState } from "../../app.service";
import { AccountService } from "../../shared/services/account.service";
import { AppTranslateService } from "../../shared/services/app-translate.service";
import { CaseService } from "../../shared/services/case.service";
import { ErrorReportingService } from "../../shared/services/error-reporting.service";
import { Account } from "../../shared/types/account.type";
import { Credential } from "../../shared/types/credential.type";
import { DialogComponent } from "./dialog/dialog.component";
import {
  DevicePlatformService,
  DeviceType,
} from "app/shared/services/device-platform.service";
import { AnalyticsService } from "@cariloop/analytics";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "login",
  templateUrl: "login.html",
  styleUrls: ["login.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("emailRef") emailRef: MatFormField;
  @ViewChild("passwordRef") passwordRef;
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;
  public model: Credential = new Credential();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public error: any;
  public email: string = this.route.snapshot.queryParams.email ?? "";
  public modalVisible = false;
  public forgotPassword = false;
  public modalError: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public message: any[] = [];
  public isLogged = false;
  public loading = true;
  public redirectTo: string;
  public loginCode: string;
  public showLoginCode = false;
  public disableLogin = false;
  public showSignInCodeField: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public loginCodeError: any;
  public loginWithUrl = "";
  public loginWithName = "";
  public alightUrl = "";
  public oneLoginUrl = "";
  public showRegister = false;
  public showMobileAdoptionCTA = false;
  public isMobileDevice = false;
  public device: DeviceType = "browser";
  public showMobileWebCTA = false;
  public isUrbanSitterRedirect: boolean =
    this.route.snapshot.queryParams.urbanSitter ?? false;
  public loginMessage = "";
  public mfaToken = this.route.snapshot.queryParams.mfaToken;
  public mfaDeviceId = this.storageService.getItem("mfaDeviceId") || "";
  public screen: "login" | "mfa" | "mfaError" = this.mfaToken ? "mfa" : "login";

  public webinarId: number;
  public enterpriseURL;
  public memberPortalURL;
  public chooseAdventure = false;
  public isLoading = false;
  public passwordComplexity = {
    capitalLetters: false,
    commonPasswords: false,
    length: 0,
    numbers: false,
    symbols: false,
  };
  private titleTranslationSubscription: Subscription;

  protected readonly mfaType = signal<MfaType>("none");

  constructor(
    private appService: AppState,
    private accountService: AccountService,
    private translate: AppTranslateService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private caseService: CaseService,
    private storageService: StorageService,
    private redirectService: RedirectService,
    private orgService: OrganziationService,
    private errorReportingService: ErrorReportingService,
    private urbanSitterService: UrbanSitterService,
    private provisioningService: ProvisioningService,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private devicePlatformService: DevicePlatformService,
    private changeDetectorRef: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private destroyRef: DestroyRef,
  ) {
    this.redirectService.init(route.snapshot);
    const isAdminUrl = window.location.hostname?.includes("admin.cariloop.com");
    if (isAdminUrl) {
      window.location.href = `${environment.AUTH_URL}/login?admin=1`;
    }
    this.loadLoginUrls();
  }

  async ngOnInit() {
    this.titleTranslationSubscription = this.translateService
      .stream("LOGIN.TITLE")
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pageTitle) => {
        this.titleService.setTitle(pageTitle + " | Cariloop");
      });
    this.isLogged = this.checkIfLogged();
    this.redirectTo = this.route.snapshot.queryParams["redirectUrl"];
    this.device = this.devicePlatformService.getDeviceType();
    this.isMobileDevice = this.devicePlatformService.isMobileDevice();

    const source = this.route.snapshot.queryParams.source;
    if (source === "new-password") {
      this.loginMessage = "MFA.PASSWORD_UPDATED";
    } else if (source === "code-expired") {
      const dialogRef = this.dialog.open(DialogComponent);

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    }
    if (this.route.snapshot.queryParams.redirectUrl?.includes("resources/")) {
      this.storageService.setItem(
        "contentRedirect",
        this.route.snapshot.queryParams.redirectUrl,
      );
    }
    this.provisioningService.getProfileGeneral().subscribe((profile) => {
      if (
        profile.newAccountRegistrationGeneralAccess?.value &&
        !this.isUrbanSitterRedirect
      ) {
        this.showRegister = true;
      }
      if (profile.mobileAdoptionCTA) {
        this.showMobileAdoptionCTA =
          profile.mobileAdoptionCTA.value && !this.isUrbanSitterRedirect;
        if (this.showMobileAdoptionCTA) {
          this.setMobileWebCTA();
        }
      }
    });
    this.webinarId = this.route.snapshot.queryParams["webinar"];

    if (this.email) {
      this.model.email = decodeURIComponent(this.email.toLowerCase());
    }

    if (this.isUrbanSitterRedirect) {
      this.analyticsService.trackEvent("urbansitter_login_started", {
        deviceType: this.device,
        email: this.email,
      });
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.formFields?.forEach((ff: MatFormField) => {
        ff.updateOutlineGap();
      });
    }, 500);
  }

  ngOnDestroy() {
    this.titleTranslationSubscription.unsubscribe();
  }

  @HostListener("window:beforeunload", ["$event"])
  userIsExit() {
    if (this.webinarId && !this.isLogged) {
      const eventParams = {
        deviceType: this.device,
        webinarId: this.webinarId,
      };
      this.analyticsService.trackEvent("webinar_abandoned_login", eventParams);
    }
  }

  animationEvent(e: AnimationEvent, inputName: string) {
    if (e.animationName === "autofill") {
      if (inputName === "email") {
        return (this.emailRef.floatLabel = "always");
      }
      return (this.passwordRef.floatLabel = "always");
    }
    if (e.animationName === "cdk-text-field-autofill-end") {
      if (inputName === "email") {
        return (this.emailRef.floatLabel = "never");
      }
      return (this.passwordRef.floatLabel = "never");
    }
  }

  resetFloatLabel(inputName: string) {
    if (inputName === "email") {
      this.emailRef.floatLabel = "auto";
    } else {
      this.passwordRef.floatLabel = "auto";
    }
  }

  loadLoginUrls(): void {
    const { envName, BASE_URL } = environment;
    if (envName === "production") {
      return;
    }
    const params = this.route.snapshot.queryParams;
    if (params.providerId) {
      this.orgService.getSSOConfig(params.providerId).subscribe(
        (ssoConfig) => {
          this.loginWithUrl = `${BASE_URL}login?provider=${ssoConfig.provider}`;
          this.loginWithName = ssoConfig.providerName;
        },
        (err) => this.errorHandler(err),
      );
    } else {
      if (envName !== "develop") {
        this.alightUrl = `${BASE_URL}login?provider=ALIGHT`;
      }
      this.oneLoginUrl = `${BASE_URL}login?provider=ONE_LOGIN`;
    }
  }

  private handleLoginResponse(account: Account | Error): void {
    if (account instanceof Error) {
      this.error = account;
      this.disableLogin = true;
      return;
    }

    const isInternal = this.accountService.getRoles().some((role) => {
      return (
        role === "$coach_admin" ||
        role === "$coach" ||
        role === "$coach_assistant" ||
        role === "$engagement" ||
        role === "$analytics" ||
        role === "$cms" ||
        role === "$coach_intake"
      );
    });
    this.setLanguage(account);
    this.setCrashContext(account);
    if (
      account.forcePasswordUpdate ||
      (!account.termsAcceptanceDate && !isInternal)
    ) {
      if (isInternal) {
        this.router.navigate(["/new-password"], {
          queryParams: { redirectUrl: this.redirectTo },
        });
      } else if (account.loginCode) {
        this.router.navigate(["/reset", account.loginCode], {
          queryParams: { redirectUrl: this.redirectTo },
        });
      } else {
        this.getLastVisit(account);
      }
    } else if (isInternal) {
      this.checkPasswordExpiration(account);
    } else {
      if (account.missingSteps) {
        this.router.navigate(["/register/complete"], {
          queryParamsHandling: "preserve",
        });
        return;
      }
      this.getLastVisit(account);
    }
  }

  #handleLoginHttpErrorResponse(errorResponse: HttpErrorResponse) {
    const capiError: CapiError = errorResponse.error;
    const {
      error: { code, name },
    } = capiError;

    const hasMfaCode = ["MFA_CODE_SENT_SMS", "MFA_CODE_SENT_EMAIL"].includes(
      code,
    );
    if (!hasMfaCode) {
      return false;
    }

    if (code === "MFA_CODE_SENT_SMS") {
      this.mfaType.set("sms");
    }
    if (code === "MFA_CODE_SENT_EMAIL") {
      this.mfaType.set("email");
    }

    this.mfaToken = name;
    this.screen = "mfa";

    return hasMfaCode;
  }

  public onSubmit(): void {
    this.isLoading = true;
    this.error = "";
    this.model.email = this.model.email?.trim() ?? "";
    this.accountService
      .login({
        ...this.model,
        deviceId: this.mfaDeviceId,
      })
      .subscribe(
        (res) => {
          this.isLogged = true;
          this.handleLoginResponse(res);
          this.isLoading = false;
        },
        (errorResponse) => {
          this.isLoading = false;
          this.error = null;

          if (errorResponse instanceof HttpErrorResponse) {
            const hasMfaCode =
              this.#handleLoginHttpErrorResponse(errorResponse);
            if (hasMfaCode) {
              return;
            }
          }

          this.error = errorResponse;
          if (
            errorResponse.status &&
            (errorResponse.status === 401 ||
              errorResponse.status === 441 ||
              errorResponse.status === 461 ||
              errorResponse.status === 422)
          ) {
            return;
          }
          this.errorHandler(errorResponse);
        },
      );
  }

  public checkPasswordExpiration(account: Account): void {
    if (!account.passwordDate) {
      return this.updatePasswordDate(account);
    }
    const difference = this.calculateDateDayDiff(
      account.passwordDate.toString(),
    );
    if (difference > 90) {
      this.router.navigate(["/new-password"], {
        queryParams: { redirectUrl: this.redirectTo },
      });
    } else {
      this.getLastVisit(account);
    }
  }

  private calculateDateDayDiff(date: string): number {
    const currentDate = new Date(date);
    const oldDate = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const newDate = new Date();
    const current = Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
    );
    return Math.floor((current - oldDate) / _MS_PER_DAY);
  }

  public updatePasswordDate(account: Account): void {
    const data = {
      passwordDate: new Date(),
    };
    this.accountService.updateAttributes(account.id, data).subscribe(
      (res) => {
        this.accountService.setAccountToken(res);
        this.getLastVisit(account);
      },
      (err) => {
        console.log("Error: ", err);
      },
    );
  }

  public lowercaseEmail() {
    if (this.model.email) {
      this.model.email = this.model.email.toLowerCase();
    }
  }

  public getLastVisit(account: Account) {
    this.accountService.getAccountOrg(account.id).subscribe((org) => {
      if (org?.contractStartDate) {
        const roles = this.accountService.getRoles() ?? [];
        const contractStartDate = new Date(org.contractStartDate as string),
          today = new Date();

        contractStartDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);

        if (
          contractStartDate > today &&
          roles.length == 1 &&
          roles.includes("$user")
        ) {
          this.analyticsService.trackEvent(
            "inactive_employer_redirected_from_login",
            {
              accountId: account.id,
              deviceType: this.devicePlatformService.getPlatformForMixpanel(),
            },
          );
          this.router.navigate(["/register/not-ready"]);
          return;
        }
      }

      if (account.shouldCreateCase) {
        return this.caseService.getCreateCaseUrl().subscribe((url) => {
          window.location.href = url;
        });
      }

      // If redirect to urban sitter
      if (
        this.isUrbanSitterRedirect &&
        !account.benefitId &&
        (account.country === "US" || !account.country)
      ) {
        return this.handleUrbanSitterSSO(account);
      }

      // If redirect to webinar
      if (this.webinarId) {
        const eventParams = {
          webinarId: this.webinarId,
          accountId: account.id,
          deviceType: this.device,
        };

        this.analyticsService.trackEvent(
          "webinar-login-successful",
          eventParams,
        );
        return this.router.navigate(["/webinar", this.webinarId]);
      }

      // If coming from OAuth
      if (this.redirectService.isOauth) {
        return this.redirectService.onLogin().subscribe(
          () => {},
          (err) => {
            this.error = err;
            this.disableLogin = true;
          },
        );
      }
      const comeFromAdmin = this.route.snapshot.queryParams["admin"];
      return this.redirectService
        .getUserRedirect({
          redirect: this.redirectTo,
          fromLogin: true,
          comeFromAdmin,
        })
        .subscribe((url) => {
          window.location.href = url;
        });
    });
  }

  handleUrbanSitterSSO(account) {
    this.accountService.getAccountOrg(account.id).subscribe((org) => {
      this.isLoading = true;
      if (!org.hasUrbanSitter) {
        this.route.snapshot.queryParams.urbanSitter = false;
        this.isUrbanSitterRedirect = false;
        this.isLoading = false;
        return this.getLastVisit(account);
      }
      this.provisioningService.getProvisioningByOrg(org.id).subscribe((res) => {
        if (res.urbanSitterMemberSSO?.value) {
          this.analyticsService.trackEvent("urbansitter_login_completed", {
            deviceType: this.device,
            accountId: account.id,
          });

          this.ngZone.runOutsideAngular(() => {
            this.urbanSitterService
              .connectAccount()
              .subscribe(({ token, refreshToken }) => {
                const urbanSitterForm =
                  this.urbanSitterService.createUrbanSitterForm(
                    token,
                    refreshToken,
                  );
                urbanSitterForm.submit();
                this.isLoading = false;
              });
          });
        } else {
          this.route.snapshot.queryParams.urbanSitter = false;
          this.isUrbanSitterRedirect = false;
          this.isLoading = false;
          this.getLastVisit(account);
        }
      });
    });
  }

  public setCrashContext(account): void {
    if (environment.envName !== "local") {
      Sentry.setUser({
        id: account.id,
        isAnonymous: false,
        organizationId: account.organizationId,
      });
    }
  }

  public loginWithCode(loginWithCode): void {
    this.accountService.getAccountByLoginCode(this.loginCode).subscribe(
      (res) => {
        if (res && res.id) {
          this.setLanguage(res);
          this.router.navigate(["/reset", this.loginCode], {
            queryParams: { code: true },
          });
        } else {
          this.loginCodeError = true;
          loginWithCode.form.controls["loginCode"].setErrors({
            incorrect: true,
          });
        }
      },
      (err) => console.log(err),
    );
  }

  private setLanguage(account: Account): void {
    const languageInMemory = this.accountService.getLocalLanguage();
    if (languageInMemory && languageInMemory.value) {
      const data = new Account();
      data.language = languageInMemory.value;
      this.accountService.updateAttributes(account.id, data).subscribe(
        (res) => {
          this.accountService.removeLocalLanguage();
          this.accountService.setAccountToken(res);
        },
        (err) => this.errorHandler(err),
      );
    } else if (account && account.language) {
      this.translate.updateLang(account.language);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private errorHandler(error, custom?: any): void {
    this.appService.status(error);
    if (error.status !== 451) {
      this.errorReportingService.send(error, custom);
    }
  }

  private checkIfLogged() {
    return !(this.accountService.getLogged() instanceof Error);
  }

  validateEmail(loginForm) {
    if (this.model.email) {
      if (!isValidEmail(this.model.email)) {
        loginForm.form.controls["email"].setErrors({ pattern: true });
      } else {
        loginForm.form.controls["email"].setErrors(null);
      }
    }
  }

  openSignInCodeField() {
    this.showSignInCodeField = true;
  }
  throwError() {
    const text = prompt("Error name?");
    if (!text) {
      return;
    }
    throw new Error(text);
  }

  setMobileWebCTA() {
    if (!this.storageService.getSessionItem("isMobileWebCTASeen")) {
      this.showMobileWebCTA = this.isMobileDevice;

      if (this.isMobileDevice) {
        this.storageService.setSessionItem("isMobileWebCTASeen", true);
      }
    }
  }

  changeMobileCTAState(state: boolean) {
    this.showMobileWebCTA = state;
    this.changeDetectorRef.detectChanges();
  }
}
