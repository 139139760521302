import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";

@Injectable({
  providedIn: "root",
})
export class ErrorReportingService {
  private preventSendErrors = true;
  private errorBlacklist: Map<number, number> = new Map()
    .set(0, 0)
    .set(401, 401)
    .set(431, 431)
    .set(461, 461);
  private allowUnhandledErrors = true;
  constructor() {}

  public allowErrors(): void {
    this.preventSendErrors = false;
  }

  public preventErrors(): void {
    this.preventSendErrors = true;
  }

  public send(_error: Error | HttpErrorResponse, customData?: any): void {
    const errorCode =
      _error instanceof HttpErrorResponse ? _error.status : null;
    if (this.preventSendErrors && this.errorBlacklist.has(errorCode)) {
      return console.log(`${errorCode} prevented.`);
    }
    const error = this.formatError(_error);
    if (
      error.message?.toLowerCase()?.includes("object not found matching id")
    ) {
      if (!this.allowUnhandledErrors) {
        return;
      }
      this.allowUnhandledErrors = false;
    }
    if (customData) {
      Sentry.captureException(error, customData);
      return;
    }
    Sentry.captureException(error);
  }

  private formatError(httpError: HttpErrorResponse | Error): Error {
    const error = new Error();
    if (!httpError) {
      error.name = "Error";
      error.message = "There was an error processing your request";
    } else if (httpError instanceof Error) {
      error.name = httpError.name;
      error.message = httpError.message;
    } else if (httpError.error?.error) {
      const { message, stack } = httpError.error.error;
      error.name = message;
      error.message = stack;
    } else {
      const { message, statusText } = httpError;
      error.name = statusText;
      error.message = message;
    }
    return error;
  }
}
