<mat-dialog-content class="mat-typography">
  <h5>{{ 'MFA.LOGIN_CODE_EXPIRED_TITLE' | translate }}</h5>
  <p>
    {{ 'MFA.LOGIN_CODE_EXPIRED' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>{{ 'MFA.CANCEL' | translate }}</button>
  <button mat-button routerLink="/forgot-password" color="accent" [mat-dialog-close]="true" cdkFocusInitial>{{ 'MFA.CONTINUE' | translate }}</button>
</mat-dialog-actions>
