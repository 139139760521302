<div class="header" *ngIf="!isAdventureView">
  <!-- <a
    href="https://www.cariloop.com"
    [attr.aria-label]="'TOP_NAV.HOME_PAGE_ARIA' | translate">
    <img src="assets/img/white-logo.png" [attr.alt]="'TOP_NAV.LOGO_ALT' | translate">
  </a> -->
  <div *ngIf="!isLogged" class="user-actions">
    <lib-lang-dropdown
      [itemSelected]="browserLanguage ? browserLanguage : {value: 'en', label: 'English'}"
      (itemSelectedChange)="changeLang($event.value)"
      [ariaLabel]="'TOP_NAV.LANGUAGE_DROPDOWN_ARIA' | translate"
    ></lib-lang-dropdown>

    <!-- <span
      *ngFor="let lang of availableLangs"
      (click)="changeLang(lang.value)"
      class="nav-item mat-button">
      {{lang.label}}
    </span> -->
  </div>
</div>

<div *ngIf="isAdventureView" class="full-top-nav">
  <img src="assets/img/cariloop-logo.svg" alt="" />
  <span class="flex-separator"></span>
  <lib-lang-dropdown
    [ariaLabel]="'TOP_NAV.LANGUAGE_DROPDOWN_ARIA' | translate"
    [itemSelected]="browserLanguage ? browserLanguage : {value: 'en', label: 'England'}"
    (itemSelectedChange)="changeLang($event.value)"
  ></lib-lang-dropdown>
</div>
