import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NonNullableFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsService } from "@cariloop/analytics";
import {
  CInputTextComponent,
  emailPattern,
  zipCodeRegexUS,
  zipCodeRegexCA,
} from "@cariloop/ui-components";
import { AccountService } from "app/shared/services/account.service";
import {
  DevicePlatformService,
  MixPanelPlatform,
} from "app/shared/services/device-platform.service";
import { HandledObservableError } from "app/shared/types/handled-observable-error.type";
import type { RegisteredEmail } from "app/shared/types/register.types";
import { EMPTY, Observable } from "rxjs";
import { catchError, startWith, tap } from "rxjs/operators";

type EligibilityCheckMethod = "workEmail" | "personalInformation";

type WorkEmailGroup = FormGroup<{
  workEmail: FormControl<string>;
}>;

type PersonalEmailGroup = FormGroup<PersonalEmailFormGroup>;

interface PersonalEmailFormGroup {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  zipCode: FormControl<string>;
  dateOfBirth: FormControl<string>;
}

type EligibilityCheckFormGroup = FormGroup<{
  method: FormControl<EligibilityCheckMethod>;
  workEmailGroup: WorkEmailGroup;
  personalEmailGroup: PersonalEmailGroup;
}>;

type EligibilityCheckForm = {
  method: EligibilityCheckMethod;
  workEmailGroup: WorkEmailGroup;
  personalEmailGroup: PersonalEmailGroup;
};

const MULTIPLE_ELIGIBLE_ACCOUNTS_ERROR_STATUS = 422;
const NO_ELIGIBLE_ACCOUNTS_ERROR_STATUS = 404;

type State = {
  hasEligibilityError: boolean;
  hasServerSideError: boolean;
  isSubmitting: boolean;
};

const INITIAL_STATE: State = {
  hasEligibilityError: false,
  hasServerSideError: false,
  isSubmitting: false,
};

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  // We need this to change inner material styles and follow the design.
  encapsulation: ViewEncapsulation.None,
  selector: "app-eligibility-check",
  templateUrl: "./eligibility-check.component.html",
  styleUrls: ["./eligibility-check.component.scss"],
})
export class EligibilityCheckComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input({ required: true })
  registeredEmail: null | RegisteredEmail;
  @Input({ required: true }) firstName: string;
  @Input({ required: true }) lastName: string;
  @ViewChild(FormGroupDirective) formRef: FormGroupDirective;
  @ViewChildren("zipCodeInput") zipCode: QueryList<CInputTextComponent>;

  @Output()
  formSubmitted = new EventEmitter<void>();

  today = new Date();
  isDOBTouched = false;
  errorValidationBanner: boolean = false;
  eligibilityCheckForm: EligibilityCheckFormGroup;
  selectedMethod$: Observable<EligibilityCheckMethod>;
  accountName: { firstName: string; lastName: string };
  zipCodeElement: HTMLElement;
  datePickerElement: HTMLElement;
  elementsWithListeners: HTMLElement[] = [];
  personalInfoCheckFailed: boolean = false;
  workEmailCheckFailed: boolean = false;
  platform: MixPanelPlatform;

  state = signal<State>(INITIAL_STATE);

  constructor(
    private readonly formBuilder: NonNullableFormBuilder,
    private readonly accountService: AccountService,
    private readonly analyticsService: AnalyticsService,
    private readonly devicePlatformService: DevicePlatformService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  ngOnInit() {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      throw accountResult;
    }

    const { firstName, lastName, id } = accountResult;

    this.accountName = { firstName, lastName };
    this.eligibilityCheckForm = this.formBuilder.group<EligibilityCheckForm>({
      method: "workEmail",
      workEmailGroup: this.formBuilder.group({
        workEmail: [
          this.registeredEmail?.type === "work"
            ? this.registeredEmail.address
            : "",
          [Validators.pattern(emailPattern), Validators.required],
        ],
      }),
      personalEmailGroup: this.formBuilder.group({
        firstName: [firstName as string, Validators.required],
        lastName: [lastName as string, Validators.required],
        zipCode: ["", [Validators.required, this.zipCodePatternValidator()]],
        dateOfBirth: ["", [Validators.required]],
      }),
    });

    this.selectedMethod$ = this.eligibilityCheckForm
      .get("method")
      .valueChanges.pipe(
        startWith(this.eligibilityCheckForm.get("method").value),
      );

    this.platform = this.devicePlatformService.getPlatformForMixpanel();

    const isReturning = this.route.snapshot.routeConfig.path === "complete";
    this.analyticsService.trackEvent("eligibility-check", {
      platform: this.platform,
      source: "auth",
      accountId: id,
      ...(isReturning && { userFlow: "update-existing-account" }),
      ...(!isReturning && { userFlow: "create-new-account" }),
    });
  }

  ngAfterViewInit(): void {
    this.zipCode.changes.subscribe(() => {
      this.zipCodeElement = document
        .querySelector("#zipCodeInput")
        .querySelector("input");

      const verificationMethod = this.eligibilityCheckForm.get("method").value;

      if (verificationMethod === "personalInformation") {
        this.zipCodeElement.addEventListener("blur", () => {
          const zipCodeControl = (
            this.eligibilityCheckForm.get(
              "personalEmailGroup",
            ) as FormGroup<PersonalEmailFormGroup>
          ).controls["zipCode"];

          zipCodeControl.updateValueAndValidity();
        });
        this.elementsWithListeners.push(this.zipCodeElement);
      }

      this.datePickerElement = document
        .querySelector(".date-picker")
        ?.querySelector("input");
      if (this.datePickerElement) {
        this.datePickerElement.addEventListener("blur", () => {
          this.isDOBTouched = true;
        });
        this.elementsWithListeners.push(this.datePickerElement);
      }
    });
  }

  ngOnDestroy(): void {
    this.elementsWithListeners.forEach((element) => {
      element.removeAllListeners("blur");
    });
  }

  handleSubmit() {
    const accountResult = this.accountService.getLogged();
    if (accountResult instanceof Error) {
      this.router.navigate(["/"], { queryParamsHandling: "preserve" });
      return;
    }

    const { id } = accountResult;

    this.state.set(INITIAL_STATE);

    const selectedMethod = this.eligibilityCheckForm.get("method").value;

    this.eligibilityCheckForm.removeControl(
      selectedMethod === "workEmail" ? "personalEmailGroup" : "workEmailGroup",
    );

    // This is needed because `c-input-text`s only shows their inner `mat-error` if the input is `touched`
    this.eligibilityCheckForm.markAllAsTouched();
    this.eligibilityCheckForm.updateValueAndValidity();

    if (!this.eligibilityCheckForm.valid) {
      this.resetFormControls();
      return;
    }

    this.state.update((currentState) => ({
      ...currentState,
      isSubmitting: true,
    }));

    if (selectedMethod === "personalInformation") {
      this.eligibilityCheckForm.get("personalEmailGroup").disable();
    }

    const eligibilityCheckData =
      selectedMethod === "workEmail"
        ? {
            kind: selectedMethod,
            workEmail: this.eligibilityCheckForm
              .get("workEmailGroup")
              .get("workEmail").value,
          }
        : {
            kind: selectedMethod,
            firstName: this.eligibilityCheckForm.get(
              "personalEmailGroup.firstName",
            ).value,
            lastName: this.eligibilityCheckForm.get(
              "personalEmailGroup.lastName",
            ).value,
            zipCode: this.normalizeZipCode(
              this.eligibilityCheckForm.get("personalEmailGroup.zipCode").value,
            ),
            birthDate: new Date(
              this.eligibilityCheckForm.get(
                "personalEmailGroup.dateOfBirth",
              ).value,
            ).toLocaleDateString("en-US"),
          };

    this.accountService
      .eligibilityCheck(eligibilityCheckData)
      .pipe(
        tap(() => {
          const isReturning =
            this.route.snapshot.routeConfig.path === "complete";
          this.analyticsService.trackEvent("eligibility-check-complete", {
            platform: this.platform,
            source: "auth",
            accountId: id,
            ...(isReturning && { userFlow: "update-existing-account" }),
            ...(!isReturning && { userFlow: "create-new-account" }),
          });
        }),
        catchError(({ status }: HandledObservableError) => {
          this.resetFormControls();
          if (selectedMethod === "personalInformation") {
            this.eligibilityCheckForm.get("personalEmailGroup").enable();
          } else {
            this.resetForm();
          }

          if (
            status === MULTIPLE_ELIGIBLE_ACCOUNTS_ERROR_STATUS ||
            status === NO_ELIGIBLE_ACCOUNTS_ERROR_STATUS
          ) {
            this.state.update((currentState) => ({
              ...currentState,
              isSubmitting: false,
              hasEligibilityError: true,
            }));

            if (selectedMethod === "personalInformation") {
              this.personalInfoCheckFailed = true;
            }
            if (selectedMethod === "workEmail") {
              this.workEmailCheckFailed = true;
            }

            return EMPTY;
          }

          this.state.update((currentState) => ({
            ...currentState,
            isSubmitting: false,
            hasServerSideError: true,
          }));

          return EMPTY;
        }),
      )
      .subscribe({
        next: () => {
          this.state.update((currentState) => ({
            ...currentState,
            isSubmitting: false,
          }));

          if (selectedMethod === "personalInformation") {
            this.eligibilityCheckForm.get("personalEmailGroup").enable();
          }

          this.formSubmitted.emit();
        },
      });
  }

  private zipCodePatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (this.zipCodeElement === document.activeElement) {
        return null;
      }

      return !zipCodeRegexUS.test(value) && !zipCodeRegexCA.test(value)
        ? { pattern: true }
        : null;
    };
  }

  private resetForm() {
    this.isDOBTouched = false;
    this.formRef.resetForm();
  }

  private resetFormControls() {
    this.eligibilityCheckForm.addControl(
      "personalEmailGroup",
      this.formBuilder.group({
        firstName: [this.accountName.firstName as string, Validators.required],
        lastName: [this.accountName.lastName as string, Validators.required],
        zipCode: ["", [Validators.required, this.zipCodePatternValidator()]],
        dateOfBirth: ["", [Validators.required]],
      }),
    );
    this.eligibilityCheckForm.addControl(
      "workEmailGroup",
      this.formBuilder.group({
        workEmail: [
          this.registeredEmail?.type === "work"
            ? this.registeredEmail.address
            : "",
          [Validators.pattern(emailPattern), Validators.required],
        ],
      }),
    );
  }

  selectEligibilityCheckMethod(
    event: MouseEvent | KeyboardEvent,
    selectedMethod: EligibilityCheckMethod,
  ) {
    this.eligibilityCheckForm.get("method").setValue(selectedMethod);
    (event.target as HTMLElement).blur();
  }

  private normalizeZipCode(zipCode: string): string {
    const isCanadianZipCode = zipCodeRegexCA.test(zipCode);
    if (isCanadianZipCode) {
      return zipCode.replace(/\s/g, "").toUpperCase();
    }
    return zipCode;
  }
}
