import { Injectable } from "@angular/core";
import { STORAGE_PREFIX } from "../constants/storage-prefix.constant";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  storageIsAvalaible = false;
  isSessionStorageAvailable = false;

  constructor() {
    this.storageIsAvalaible = localStorage && typeof Storage !== "undefined";
    this.isSessionStorageAvailable =
      sessionStorage && typeof Storage !== "undefined";
  }

  getKey(key: string) {
    return `${STORAGE_PREFIX}:${key}`;
  }

  getItem<T = ReturnType<typeof JSON.parse>>(key: string): null | T | string {
    if (!this.storageIsAvalaible) {
      return null;
    }
    try {
      return JSON.parse(localStorage.getItem(this.getKey(key))) as T;
    } catch (e) {
      return localStorage.getItem(this.getKey(key));
    }
  }

  setItem(key: string, value: string | object | number) {
    if (!this.storageIsAvalaible) {
      return null;
    }
    const parsedValue =
      typeof value === "string" ? value : JSON.stringify(value);
    try {
      return localStorage.setItem(this.getKey(key), parsedValue);
    } catch (e) {
      console.error("Error! private mode and can't use local storage!!", e);
    }
    return;
  }

  removeItem(key: string) {
    if (!this.storageIsAvalaible) {
      return null;
    }
    return localStorage.removeItem(this.getKey(key));
  }

  getSessionItem<T = ReturnType<typeof JSON.parse>>(
    key: string,
  ): null | T | string {
    if (!this.isSessionStorageAvailable) {
      return null;
    }
    try {
      return JSON.parse(sessionStorage.getItem(this.getKey(key))) as T;
    } catch (e) {
      return sessionStorage.getItem(this.getKey(key));
    }
  }

  setSessionItem(key: string, value: string | object | number | boolean) {
    if (!this.isSessionStorageAvailable) {
      return null;
    }
    const parsedValue =
      typeof value === "string" ? value : JSON.stringify(value);
    try {
      return sessionStorage.setItem(this.getKey(key), parsedValue);
    } catch (e) {
      console.error(e);
    }
    return;
  }
}
