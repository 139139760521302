import { inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganziationService } from 'app/shared/services/organization.service';
import { ProvisioningService } from 'app/shared/services/provisioning.service';
import { environment } from 'environments/environment';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

const INITIAL_REDEEM_LAUNCH_DATE = new Date('01/01/2024');

function getDefaultGiftCariloopProvisioningDerivedValues() {
  return {
    isEnabled: false,
    isNowBeforeLaunchDate: new Date() < INITIAL_REDEEM_LAUNCH_DATE,
  };
}

@Injectable({
  providedIn: 'root',
})
export class GiftLandingPageService {
  readonly #provisioningService = inject(ProvisioningService);
  readonly #organizationService = inject(OrganziationService);
  readonly #route = inject(ActivatedRoute);

  readonly #publicProvisioningProfiles$ =
    this.#provisioningService.getPublicProvisioningProfiles();

  readonly #giftCariloopProvisioningDerivedValues$ =
    this.#publicProvisioningProfiles$.pipe(
      map(({ giftCariloop }) => {
        if (giftCariloop === undefined) {
          return getDefaultGiftCariloopProvisioningDerivedValues();
        }

        if (giftCariloop.value.enabled) {
          const {
            value: { redeemFlowDate },
          } = giftCariloop;

          const launchDate = new Date(redeemFlowDate);

          return {
            isEnabled: true,
            isNowBeforeLaunchDate: new Date() < launchDate,
          };
        }

        return getDefaultGiftCariloopProvisioningDerivedValues();
      }),
    );

  readonly isNowBeforeLaunchDate$ =
    this.#giftCariloopProvisioningDerivedValues$.pipe(
      map(({ isNowBeforeLaunchDate }) => isNowBeforeLaunchDate),
    );

  readonly #giftOrgId$ = this.#giftCariloopProvisioningDerivedValues$.pipe(
    filter(
      ({ isEnabled, isNowBeforeLaunchDate }) =>
        isEnabled && !isNowBeforeLaunchDate,
    ),
    switchMap(() => this.#organizationService.getgiftOrgId()),
  );

  readonly redeemGiftSubject = new Subject<void>();
  readonly #redeemGiftAction$ = this.redeemGiftSubject.asObservable();

  readonly #voucherCode = this.#route.snapshot.queryParamMap.get('voucherCode');

  readonly redeemGiftRedirect$ = combineLatest([
    this.#giftOrgId$,
    this.#redeemGiftAction$,
  ]).pipe(
    tap(([giftOrgId]) => {
      if (this.#voucherCode === null) {
        return;
      }

      window.location.href = `${environment.AUTH_URL}/onboarding/case-creation/${giftOrgId}/account/new?employer=false&voucherCode=${this.#voucherCode}`;
    }),
  );
}
