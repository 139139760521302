/**
 * Account Type
 */

export class Account {
  public id: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public emailType?: EmailType;
  public email2?: string;
  public email2Type?: EmailType;
  public emailVerifiedDate?: Date;
  public birthDate?: string;
  public orgEmail?: string;
  public forcePasswordUpdate?: boolean;
  public loginCode?: string;
  public state: string;
  public password?: string;
  public organizationId: string;
  public phone: string;
  public photo: any;
  public deletedAt: string;
  public passwordDate: string;
  public invited: boolean;
  public language: string;
  country: string;
  public termsAcceptanceDate?: string;
  public timeZone: string;
  public zipCode: string;
  public siteMessages?: any;
  public roles?: any[];
  public provisioningProfile?: any;
  public orgHistory?: [];
  public memberId?: string | null;
  public benefitId?: string;
  public enterpriseProfile?: {
    title?: string;
    workEmail?: string;
    company?: string;
    workPhoneNumber?: string;
  };
  public missingSteps?: boolean;
  public source?: string;
  public shouldCreateCase?: boolean;
  public TOSLastModifiedDate?: string;
}

export type EmailType = "personal" | "work";
