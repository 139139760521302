import { inject } from "@angular/core";
import { type CanActivateFn, Router } from "@angular/router";
import { AccountService } from "app/shared/services/account.service";

export const canRegisterGuard: CanActivateFn = (route) => {
  const accountService = inject(AccountService);
  const accountResult = accountService.getLogged();
  if (accountResult instanceof Error) {
    return true;
  }

  if (route.queryParamMap.get("wasCreatedFromCoachAddCase") === "true") {
    return true;
  }

  const loginSource: string = JSON.parse(localStorage.getItem("login-source"));
  if (loginSource === "register") {
    return true;
  }

  const router = inject(Router);
  router.navigate(["/register/complete"], {
    queryParams: route.queryParams,
  });
  return false;
};
