import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class AppTranslateService {
  private translationSubject = new Subject<string>();

  constructor() { }

  onLangChange(): Observable<string> {
    return this.translationSubject.asObservable();
  }

  updateLang(lang: string): void {
    this.translationSubject.next(lang);
  }
}
