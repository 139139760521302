<section class="registration-header" [ngClass]="{'registration-header--hide-step-counter': hideStepCounter, 'registration-header--webview': isMobileWebView}">
    <app-registration-header-logo *ngIf="!isMobileWebView" class="registration-header__logo"/>
    <lib-lang-dropdown
    *ngIf="!isMobileWebView"
    class="registration-header__language"
    (itemSelectedChange)="changeLanguage($event.value)"
    [itemSelected]="
        browserLanguage ? browserLanguage : { value: 'en', label: 'English' }
        "
    />
    <app-registration-step-counter
        *ngIf="!hideStepCounter"
        class="registration-header__step-counter"
        [currentStep]="currentStep"
        [totalSteps]="totalSteps"
    />
</section>