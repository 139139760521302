import { Injectable } from "@angular/core";
import { AppState } from "app/app.service";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountService } from "app/shared/services/account.service";
import { AppStateType } from "app/shared/types/app-state-type";
import { environment } from "environments/environment";
import { of } from "rxjs/";
import { catchError, map } from "rxjs/operators";
import { AccountRegistrationService } from "../services/account-registration.service";

@Injectable({
  providedIn: "root",
})
export class MissingRegistrationStepsGuard {
  constructor(
    private accountService: AccountService,
    private accountRegistrationService: AccountRegistrationService,
    private appService: AppState,
    private router: Router,
  ) {}

  canActivate(next: ActivatedRouteSnapshot) {
    const appState: AppStateType = {
      authenticated: false,
      path: {
        root: "missingStepsGuard",
      },
    };
    this.appService.appStateUpdate(appState);

    const isLogged = this.checkIfLogged();

    if (isLogged) {
      const logged = this.accountService.getLogged();
      if (logged instanceof Error) {
        throw logged;
      }

      return this.accountRegistrationService.missingSteps(logged.id).pipe(
        map((res) => {
          if (res && res.length > 0) {
            const loginSource = this.getParsedLoginSource();

            if (loginSource && loginSource === "register") {
              this.router.navigate(["/register"], {
                queryParams: next.queryParams,
              });
              return false;
            }
            return true;
          }
        }),
        catchError(() => {
          window.location.href = `${environment.AUTH_URL}/login`;
          return of(false);
        }),
      );
    }

    window.location.href = `${environment.AUTH_URL}/login`;
    return false;
  }

  private checkIfLogged() {
    return !(this.accountService.getLogged() instanceof Error);
  }

  private getParsedLoginSource() {
    try {
      return JSON.parse(localStorage.getItem("login-source"));
    } catch (error) {
      return null;
    }
  }
}
