import { Component, OnInit } from '@angular/core';
import { AccountService } from '../shared/services/account.service';
import { Router } from '@angular/router';

/**
 * @Component LogoutComponent
 */
@Component({
    selector: 'logout',
    styles: [],
    template: '<div></div>'
})
export class LogoutComponent implements OnInit {
    constructor(private accountService: AccountService,
                private router: Router) {}

    public ngOnInit(): void {
        this.accountService.logout().subscribe((res) => {
          this.router.navigate(['/login']);
        }, (err) => this.errorHandler(err));
    }

    private errorHandler(err) {
        // if (err.status === 500 || err.status === 401) {
        this.accountService.cleanLocalStorage();
        this.accountService.unSetOwneraccount();
        this.router.navigate(['/login']);
        // }
        console.log(err);
    }
}
