<form
  [formGroup]="creationForm"
  (ngSubmit)="onSubmit()"
  class="account-creation"
>
  <c-banner
    *ngIf="isSSO"
    [style]="'success'"
    iconPositionX="top"
    [message]="'REGISTER.CREATE_PASSWORD_BANNER' | translate"
    [showCloseButton]="false"
  ></c-banner>
  <h2 *ngIf="!isSSO">{{ "REGISTER.CREATE_ACCOUNT" | translate }}</h2>
  <div *ngIf="isSSO" class="create-password">
    <h2>{{ "REGISTER.CREATE_PASSWORD" | translate }}</h2>
    <p class="password-paragraph">
      {{ "REGISTER.PASSWORD_PARAGRAPH" | translate }}
    </p>
  </div>

  <div class="flex-form-fields">
    <c-banner
      *ngIf="errorState().isErrorActive"
      [style]="'error'"
      [inline]="false"
      [showCloseButton]="false"
    >
      <div class="banner-error-message">
        {{ errorState().errorMessage | translate }}
        <span
          *ngIf="
            errorState().errorMessage ===
            'REGISTER.GENERIC_FORM.ERRORS.GENERIC_SERVER_ERROR'
          "
          class="banner-error-message-email-link"
        >
          <a
            href="mailto:support@cariloop.com"
            title="Contact Cariloop Support at support@cariloop.com"
          >
            support&#64;cariloop.com
          </a>
          .
        </span>
      </div>
    </c-banner>

    <div class="full-name">
      <div class="input__container">
        <c-input-text
          class="name-input"
          name="firstName"
          type="text"
          required
          [label]="'REGISTER.GENERIC_FORM.FIRST_NAME' | translate"
          formControlName="firstName"
          inputID="firstNameInput"
          autocomplete="name"
        >
        </c-input-text>
        <div role="alert">
          <span
            class="input__error-text"
            *ngIf="
              creationForm.get('firstName').touched &&
              !creationForm.get('firstName').valid
            "
          >
            <p *ngIf="creationForm.get('firstName').errors">
              {{ "REGISTER.GENERIC_FORM.ERRORS.FIRST_NAME" | translate }}
            </p>
          </span>
        </div>
      </div>

      <div class="input__container">
        <c-input-text
          class="name-input"
          name="lastName"
          type="text"
          required
          [label]="'REGISTER.GENERIC_FORM.LAST_NAME' | translate"
          formControlName="lastName"
          inputID="lastNameInput"
          autocomplete="name"
        >
        </c-input-text>
        <div role="alert">
          <span
            class="input__error-text"
            *ngIf="
              creationForm.get('lastName').touched &&
              !creationForm.get('lastName').valid
            "
          >
            <p *ngIf="creationForm.get('lastName').errors">
              {{ "REGISTER.GENERIC_FORM.ERRORS.LAST_NAME" | translate }}
            </p>
          </span>
        </div>
      </div>
    </div>

    <div class="email-group">
      <div class="email-input">
        <label for="email" class="input-label">
          <span>{{ "REGISTER.GENERIC_FORM.EMAIL.ADDRESS" | translate }}</span>
          <mat-icon
            [ds-tooltip]="emailTooltip.el"
            fontSet="material-symbols-outlined"
            class="info-icon"
          >
            info
            <c-tooltip
              #emailTooltip
              [tooltipText]="'REGISTER.GENERIC_FORM.EMAIL.TOOLTIP' | translate"
              [tooltipDirection]="'bottom'"
              [tooltipArrowPosition]="'middle'"
            ></c-tooltip>
          </mat-icon>
        </label>
        <c-input-text
          #emailInput
          inputID="email"
          name="email"
          type="email"
          formControlName="email"
          required
          autocomplete="email"
        >
        </c-input-text>
      </div>
      <div role="alert">
        <span
          class="input__error-text"
          *ngIf="
            creationForm.get('email').touched &&
            !creationForm.get('email').valid
          "
        >
          <p *ngIf="creationForm.get('email').errors?.required">
            {{ "REGISTER.GENERIC_FORM.ERRORS.EMAIL.MISSING" | translate }}
          </p>
          <p *ngIf="creationForm.get('email').errors?.pattern">
            {{ "REGISTER.GENERIC_FORM.ERRORS.EMAIL.INVALID" | translate }}
          </p>
        </span>
      </div>
      <div
        class="email-type"
        *ngIf="
          creationForm.get('email').touched &&
          isValidEmail(creationForm.get('email').value)
        "
      >
        <div class="container-arrow"></div>
        <label for="email-type" class="label">
          {{
            "REGISTER.GENERIC_FORM.EMAIL.PERSONAL_OR_WORK.QUESTION" | translate
          }}
        </label>
        <mat-radio-group
          name="email-type"
          id="email-type"
          formControlName="emailType"
          class="radio-group"
          [ngClass]="{
            invalid: creationForm.get('emailType').errors?.required
          }"
        >
          <mat-radio-button value="personal">
            {{
              "REGISTER.GENERIC_FORM.EMAIL.PERSONAL_OR_WORK.PERSONAL"
                | translate
            }}
          </mat-radio-button>
          <mat-radio-button value="work">
            {{
              "REGISTER.GENERIC_FORM.EMAIL.PERSONAL_OR_WORK.WORK" | translate
            }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div aria-live="polite">
        <span
          class="input__text-error"
          *ngIf="
            creationForm.get('emailType').errors?.required &&
            !creationForm.get('email').errors
          "
        >
          {{ "REGISTER.GENERIC_FORM.ERRORS.EMAIL_TYPE" | translate }}
        </span>
      </div>
    </div>
    <div>
      <app-password-field
        [label]="'REGISTER.GENERIC_FORM.PASSWORD.PASSWORD' | translate"
        [passwordComplexity]="passwordComplexity"
        formControlName="password"
        required
      >
        <p *ngIf="creationForm.get('password').errors?.knownBadPassword">
          {{ "REGISTER.GENERIC_FORM.ERRORS.PASSWORD_COMMON" | translate }}
        </p>
      </app-password-field>
      <div role="alert">
        <span
          class="input__error-text"
          *ngIf="
            creationForm.get('password').touched &&
            !creationForm.get('password').valid
          "
        >
          <p *ngIf="creationForm.get('password').errors?.required">
            {{ "REGISTER.GENERIC_FORM.ERRORS.PASSWORD" | translate }}
          </p>
        </span>
      </div>
    </div>
  </div>

  <div class="checkbox__terms-container">
    <div class="checkbox__terms">
      <mat-checkbox
        formControlName="termsAndPrivacy"
        required
        [ngClass]="{
          invalid:
            creationForm.get('termsAndPrivacy').errors?.required &&
            creationForm.get('termsAndPrivacy').touched
        }"
        aria-labelledby="terms-description"
      >
      </mat-checkbox>
      <p id="terms-description">
        <span class="checkbox__terms-description">{{
          "GENERAL.AGREE" | translate
        }}</span>
        <a
          class="checkbox__terms-description"
          href="https://www.cariloop.com/terms-of-service/"
          target="_blank"
          >{{ "GENERAL.TOS" | translate }}</a
        >
        <span class="checkbox__terms-description">{{
          "GENERAL.AND" | translate
        }}</span>
        <a
          class="checkbox__terms-description"
          href="https://www.cariloop.com/privacy-policy/"
          target="_blank"
          >{{ "GENERAL.PRIVACY" | translate }}</a
        >
      </p>
    </div>
    <div role="alert">
      <span
        class="input__error-text"
        *ngIf="
          creationForm.get('termsAndPrivacy').errors?.required &&
          creationForm.get('termsAndPrivacy').touched
        "
      >
        {{ "GENERAL.TOS_ERROR" | translate }}
      </span>
    </div>
  </div>
  <c-button
    *ngIf="!isLoading && !isSSO"
    type="submit"
    [text]="'REGISTER.GENERIC_FORM.CREATE_BTN' | translate"
    buttonType="action"
    buttonStyle="fill"
    size="large"
  ></c-button>
  <c-button
    *ngIf="!isLoading && isSSO"
    type="submit"
    [text]="'REGISTER.CONTINUE' | translate"
    buttonType="action"
    buttonStyle="fill"
    size="large"
  ></c-button>
  <ng-container *ngIf="isLoading">
    <img src="assets/img/loader.png" alt="Loading" class="loading" />
  </ng-container>
</form>

<p *ngIf="!isMobileWebView && !isSSO" class="login-code-text">
  {{ "REGISTER.SIGN_IN_TEXT" | translate }}
  <a [routerLink]="['/login']">
    {{ "REGISTER.SIGN_IN_LINK" | translate }}
  </a>
</p>
