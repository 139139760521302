import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'app/app.service';
import { AppStateType } from 'app/shared/types/app-state-type';
import { LANGUAGES } from '../languages';
import { AccountService } from '../shared/services/account.service';
import { AppTranslateService } from '../shared/services/app-translate.service';
import { Account } from '../shared/types/account.type';
import { Language } from '../shared/types/language.type';

@Component({
  selector: 'top-nav',
  templateUrl: 'top-nav.html',
  styleUrls: ['top-nav.scss'],
})
export class TopNavComponent implements OnInit {
  @Input() public isAdventureView = false;

  public isLogged: boolean;
  public rootName = '';
  public availableLangs: Array<Language> = [];
  public loggedUser: Account;
  public browserLanguage: { [key: string]: string } = {
    value: 'en',
    label: 'English',
  };

  constructor(
    private accountService: AccountService,
    private appTranslate: AppTranslateService,
    private translate: TranslateService,
    private appState: AppState,
  ) {
    this.languageSubscription();
    this.setLanguage();
    if (localStorage.getItem('cariloop:language')) {
      const localLanguage = JSON.parse(
        localStorage.getItem('cariloop:language'),
      );
      this.browserLanguage =
        LANGUAGES.find((lang) => lang.value === localLanguage?.value) ??
        LANGUAGES[0];
    } else {
      this.browserLanguage =
        LANGUAGES.find(
          (lang) => lang.value === window.navigator.language.substring(0, 2),
        ) ?? LANGUAGES[0];
      this.appTranslate.updateLang(this.browserLanguage.value);
    }
  }

  public ngOnInit() {
    this.isLogged = this.checkIfLogged();
  }

  changeLang(lang: string) {
    const newLanguage = this.availableLangs.find((l) => l.value === lang);
    this.accountService.setLocalLanguage(newLanguage);
    this.appTranslate.updateLang(lang);
    return newLanguage;
  }

  public languageSubscription(): void {
    this.appTranslate
      .onLangChange()
      .subscribe((lang) => this.switchLanguage(lang));
  }

  public switchLanguage(language = 'en'): void {
    this.availableLangs = this.filterLanguages(
      language || this.translate.getDefaultLang(),
      LANGUAGES,
    );
    this.translate.use(language);
  }

  public filterLanguages(
    currentLang: string,
    langList: Array<Language>,
  ): Array<Language> {
    return langList.filter((lang) => lang.value !== currentLang);
  }

  public setLanguage(): void {
    const currentUser = this.accountService.getLogged();
    const memoryLang = this.accountService.getLocalLanguage();
    let currentLang: string;
    this.translate.setDefaultLang('en');
    if (memoryLang && memoryLang.value) {
      currentLang = memoryLang.value;
    } else {
      currentLang =
        !(currentUser instanceof Error) && currentUser.language
          ? currentUser.language
          : this.translate.getBrowserLang();
    }
    this.switchLanguage(currentLang);
  }

  private checkIfLogged() {
    const getLoggedResult = this.accountService.getLogged();
    if (getLoggedResult instanceof Error) {
      return false;
    }

    this.loggedUser = getLoggedResult;
    return true;
  }

  public appStateSubscription(): void {
    this.appState
      .onAppStateUpdate()
      .subscribe((update) => this.manageAppState(update));
  }

  manageAppState(state: AppStateType) {
    this.rootName = state.path.root;
    this.isLogged = this.checkIfLogged();
  }
}
