import { Injectable } from "@angular/core";
import { Gift } from "../types/gift.type";
import { HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { BaseModelService } from "./base-model.service";

@Injectable({
  providedIn: "root",
})
export class GiftService {
  private baseUrl = "";
  private headers: HttpHeaders = new HttpHeaders();

  constructor(private http: BaseModelService) {
    this.headers.append("Accept", "application/json");
    this.headers.append("Content-Type", "application/json");
    this.baseUrl = `${environment.BASE_URL}gifts`;
  }

  /**
   * Is responsible for public permissions
   * with General type.
   * @returns
   */
  public getByVoucherCode(voucherCode: string) {
    const params = new HttpParams().set("voucherCode", voucherCode);
    return this.http.get<Gift>(`${this.baseUrl}/by-voucher-code`, {
      headers: this.headers,
      params,
    });
  }
}
