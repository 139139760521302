import { Component, Input, OnInit } from '@angular/core';
import { LanguageType } from '@cariloop/ui-components/lib/models/language.interface';
import { LANGUAGES } from 'app/languages';
import { AccountService } from 'app/shared/services/account.service';
import { AppTranslateService } from 'app/shared/services/app-translate.service';

@Component({
  selector: 'sso-error',
  templateUrl: './sso-error.component.html',
  styleUrls: ['./sso-error.component.scss']
})
export class SsoErrorComponent {
    browserLanguage: LanguageType = {
        value: 'en',
        label: 'English',
      };
    
      @Input()
      isMobileWebView = false;
    
      constructor(
        private readonly accountService: AccountService,
        private readonly appTranslateService: AppTranslateService,
      ) {}
    
      ngOnInit() {
        const localStorageLanguage = localStorage.getItem('cariloop:language');
        if (localStorageLanguage === null || localStorageLanguage === '') {
          this.browserLanguage =
            LANGUAGES.find(
              ({ value }) => value === window.navigator.language.substring(0, 2),
            ) ?? LANGUAGES[0];
    
          this.appTranslateService.updateLang(this.browserLanguage.value);
    
          return;
        }
    
        const parsedLanguage = JSON.parse(localStorageLanguage);
        this.browserLanguage =
          LANGUAGES.find(({ value }) => value === parsedLanguage?.value) ??
          LANGUAGES[0];
      }
    
      changeLanguage(language: string) {
        const newLanguage = LANGUAGES.find(({ value }) => value === language);
    
        this.accountService.setLocalLanguage(newLanguage);
    
        this.appTranslateService.updateLang(language);
    
        return newLanguage;
      }
}