import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { AccountService } from "../services/account.service";
import { map } from "rxjs/operators";

export const contractStartDateResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const accountService = inject(AccountService);
  const account = accountService.getLogged();

  if (account instanceof Error) {
    return undefined;
  }
  return accountService
    .getAccountOrg(account.id)
    .pipe(map((org) => org.contractStartDate));
};
