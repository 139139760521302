<app-register-layout
  [flowMode]="flow$ | async"
  [ngSwitch]="state().currentStep"
  [currentStep]="state().currentStep"
>
  <app-registration-header
    [currentStep]="currentStepIndex() + 1"
    [totalSteps]="totalSteps()"
    [isMobileWebView]="isMobileWebView"
    [hideStepCounter]="state().currentStep === 'create-account'"
  />

  <main>
    <account-creation
      (formSubmitted)="handleAccountCreationSubmit($event)"
      *ngSwitchCase="'create-account'"
      [isMobileWebView]="isMobileWebView"
      [isSSO]="isSSO"
      [ssoEmail]="ssoEmail"
    />

    <app-email-verification
      (nextStep)="goToNextStep()"
      *ngSwitchCase="'email-verification'"
    />

    <app-backup-contact
      (nextStep)="goToNextStep()"
      *ngSwitchCase="'backup-contact'"
    />

    <app-eligibility-check
      (formSubmitted)="goToNextStep()"
      *ngSwitchCase="'eligibility-check'"
      [registeredEmail]="this.state().registeredEmail"
    />
  </main>
</app-register-layout>
