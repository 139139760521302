import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { canRegisterGuard } from "app/shared/guards/can-register.guard";
import { AuthRedirect } from "./authRedirect";
import { DisconnectionComponent } from "./disconnection/disconnection.component";
import { GiftRedemptionComponent } from "./gift-redemption/gift-redemption.component";
import { LoadingStateComponent } from "./loading-state/loading-state.component";
import { SignCallbackComponent } from "./sign-callback/sign-callback.component";
import { UrlNotFoundComponent } from "./url-not-found/url-not-found.component";

export const ROUTES: Routes = [
  {
    path: "forgot-password",
    loadChildren: () =>
      import("app/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  { path: "disconnection", component: DisconnectionComponent },
  { path: "sign-callback", component: SignCallbackComponent },
  {
    path: "masthead",
    loadChildren: () =>
      import("app/masthead/masthead.module").then((m) => m.MastheadModule),
  },
  {
    path: "pgp",
    loadChildren: () => import("app/pgp/pgp.module").then((m) => m.PgpModule),
  },
  {
    path: "event",
    loadChildren: () =>
      import("app/event/event.module").then((m) => m.EventModule),
  },
  { path: "loading-state", component: LoadingStateComponent },
  {
    path: "gift-redemption",
    component: GiftRedemptionComponent,
    canActivate: [canRegisterGuard],
  },
  {
    path: "mobile",
    loadComponent: () =>
      import("./mobile-store-redirect/mobile-store-redirect.component").then(
        ({ MobileStoreRedirectComponent }) => MobileStoreRedirectComponent,
      ),
  },
  {
    path: "webinar/:id",
    loadChildren: () =>
      import("app/webinar/webinar.module").then((m) => m.WebinarModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("app/access/access.module").then((m) => m.AccessModule),
  },
  {
    path: "**",
    pathMatch: "full",
    component: UrlNotFoundComponent,
    canActivate: [AuthRedirect],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {})],
  exports: [RouterModule],
})
export class AppRouteModule {}
