import { Injectable } from '@angular/core';
import { AppStateType } from './shared/types/app-state-type';
import { Subject ,  Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AppState {
  public subject = new Subject<boolean>();
  private disconnectionSubject = new Subject<any>();
  private initialLoad = false;
  private scrollStatus: any = {};
  private caseUpdateSubject = new Subject<any>();
  private appStateSubject = new Subject<any>();

  public caseUpdate(): Observable<any> {
    return this.caseUpdateSubject.asObservable();
  }

  public onCaseUpdate(_case: any): void {
    this.caseUpdateSubject.next(_case);
  }

  public status(error = {status: 0}): void {
    const status = error.status === 0 || error.status >= 503 ?
      'inactive' : 'active';
    this.disconnectionSubject.next(status);
  }

  public onStatusChange(): Observable<string> {
    return this.disconnectionSubject.asObservable();
  }

  public onAppStateUpdate(): Observable<AppStateType> {
    return this.appStateSubject.asObservable();
  }

  public appStateUpdate(appState: AppStateType) {
    this.appStateSubject.next(appState);
  }

  public getInitialLoadStatus() {
    return this.initialLoad;
  }

  public getIsPaidSubject() {
    return this.subject;
  }

  public setInitialLoadStatus() {
    this.initialLoad = true;
  }

  public removeLoadStatus() {
    this.initialLoad = false;
  }

  public setScrollStatus(view: string, scrollCount: number, anchorId: string, YOffset?: number) {
    this.scrollStatus = {
      view,
      scrollCount,
      anchorId,
      YOffset
    };
  }

  public getScrollStatus() {
    return this.scrollStatus;
  }

  public clearScrollStatus() {
    this.scrollStatus = {};
  }

  public formatError(httpError: HttpErrorResponse | Error): Error {
    const error = new Error();
    if (httpError instanceof Error) {
      error.name = httpError.name;
      error.message = httpError.message;
    } else {
      const message = httpError?.error?.error?.message || 'There was an error processing your request';
      const stack = httpError?.error?.error?.stack;
      error.name = message;
      error.message = stack;
    }
    return error;
  }

}
