import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "../pipes/pipes.module";
import { MdErrorHandlerComponent } from "./md-error-handler/md-error-handler.component";
import { MatIconModule } from "@angular/material/icon";
import { PasswordMatchDirective } from "./directives/password-match.directive";
import { PasswordValidationDirective } from "./directives/password-valid.directive";
import { BlackContainerComponent } from "./black-container/black-container.component";

import { TopNavModule } from "../top-nav/top-nav.module";
import {
  LangDropdownComponent,
  UIComponentsModule,
} from "@cariloop/ui-components";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild(),
    PipesModule,
    MatIconModule,
    TopNavModule,
  ],
  declarations: [
    PasswordMatchDirective,
    PasswordValidationDirective,
    MdErrorHandlerComponent,
    BlackContainerComponent,
  ],
  exports: [
    PasswordMatchDirective,
    PasswordValidationDirective,
    MdErrorHandlerComponent,
    BlackContainerComponent,
  ],
})
export class WidgetsModule {}
