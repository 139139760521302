<div class="main-container">
  <div class="description-container">
    <div class="title">
      {{ "REGISTER.BACKUP_CONTACT.CONTACT" | translate }}
    </div>
    <div class="text">
      {{ "REGISTER.BACKUP_CONTACT.DESCRIPTION" | translate }}
    </div>
  </div>
  <form [formGroup]="backupForm" class="form-container">
    <div class="inputs-container">
      <c-banner
        *ngIf="state.hasServerSideError"
        class="backup-contact-banner-error"
        iconPositionX="top"
        [style]="state.errorType === 'email-already-used' ? 'warning' : 'error'"
        [message]="
          state.errorType === 'gateway-timeout'
            ? ('REGISTER.GENERIC_FORM.ERRORS.GENERIC-GATEWAY-TIMEOUT'
              | translate)
            : state.errorType === 'email-already-used'
              ? ('REGISTER.BACKUP_CONTACT.ERRORS.EMAIL-ALREADY-USE' | translate)
              : ''
        "
      >
        <div *ngIf="state.errorType === 'generic-error'" class="banner-message">
          <span>
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-1" | translate
            }}
          </span>
          <span>
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-2" | translate
            }}
          </span>
          <a class="banner-link" href="mailto:support@cariloop.com">
            {{
              "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-3" | translate
            }}
          </a>
        </div>
      </c-banner>
      <div class="radio-container">
        <div class="input-title">
          {{ "REGISTER.BACKUP_CONTACT.ALTERNATE_CONTACT_METHOD" | translate }}
        </div>
        <mat-radio-group class="radio-menu" formControlName="method">
          <mat-radio-button [value]="true" class="radio-item">
            {{ "REGISTER.BACKUP_CONTACT.PERSONAL_EMAIL" | translate }}
          </mat-radio-button>
          <mat-radio-button [value]="false" class="radio-item">
            {{ "REGISTER.BACKUP_CONTACT.PHONE_NUMBER" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div *ngIf="backupForm.get('method').value" class="input-container">
        <div class="input-title">
          {{ "REGISTER.BACKUP_CONTACT.INPUT_EMAIL" | translate }}
        </div>
        <c-input-text
          class="input backup-contact-input--email"
          formControlName="email"
          name="email"
          required
          type="text"
        >
          <mat-error
            *ngIf="
              backupForm.get('email').touched && backupForm.get('email').errors
            "
          >
            {{ "REGISTER.BACKUP_CONTACT.EMAIL_ERROR" | translate }}
          </mat-error>
        </c-input-text>
      </div>
      <div *ngIf="!backupForm.get('method').value" class="input-container">
        <div class="input-title">
          {{ "REGISTER.BACKUP_CONTACT.INPUT_PHONE" | translate }}
        </div>
        <c-input-text
          class="input"
          formControlName="phone"
          name="phone"
          required
          type="text"
        >
          <mat-error
            *ngIf="
              backupForm.get('phone').touched && backupForm.get('phone').errors
            "
          >
            {{ "REGISTER.BACKUP_CONTACT.PHONE_ERROR" | translate }}
          </mat-error>
        </c-input-text>
        <div *ngIf="!backupForm.get('method').value" class="phone-text">
          {{ "REGISTER.BACKUP_CONTACT.DESCRIPTION_PHONE" | translate }}
        </div>
      </div>
    </div>
    <c-button
      [buttonDisabled]="!backupForm.valid"
      (onClick)="onContinue()"
      buttonStyle="fill"
      buttonType="action"
      class="btn full-button backup-contact-button--submit"
      size="large"
      text="{{ 'REGISTER.BACKUP_CONTACT.CONTINUE' | translate }}"
    ></c-button>
  </form>
</div>
