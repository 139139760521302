import { NgModule } from "@angular/core";
import { RegisterComponent } from "./register.component";
import { RouterModule, Routes } from "@angular/router";
import { CompleteComponent } from "./complete/complete.component";
import { missingStepsResolver } from "app/shared/resolvers/missing-registration-steps.resolver";
import { MissingRegistrationStepsGuard } from "app/shared/guards/missing-registration-steps.guard";
import { canRegisterGuard } from "app/shared/guards/can-register.guard";
import { SsoErrorComponent } from "./sso-error/sso-error.component";
import { RegisterProvisioningGuard } from "app/shared/guards/register-provisioning.guard";
import { inactiveEmployerGuard } from "app/shared/guards/inactive-employer.guard";
import { contractStartDateResolver } from "app/shared/resolvers/contractStartDate.resolver";

const AccessRoutes: Routes = [
  {
    path: "",
    component: RegisterComponent,
    canActivate: [canRegisterGuard, RegisterProvisioningGuard],
    resolve: {
      missingSteps: missingStepsResolver,
    },
  },
  { path: "sso-error", component: SsoErrorComponent },
  {
    path: "complete",
    component: CompleteComponent,
    canActivate: [MissingRegistrationStepsGuard],
    resolve: {
      missingSteps: missingStepsResolver,
    },
  },
  {
    path: "not-ready",
    canActivate: [inactiveEmployerGuard],
    loadComponent: () =>
      import("./inactive-employer/inactive-employer.component").then(
        (m) => m.InactiveEmployerComponent,
      ),
    resolve: {
      contractStartDate: contractStartDateResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(AccessRoutes)],
  exports: [RouterModule],
})
export class RegisterRoutesModule {}
