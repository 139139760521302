import { inject } from "@angular/core";
import { AccountRegistrationService } from "../services/account-registration.service";
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from "@angular/router";
import { CompleteRegisterStep } from "../types/complete-register-step.type";
import { AccountService } from "../services/account.service";

export const missingStepsResolver: ResolveFn<CompleteRegisterStep[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const logged = inject(AccountService).getLogged();
  if (logged instanceof Error) {
    return undefined;
  }

  return inject(AccountRegistrationService).missingSteps(logged.id);
};
