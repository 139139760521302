import { inject, Injectable } from "@angular/core";
import { BaseModelService } from "app/shared/services/base-model.service";
import type { Account } from "app/shared/types/account.type";
import { environment } from "environments/environment";

const BASE_URL = `${environment.BASE_URL}accounts`;

export type LoginCredentials = { email: Account["email"]; password: string };

@Injectable()
export class MfaService {
  readonly #http = inject(BaseModelService);

  sendMfaEmail(loginCredentials: LoginCredentials) {
    return this.#http.post<{ accountMfaCodeToken: string }>(
      `${BASE_URL}/send-mfa-email`,
      loginCredentials,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }
}
