<section class="existing-backup-contact-container">
  <div>
    <h1 class="existing-backup-contact-header">
      {{ "REGISTER.BACKUP_CONTACT.CONTACT" | translate }}
    </h1>

    <p class="existing-backup-contact-explanation">
      {{ "REGISTER.BACKUP_CONTACT.DESCRIPTION" | translate }}
    </p>
  </div>

  <form
    (ngSubmit)="handleSubmit()"
    [formGroup]="existingBackupContactForm"
    class="existing-backup-contact-form"
    id="existing-backup-contact-form"
  >
    <c-banner
      *ngIf="state().hasServerSideError"
      class="existing-backup-contact-banner-error"
      iconPositionX="top"
      [style]="state().errorType === 'email-already-used' ? 'warning' : 'error'"
      [message]="
        state().errorType === 'gateway-timeout'
          ? ('REGISTER.GENERIC_FORM.ERRORS.GENERIC-GATEWAY-TIMEOUT' | translate)
          : state().errorType === 'email-already-used'
            ? ('REGISTER.BACKUP_CONTACT.ERRORS.EMAIL-ALREADY-USE' | translate)
            : ''
      "
    >
      <div
        *ngIf="state().errorType === 'generic-error'"
        class="existing-backup-contact-banner-message"
      >
        <span>
          {{ "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-1" | translate }}
        </span>
        <span>
          {{ "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-2" | translate }}
        </span>
        <a
          class="existing-backup-contact-banner-link"
          href="mailto:support@cariloop.com"
        >
          {{ "REGISTER.GENERIC_FORM.ERRORS.GENERIC-SERVER-PART-3" | translate }}
        </a>
      </div>
    </c-banner>

    <div>
      <label
        class="existing-backup-contact-radio-label"
        id="work-personal-label"
      >
        {{
          "REGISTER.EXISTING_BACKUP_CONTACT.IS_YOUR_WORK_OR_PERSONAL_EMAIL"
            | translate: { emailAddress }
        }}
      </label>

      <mat-radio-group
        [disabled]="state().isSubmitting"
        aria-labelledby="work-personal-label"
        formControlName="emailType"
      >
        <mat-radio-button
          checked="true"
          class="existing-backup-contact-radio"
          value="personal"
        >
          {{ "REGISTER.EXISTING_BACKUP_CONTACT.PERSONAL_EMAIL" | translate }}
        </mat-radio-button>
        <mat-radio-button class="existing-backup-contact-radio" value="work">
          {{ "REGISTER.EXISTING_BACKUP_CONTACT.WORK_EMAIL" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div
      *ngIf="(selectedEmailType$ | async) === 'work'"
      class="existing-backup-contact-alternate-contact-method-container"
      formGroupName="alternateContactMethodGroup"
    >
      <div>
        <label
          class="existing-backup-contact-radio-label"
          id="alternate-contact-method-label"
        >
          {{ "REGISTER.BACKUP_CONTACT.ALTERNATE_CONTACT_METHOD" | translate }}
        </label>

        <mat-radio-group
          [disabled]="state().isSubmitting"
          aria-labelledby="alternate-contact-method-label"
          formControlName="alternateContactMethod"
        >
          <mat-radio-button
            checked="true"
            class="existing-backup-contact-radio"
            value="personalEmail"
          >
            {{ "REGISTER.BACKUP_CONTACT.PERSONAL_EMAIL" | translate }}
          </mat-radio-button>
          <mat-radio-button
            class="existing-backup-contact-radio"
            value="phoneNumber"
          >
            {{ "REGISTER.BACKUP_CONTACT.PHONE_NUMBER" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <c-input-text
        class="existing-backup-contact-input--email"
        *ngIf="(selectedAlternateContactMethod$ | async) === 'personalEmail'"
        [disabled]="state().isSubmitting"
        formControlName="personalEmail"
        label="{{ 'REGISTER.BACKUP_CONTACT.INPUT_EMAIL' | translate }}"
        type="email"
      >
        <ng-container
          *ngIf="
            existingBackupContactForm.get(
              'alternateContactMethodGroup.personalEmail'
            ).errors?.required !== undefined
          "
        >
          {{
            "REGISTER.EXISTING_BACKUP_CONTACT.REQUIRED_EMAIL_ADDRESS"
              | translate
          }}
        </ng-container>
        <ng-container
          *ngIf="
            existingBackupContactForm.get(
              'alternateContactMethodGroup.personalEmail'
            ).errors?.pattern !== undefined
          "
        >
          {{
            "REGISTER.EXISTING_BACKUP_CONTACT.INVALID_EMAIL_ADDRESS" | translate
          }}
        </ng-container>
      </c-input-text>

      <c-input-text
        *ngIf="(selectedAlternateContactMethod$ | async) === 'phoneNumber'"
        [disabled]="state().isSubmitting"
        formControlName="phoneNumber"
        label="{{ 'REGISTER.BACKUP_CONTACT.INPUT_PHONE' | translate }}"
        type="text"
      >
        <ng-container
          *ngIf="
            existingBackupContactForm.get(
              'alternateContactMethodGroup.phoneNumber'
            ).errors?.required !== undefined
          "
        >
          {{
            "REGISTER.EXISTING_BACKUP_CONTACT.REQUIRED_PHONE_NUMBER" | translate
          }}
        </ng-container>
        <ng-container
          *ngIf="
            existingBackupContactForm.get(
              'alternateContactMethodGroup.phoneNumber'
            ).errors?.pattern !== undefined
          "
        >
          {{
            "REGISTER.EXISTING_BACKUP_CONTACT.INVALID_PHONE_NUMBER" | translate
          }}
        </ng-container>
      </c-input-text>
    </div>
  </form>

  <ng-container *ngIf="state().isSubmitting; then spinner; else submitButton" />
  <ng-template #spinner>
    <mat-spinner diameter="40" />
  </ng-template>
  <ng-template #submitButton>
    <button
      class="existing-backup-contact-form-button"
      form="existing-backup-contact-form"
      type="submit"
    >
      {{ "REGISTER.EXISTING_BACKUP_CONTACT.CONTINUE" | translate }}
    </button>
  </ng-template>
</section>
