<div class="main-gift-container">
  <div class="gift-redemption-container">
    <img
      alt="cariloop-logo"
      class="cariloop-logo"
      height="29"
      ngSrc="assets/img/cariloop-logo.svg"
      width="98"
    />
    <img
      alt="gifts-cariloop"
      class="cariloop-gifts"
      height="100"
      ngSrc="assets/img/gift-cariloop.svg"
      width="236"
    />
    <div class="title">
      {{ gift.sender.firstName }} gifted you {{ gift.product.term }}
      {{ gift.product.term > 1 ? 'months' : 'month' }} of Cariloop!
    </div>
    <div class="description">
      We'll help you navigate the demands of care for anyone you consider
      family.
    </div>

    <div class="gift-list">
      <div class="gift-row-item">
        <mat-icon class="gift-row-item-icon material-symbols-outlined">
          verified
        </mat-icon>
        <div class="gift-row-item-description">
          Get matched with a licensed/certified Care Coach
        </div>
      </div>
      <div class="gift-row-item">
        <mat-icon class="gift-row-item-icon material-symbols-outlined">
          tactic
        </mat-icon>
        <div class="gift-row-item-description">
          Navigate next steps for you and your loved one
        </div>
      </div>
      <div class="gift-row-item">
        <img
          alt="Lab profile"
          class="gift-row-item-icon"
          height="24"
          ngSrc="assets/icon/lab_profile.svg"
          width="24"
        />
        <div class="gift-row-item-description">
          Track medications, providers, and key information
        </div>
      </div>
      <div class="gift-row-item">
        <mat-icon class="gift-row-item-icon material-symbols-outlined">
          forum
        </mat-icon>
        <div class="gift-row-item-description">
          Message your Coach whenever you have a question
        </div>
      </div>
    </div>

    <div *ngIf="isNowBeforeLaunchDate$ | async" class="coming-soon-container">
      Coming soon: January 1, 2024
    </div>
    <c-button
      (click)="handleRedeemGiftClick()"
      *ngIf="(isNowBeforeLaunchDate$ | async) === false"
      buttonSize="large"
      buttonStyle="fill"
      buttonType="action"
      class="redeem-button"
      text="Redeem gift"
    />
  </div>
</div>
