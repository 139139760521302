<app-black-container>
  <div class="white-space-container">
    <div id="urban-sitter-banner" *ngIf="isUrbanSitterRedirect">
      <div class="horizontal-banner-flex">
        <img
          src="assets/img/cariloop-logo-dark-text.svg"
          [alt]="'GENERAL.CARILOOP_LOGO_ALT' | translate"
        />
        <mat-icon aria-hidden="true" fontIcon="add"></mat-icon>
        <img
          src="assets/img/urban-sitter-logo.png"
          [alt]="'GENERAL.URBAN_SITTER_LOGO_ALT' | translate"
        />
      </div>
      <p>{{'LOGIN.URBAN_SITTER_BANNER' | translate}}</p>
    </div>
    <div class="form-container">
      <div class="title-container full-width">
        <h2 class="title" *ngIf="screen==='login'">
          {{ 'LOGIN.TITLE' | translate }}
        </h2>
      </div>
      <p
        *ngIf="loginMessage && screen==='login'"
        class="header-md-medium header"
      >
        {{loginMessage | translate }}
        <br />
        &nbsp;
      </p>
      <div role="alert">
        <app-md-error-handler [error]="error"></app-md-error-handler>
      </div>
      <form
        (ngSubmit)="onSubmit()"
        #form="ngForm"
        class="login-form"
        *ngIf="screen==='login'"
      >
        <div class="inputs-container">
          <div class="input-container">
            <c-input-text
              #emailRef="ngModel"
              name="email"
              id="email"
              type="text"
              autocomplete="email"
              [(ngModel)]="model.email"
              (change)="lowercaseEmail(); validateEmail(form)"
              (animationstart)="animationEvent($event, 'email')"
              [label]="'LOGIN.EMAIL_ADDRESS' | translate "
              inputID="email-input"
              [required]="true"
            >
            </c-input-text>
            <div role="alert">
              <span
                class="login__input-error"
                *ngIf="emailRef.touched && !emailRef.valid"
              >
                <p *ngIf="emailRef?.errors?.pattern">
                  {{ 'LOGIN.EMAIL_PATTERN_ERROR' | translate }}
                </p>
                <p *ngIf="emailRef?.errors?.required">
                  {{ 'LOGIN.EMAIL_REQUIRED_ERROR' | translate }}
                </p>
              </span>
            </div>
          </div>
          <div class="input-container">
            <app-password-field
              [label]="'LOGIN.PASSWORD' | translate"
              #passRef="ngModel"
              [(ngModel)]="model.password"
              (animationstart)="animationEvent($event, 'password')"
              autocomplete="current-password"
              id="password"
              type="password"
              name="password"
              [passwordComplexity]="passwordComplexity"
              [required]="true"
            ></app-password-field>
            <div role="alert">
              <span
                class="login__input-error"
                *ngIf="passRef.touched && !passRef.valid"
              >
                <p>{{ 'LOGIN.PASSWORD_ERROR' | translate }}</p>
              </span>
            </div>
          </div>
        </div>
        <div class="forgot-buttons-container">
          <a
            routerLink="/forgot-password"
            class="mat-link mat-caption forgot-password"
          >
            {{ 'LOGIN.FORGOT_PASSWORD' | translate }}
          </a>
        </div>
        <div class="forgot-password-container full-width">
          <div class="buttons-flex">
            <c-button
              *ngIf="!isLoading"
              type="submit"
              [text]="'LOGIN.SIGN_IN' | translate"
              class="full-button"
              buttonType="neutral"
              buttonStyle="fill"
              size="large"
              [buttonDisabled]="!form.form.valid || disableLogin || !model.email"
            ></c-button>
            <mat-spinner
              *ngIf="isLoading"
              class="loading-spinner"
              [diameter]="40"
            ></mat-spinner>

            <ng-container
              *ngIf="!loginWithUrl && oneLoginUrl  && !isUrbanSitterRedirect"
            >
              <p aria-hidden="true" class="mat-caption or-container full-width">
                {{ 'LOGIN.OR' | translate }}
              </p>
              <a
                [href]="oneLoginUrl"
                tabindex="0"
                [attr.aria-label]="'LOGIN.ONELOGIN_ARIA' | translate"
                class="full-width login-with-button"
              >
                {{ 'LOGIN.ONELOGIN' | translate }}
              </a>
            </ng-container>

            <ng-container
              *ngIf="!loginWithUrl && alightUrl  && !isUrbanSitterRedirect"
            >
              <p aria-hidden="true" class="mat-caption or-container full-width">
                {{ 'LOGIN.OR' | translate }}
              </p>
              <a
                [href]="alightUrl"
                tabindex="0"
                class="full-width login-with-button"
              >
                {{ 'LOGIN.ALIGHT_LOGIN' | translate }}
              </a>
            </ng-container>

            <ng-container *ngIf="loginWithUrl">
              <p aria-hidden="true" class="mat-caption or-container full-width">
                {{ 'LOGIN.OR' | translate }}
              </p>
              <a
                [href]="loginWithUrl"
                tabindex="0"
                class="full-width login-with-button"
              >
                {{ 'LOGIN.SIGN_IN_WITH' | translate }} {{loginWithName}}
              </a>
            </ng-container>
          </div>

          <div
            class="login-code-container full-width"
            *ngIf="!showSignInCodeField"
          >
            <button
              class="mat-link login-code-text"
              id="login-code-button"
              (click)="openSignInCodeField()"
              tabindex="0"
            >
              {{ 'LOGIN.LOGIN_CODE' | translate }}
            </button>
          </div>
          <div class="sign-in-container" *ngIf="showSignInCodeField">
            <form
              (ngSubmit)="loginWithCode(loginCodeForm)"
              #loginCodeForm="ngForm"
              id="loginCodeForm"
            >
              <div class="input-container">
                <c-input-text
                  required
                  name="loginCode"
                  #loginCodeModel="ngModel"
                  type="text"
                  [(ngModel)]="loginCode"
                  [label]="'LOGIN.CODE' | translate"
                  [ariaLabel]="'LOGIN.LOGIN_CODE_ARIA' | translate"
                  inputID="login-code-input"
                >
                </c-input-text>
                <div role="alert">
                  <span
                    class="login__input-error"
                    *ngIf="loginCodeModel?.touched && !loginCodeModel.valid"
                  >
                    <p *ngIf="loginCode && !loginCode.valid">
                      {{ 'LOGIN.LOGIN_CODE_ERROR_EXPIRED' | translate }}
                    </p>
                    <p *ngIf="loginCodeModel.invalid">
                      {{ 'LOGIN.LOGIN_CODE_ERROR_INVALID' | translate }}
                    </p>
                  </span>
                </div>
              </div>
              <c-button
                type="submit"
                [text]="'LOGIN.SIGN_IN_WITH_CODE' | translate"
                class="full-button"
                buttonType="neutral"
                buttonStyle="fill"
                size="large"
                [buttonDisabled]="!loginCodeForm.form.valid"
              ></c-button>
            </form>
          </div>
        </div>
      </form>
      <div class="login__sign-up-container full-width">
        <div *ngIf="showRegister && screen !== 'mfa'">
          <p>{{ 'LOGIN.SIGN_UP_TEXT' | translate }}</p>
          <a
            routerLink="/register"
            class="mat-link"
            tabindex="0"
            id="sign-up-link"
          >
            {{ 'LOGIN.SIGN_UP' | translate }}
          </a>
        </div>
      </div>
      <app-mfa-login
        *ngIf="screen!=='login'"
        [loginCredentials]="{email: model.email,password:model.password}"
        [mfaToken]="mfaToken"
        [mfaType]="mfaType()"
        (backToLogin)="screen='login'"
        (mfaSuccess)="handleLoginResponse($event)"
      ></app-mfa-login>
    </div>
    <app-cta-mobile
      *ngIf="showMobileAdoptionCTA && screen !== 'mfa'"
      class="cta-mobile"
    ></app-cta-mobile>
  </div>
</app-black-container>
<div class="analytics t-error" (dblclick)="throwError()"></div>

<app-mobile-app-cta
  *ngIf="showMobileWebCTA"
  [device]="device"
  [isMobileDevice]="isMobileDevice"
  (shouldShowCTA)="changeMobileCTAState($event)"
  [@fadeInOut]="showMobileWebCTA"
></app-mobile-app-cta>
