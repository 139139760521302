export const environment = {
  production: true,
  envName: "production",
  gaId: "UA-34144422-3",
  STRIPE_KEY: "pk_live_Q6nrhItagQm747NHvunjwKEw",
  BASE_URL: "https://api.cariloop.com/rest/",
  SCHEDULER_BASE_URL: "https://scheduler.cariloop.com/api/",
  STORE_BASE_URL: "https://store.cariloop.com/rest/",
  SF_FLOW_URL: "https://cariloop.secure.force.com/Portal?authid=g8H9tQNimxtbJf",
  PLAN_URL: "https://plan.cariloop.com/plan",
  AUTH_URL: "https://plan.cariloop.com",
  COACH_URL: "https://plan.cariloop.com/coach",
  ADMIN_URL: "https://plan.cariloop.com/admin",
  ENTERPRISE_URL: "https://plan.cariloop.com/enterprise",
  ONBOARDING_URL: "https://plan.cariloop.com/onboarding",
  URBANSITTER_URL: "https://www.urbansitter.com/sso",
  RAYGUN_TOKEN: "C5giNPucmWrJX9vuCgqRqBfO3iPjQ2g5",
  RAYGUN_APP_ID: "16cp9eo",
  IOS_APP_URL: "https://apps.apple.com/us/app/cariloop/id1450405352",
  ANDROID_APP_URL:
    "https://play.google.com/store/apps/details?id=com.cariloop.member&pli=1",
  mixpanelToken: "e10835c8c123cdfa8e1c525ebd3a93c9",
  TOS_LAST_MODIFIED_DATE: "2023-12-15T06:00:00.000Z",
  version: "10.8.4",
  SENTRY_DSN:
    "https://862da2ee3b31ac059d4b3f28c236980f@o4507579381514240.ingest.us.sentry.io/4507580535406592",
};
