import { BooleanInput } from "@angular/cdk/coercion";
import { NgClass, NgIf } from "@angular/common";
import { Component, Input, OnInit, Optional, Self } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NgControl,
  ValidatorFn,
} from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ProvisioningPasswordComplexity } from "app/shared/services/provisioning.service";

@Component({
  selector: "app-password-field",
  templateUrl: "./password-field.component.html",
  styleUrls: ["./password-field.component.scss"],
  standalone: true,
  imports: [FormsModule, NgIf, NgClass, TranslateModule],
})
export class PasswordFieldComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() name: string = undefined;
  @Input() hint: string;
  @Input() requiredHint: string;
  @Input() required: BooleanInput;
  @Input() placeholder: string = "";
  @Input() autocomplete: string = "new-password";

  private _passwordComplexity: ProvisioningPasswordComplexity = {
    capitalLetters: false,
    commonPasswords: false,
    length: 8,
    numbers: false,
    symbols: false,
  };

  @Input("passwordComplexity")
  get passwordComplexity(): any {
    return this._passwordComplexity;
  }
  set passwordComplexity(value: any) {
    this._passwordComplexity = value || {
      capitalLetters: false,
      commonPasswords: false,
      length: 8,
      numbers: false,
      symbols: false,
    };
  }

  control: AbstractControl;
  disabled: boolean = false;

  passwordState = {
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false,
    hasNumber: false,
    isLengthValid: false,
    isNotCommonPass: false,
  };

  password: string;
  isPasswordVisible = false;

  private onChange: any = () => {};
  onTouched: any = () => {};

  constructor(@Self() @Optional() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    this.control = this.ngControl.control;
    const validators = this.control.validator
      ? [this.control.validator, this.customValidator()]
      : [this.customValidator()];
    this.control.setValidators(validators);
    this.control.updateValueAndValidity();
  }

  changePassword(input: string) {
    this.password = input;
    this.validatePassword();
    this.onChange(input);
    this.onTouched();
  }

  writeValue(value: any): void {
    this.password = value;
    this.validatePassword();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  tooglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
    this.validatePassword();
    this.onChange(this.password);
  }

  private validatePassword() {
    this.passwordState.hasUpperCase = this.passwordComplexity.capitalLetters
      ? /[A-Z]/.test(this.password ?? "")
      : true;
    this.passwordState.hasLowerCase = this.passwordComplexity.capitalLetters
      ? /[a-z]/.test(this.password ?? "")
      : true;
    this.passwordState.hasSpecialChar = this.passwordComplexity.symbols
      ? /["|\\/~^:,;?!&%$@*+"]/.test(this.password ?? "")
      : true;
    this.passwordState.hasNumber = this.passwordComplexity.numbers
      ? /\d/.test(this.password ?? "")
      : true;
    this.passwordState.isLengthValid =
      (this.password ?? "").length >= this.passwordComplexity.length;
  }

  customValidator(): ValidatorFn {
    return (_control: AbstractControl): { [key: string]: any } | null => {
      return this.passwordState.hasUpperCase &&
        this.passwordState.hasLowerCase &&
        this.passwordState.hasSpecialChar &&
        this.passwordState.hasNumber &&
        this.passwordState.isLengthValid
        ? null
        : { passwordInvalid: true };
    };
  }

  shouldShowError(): boolean {
    return !!(
      this.control?.errors &&
      (Object.keys(this.control.errors).length > 1 ||
        !this.control.errors.passwordInvalid) &&
      this.control.touched
    );
  }
}
