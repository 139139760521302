import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountRegistrationService } from "app/shared/services/account-registration.service";
import { ToastState } from "@cariloop/ui-components";
import { AccountService } from "app/shared/services/account.service";
import {
  ToastProperties,
  ToastService,
} from "app/shared/services/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { Account } from "app/shared/types/account.type";
import { AnalyticsService } from "@cariloop/analytics";
import {
  DevicePlatformService,
  MixPanelPlatform,
} from "app/shared/services/device-platform.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-email-verification",
  templateUrl: "./email-verification.component.html",
  styleUrls: ["./email-verification.component.scss"],
})
export class EmailVerificationComponent implements OnInit {
  @Input() isExistingPath: boolean = false;
  @Output() nextStep = new EventEmitter<void>();
  isLoading = false;
  toastProperties: ToastProperties = {
    toastMessage: "Verification code re-sent",
    showToast: false,
    toastType: ToastState.success,
  };

  form: FormGroup = new FormGroup({
    code: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });

  error: "generic" | "invalid-code" | "system-error";
  account: Account;
  platform: MixPanelPlatform;

  get code() {
    return this.form.get("code");
  }

  constructor(
    private accountService: AccountService,
    private accountRegistrationService: AccountRegistrationService,
    public toastService: ToastService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService,
    private devicePlatformService: DevicePlatformService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.toastProperties.toastMessage = this.translate.instant(
      "REGISTER.VERIFY_EMAIL.RESEND.TOAST",
    );
    this.form.get("code").valueChanges.subscribe((val) => {
      if (val.length > 6) {
        this.form.patchValue({
          code: val.slice(0, 6),
        });
      }
    });

    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }
    this.account = account;

    this.platform = this.devicePlatformService.getPlatformForMixpanel();

    const isReturning = this.route.snapshot.routeConfig.path === "complete";
    this.analyticsService.trackEvent("email-verification", {
      platform: this.platform,
      source: "auth",
      accountId: this.account.id,
      ...(isReturning && { userFlow: "update-existing-account" }),
      ...(!isReturning && { userFlow: "create-new-account" }),
    });
  }

  verifyEmail() {
    this.error = undefined;
    const { code } = this.form.value;
    this.isLoading = true;

    this.accountRegistrationService
      .verifyAccount(this.account.id, code)
      .subscribe(
        (res) => {
          console.log(res);
          this.isLoading = false;

          const isReturning =
            this.route.snapshot.routeConfig.path === "complete";
          this.analyticsService.trackEvent("email-verification-complete", {
            platform: this.platform,
            source: "auth",
            accountId: this.account.id,
            ...(isReturning && { userFlow: "update-existing-account" }),
            ...(!isReturning && { userFlow: "create-new-account" }),
          });

          this.nextStep.next();
        },
        (err) => {
          this.isLoading = false;
          if (err.status == 404) {
            this.error = "invalid-code";
          } else if (err.status == 504) {
            this.error = "system-error";
          } else {
            this.error = "generic";
          }
          console.log(err);
        },
      );
  }

  resendCode() {
    const account = this.accountService.getLogged();
    if (account instanceof Error) {
      throw account;
    }

    this.accountRegistrationService
      .resendVerificationCode(account.id)
      .subscribe(
        (res) => {
          this.toastProperties.showToast = true;
          setTimeout(() => (this.toastProperties.showToast = false), 5000);
        },
        (err) => {
          console.log(err);
        },
      );
  }
}
