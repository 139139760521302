<c-cta-mobile
  environment="dev"
  [messages]="{
    qrText: 'LOGIN.CTA_MOBILE.QR_TEXT' | translate,
    paragraph: 'LOGIN.CTA_MOBILE.DESCRIPTION' | translate
  }"
  [lang]="lang"
  (onClick)="downloadAppClicked($event)"
>
  <c-cta-heading
    cta-heading
    [level]="3"
    [text]="'LOGIN.CTA_MOBILE.TITLE' | translate"
  ></c-cta-heading>
</c-cta-mobile>
