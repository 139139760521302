import { NgModule } from '@angular/core';
import { TopNavComponent } from './top-nav.component';
import { CommonModule } from '@angular/common';
import { AccountService } from '../shared/services/account.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangDropdownComponent, UIComponentsModule } from '@cariloop/ui-components';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    UIComponentsModule

  ],
  declarations: [TopNavComponent],
  exports: [TopNavComponent],
  providers: [AccountService]
})
export class TopNavModule {
}
