import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class CountdownComponent implements OnInit {
  @Input() limit: Date;
  @Output() onEnd = new EventEmitter();
  label = '';
  emited = false;

  constructor() { }

  ngOnInit(): void {
    this.update();
  }

  update = () => {
    if (!this.limit) {
      this.label = '';
      return;
    }
    const now = new Date();
    const difference = Math.round((this.limit.getTime() - now.getTime()) / 1000);
    if (difference <= 0) {
      if (!this.emited) {
        this.emited = true;
        this.onEnd.emit();
      }
      this.label = '';
      return;
    }
    const minutes = Math.floor(difference / 60);
    const seconds = difference % 60;
    const zero = seconds < 10 ? '0' : '';
    this.label = `${minutes}:${zero}${seconds}`;
    setTimeout(
      () => this.update(),
      998
    );
  }
}
