import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../environments/environment';

@Injectable()
export class AuthRedirect  {
    constructor(private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.router.navigate(['/login'], {queryParams: {redirectUrl: state.url}});
        return false;
    }
}
