<app-black-container>
<div class="custom complete-register" *ngIf="!isLinkExpired">
  <div class="title-header full-width">
    <h6 class="header-md-medium header mat-h6">{{ 'COMPLETE_REGISTRATION.TITLE' | translate }}</h6>
  </div>
  <app-md-error-handler [error]="error"></app-md-error-handler>
  <form (ngSubmit)="onSubmit()" #form="ngForm" appPasswordMatch autocomplete="off">
    <div class="input-container">
      <mat-form-field appearance="outline" value="null" class="form-field full-width" color="accent">
        <mat-label>{{ 'COMPLETE_REGISTRATION.NAME' | translate }}</mat-label>
        <input
        appearance="outline"
        name="name"
        matInput
        autocomplete="name"
        [(ngModel)]="name"
        type="text"
        required>
        <mat-error *ngIf="!name">
        {{ 'FORM_ERRORS.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" value="null" class="form-field full-width" color="accent">
        <mat-label>{{ 'COMPLETE_REGISTRATION.PHONE' | translate }}</mat-label>
        <input
        appearance="outline"
        name="phoneNumber"
        matInput
        [(ngModel)]="model.phone"
        autocomplete="new-phone"
        type="text"
        required>
        <mat-error *ngIf="!model.phone">
        {{ 'FORM_ERRORS.REQUIRED_FIELD' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field
        appearance="outline"
        value="null"
        class="form-field full-width"
        color="accent"
        [ngClass]="{'textError': passwordRef && passwordRef.control.hasError('invalidPassword') && passwordRef.control.touched}"
      >
        <mat-label>{{ 'COMPLETE_REGISTRATION.PASSWORD' | translate }}</mat-label>
        <input
        appearance="outline"
        autocomplete="new-password"
        name="password"
        [(ngModel)]="model.password"
        type="password"
        #passwordRef="ngModel"
        matInput
        required
        appPasswordValidation
        [capitalLetters]="model?.provisioningProfile?.passwordComplexity?.value.capitalLetters"
        [commonPasswords]="model?.provisioningProfile?.passwordComplexity?.value.commonPasswords"
        [numbers]="model?.provisioningProfile?.passwordComplexity?.value.numbers"
        [symbols]="model?.provisioningProfile?.passwordComplexity?.value.symbols"
        [length]="model?.provisioningProfile?.passwordComplexity?.value.length"
      >
        <mat-error *ngIf="passwordRef?.errors?.required">
          {{ 'FORM_ERRORS.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="passwordRef && passwordRef.errors?.invalidPassword">
          {{ passwordRef.getError('textError') }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="input-container">
      <mat-form-field appearance="outline" value="null" class="form-field full-width" color="accent">
        <mat-label>{{ 'COMPLETE_REGISTRATION.PASSWORD_CONFIRMATION' | translate }}</mat-label>
        <input
        appearance="outline"
        autocomplete="new-password-confirmation"
        id="passwordConfirmation"
        name="passwordConfirmation"
        matInput
        [(ngModel)]="confirmation"
        #confirmationRef="ngModel"
        [errorStateMatcher]="errorMatcher"
        type="password"
        required>
        <mat-error *ngIf="confirmationRef?.errors?.required">
          {{ 'FORM_ERRORS.REQUIRED_FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="confirmation && form?.errors?.passwordsDoNotMatch">
          {{ 'FORM_ERRORS.PASSWORDS_DO_NOT_MATCH' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="buttons-container">
      <button
          mat-raised-button
          [attr.aria-label]="'COMPLETE_REGISTRATION.SIGN_UP_ARIA' | translate"
          [disabled]="!form.valid"
          color="primary"
          type="submit">
            {{ 'COMPLETE_REGISTRATION.SIGN_UP' | translate }}
      </button>
      <button
          mat-raised-button
          [attr.aria-label]="'COMPLETE_REGISTRATION.CANCEL_ARIA' | translate"
          color="accent"
          type="button">
            {{ 'COMPLETE_REGISTRATION.CANCEL' | translate }}
      </button>
    </div>

    <div class="input-container">
      <mat-checkbox name="terms" required [(ngModel)]="termsAccepted" #termsAcceptedRef="ngModel">
        <span class="mat-caption terms-of-service" [innerHTML]="'GENERAL.TERMS' | translate"></span>
      </mat-checkbox>
    </div>
  </form>
</div>

<div class="custom expired-link complete-register" *ngIf="isLinkExpired">
  <h6 class="mat-h6 expired-title">{{ 'LINK_EXPIRED.TITLE' | translate }}</h6>
  <p class="description first mat-body-2">
    {{ 'LINK_EXPIRED.TEXT1' | translate }}
    <span *ngIf="!ownerName">{{ 'LINK_EXPIRED.TEXT2_NO_OWNER' | translate }}</span>
    <span *ngIf="ownerName">{{ 'LINK_EXPIRED.TEXT2' | translate : {ownerName: ownerName} }}</span>
  </p>
  <div class="center-button">
    <button
          mat-raised-button
          routerLink="/login"
          color="accent"
          type="button"
          class="full-width">
          {{ 'LINK_EXPIRED.MAIL_TO' | translate }}
      </button>
  </div>
</div>
</app-black-container>
