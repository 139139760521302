import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "app/../environments/environment";
import { AccountService } from "./account.service";
import { HttpHeaders } from "@angular/common/http";
import { BaseModelService } from "./base-model.service";
import { map, switchMap } from "rxjs/operators";
import { ProvisioningService } from "./provisioning.service";
import { of } from "rxjs";

@Injectable()
export class CaseService {
  private headers: HttpHeaders = new HttpHeaders();

  /**
   * Constructor
   */
  constructor(
    public http: BaseModelService,
    private accountService: AccountService,
    private provisioningService: ProvisioningService,
  ) {
    this.headers = this.headers.append("Accept", "application/json");
    this.headers = this.headers.append("Content-Type", "application/json");
  }

  public getCaseIdFromPost(postId: string): Observable<string> {
    const url = `${environment.BASE_URL}/posts/${postId}`;
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    const stages = [map((post: { caseId: string }) => post.caseId)];
    return this.http.get(url, { headers: this.headers }, stages);
  }
  getCaseHomeUrl(): Observable<string> {
    const user = this.accountService.getLogged();
    if (user instanceof Error) {
      return of(environment.AUTH_URL);
    }
    return this.accountService.getLastCaseVisited(user.id).pipe(
      switchMap((data) => {
        if (data?.props?.pathName) {
          return of(`${environment.PLAN_URL}${data.props.pathName}/home`);
        }
        return of(`${environment.PLAN_URL}/onboarding`);
      }),
    );
  }

  getCreateCaseUrl() {
    return of(`${environment.PLAN_URL}/onboarding`);
  }
}
