import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "app/../environments/environment";
import { Observable } from "rxjs";
import { CompleteRegisterStep } from "../types/complete-register-step.type";
import { AccountService } from "./account.service";
import { BaseModelService } from "./base-model.service";

@Injectable()
export class AccountRegistrationService {
  private baseUrl = "";
  private headers: HttpHeaders = new HttpHeaders();

  constructor(
    public http: BaseModelService,
    private accountService: AccountService,
  ) {
    this.headers = this.headers.append("Accept", "application/json");
    this.headers = this.headers.append("Content-Type", "application/json");
    this.baseUrl = environment.BASE_URL + "accounts";
  }

  public createAccount(data: object): Observable<unknown> {
    const url = `${this.baseUrl}/register`;
    return this.http.post(url, data, { headers: this.headers });
  }

  public resendVerificationCode(id: string): Observable<{ success: boolean }> {
    const url = `${this.baseUrl}/${id}/resend-verify`;
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    return this.http.post(url, {}, { headers: this.headers });
  }

  public verifyAccount(
    id: string,
    code: string,
  ): Observable<{ success: boolean }> {
    const url = `${this.baseUrl}/${id}/verify`;
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    return this.http.post(
      url,
      {
        code,
      },
      { headers: this.headers },
    );
  }

  public missingSteps(id: string): Observable<CompleteRegisterStep[]> {
    const url = `${this.baseUrl}/${id}/missing-steps`;
    this.headers = this.headers.set(
      "Authorization",
      String(this.accountService.getToken()),
    );
    return this.http.post(url, {}, { headers: this.headers });
  }
}
