import { Platform } from "@angular/cdk/platform";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from "@angular/core";
import { AnalyticsService } from "@cariloop/analytics";
import { DeviceType } from "app/shared/services/device-platform.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-mobile-app-cta",
  templateUrl: "./mobile-app-cta.component.html",
  styleUrls: ["./mobile-app-cta.component.scss"],
})
export class MobileAppCtaComponent implements AfterViewInit {
  readonly #analytics = inject(AnalyticsService);
  readonly #platform = inject(Platform);

  @Input({ required: true }) device: DeviceType;
  @Input({ required: true }) isMobileDevice: boolean;
  @Output() shouldShowCTA = new EventEmitter<boolean>();

  public isPopupDismissed = false;
  public startTime = Date.now();
  public elapsedTime = 0;

  ngAfterViewInit(): void {
    document.querySelector("app").classList.add("no-scroll");
  }

  onContinueInBrowser() {
    this.elapsedTime += Date.now() - this.startTime;
    this.sendMixpanelEvent("mobile_app_cta_dismissed", {
      timeTakenToInteract: this.convertElapsedTime(),
    });
    this.isPopupDismissed = true;
    document.querySelector("app").classList.remove("no-scroll");
    this.shouldShowCTA.emit(false);
  }

  onUseApp() {
    this.isPopupDismissed = true;
    document.querySelector("app").classList.remove("no-scroll");
    this.elapsedTime += Date.now() - this.startTime;
    this.sendMixpanelEvent("mobile_app_cta_use_app", {
      timeTakenToInteract: this.convertElapsedTime(),
    });

    switch (this.device) {
      case "ios":
        this.redirectToApp(environment.IOS_APP_URL);
        break;
      case "android":
        this.redirectToApp(environment.ANDROID_APP_URL);
        break;
      default:
        break;
    }

    this.shouldShowCTA.emit(false);
  }

  redirectToApp(appStoreUrl: string) {
    if (!this.#platform.SAFARI) {
      let isBlurredOnRedirect = false;
      const updateBlur = () => (isBlurredOnRedirect = true);
      window.addEventListener("blur", updateBlur);

      setTimeout(() => {
        window.removeEventListener("blur", updateBlur);
        if (isBlurredOnRedirect) {
          return;
        }
        window.open(appStoreUrl, "_self");
      }, 500);
      window.open("cariloop://", "_self");
    } else {
      window.open(appStoreUrl, "_self");
    }
  }

  sendMixpanelEvent(eventType: string, properties?: object) {
    this.#analytics.trackEvent(eventType, {
      timestamp: new Date().toISOString(),
      deviceType: this.device,
      source: "auth",
      ...properties,
    });
  }

  convertElapsedTime() {
    let totalSeconds = this.elapsedTime / 1000;

    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds }`;
  }
}
