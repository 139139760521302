import { inject } from '@angular/core';
import { type CanActivateFn, Router } from '@angular/router';
import { AccountService } from 'app/shared/services/account.service';

export const flutterExistingRegistrationWebViewGuard: CanActivateFn = (
  route,
) => {
  const flutterDataExistingAccountRegistration = route.queryParamMap.get(
    'flutterDataExistingAccountRegistration',
  );
  if (flutterDataExistingAccountRegistration === null) {
    return true;
  }

  const accountService = inject(AccountService);
  const { email, password }: { email: string; password: string } = JSON.parse(
    decodeURIComponent(flutterDataExistingAccountRegistration),
  );
  const router = inject(Router);

  accountService
    .login({
      email,
      password,
    })
    .subscribe({
      next: () => {
        router.navigate(['/register/complete']);
      },
    });

  return false;
};
