<div class="black-main-container">
  <div
    class="black-container"
    style="background-image: url(&quot;assets/img/login-background.png&quot;)"
  >
    <div class="text-container">
      <img
        class="cariloop-logo"
        src="assets/img/cariloop-logo.svg"
        [alt]="'GENERAL.CARILOOP_LOGO_ALT' | translate"
      />
      <h1 class="welcome-text login-title">
        {{ "APP.WELCOME" | translate }}
      </h1>
      <p style="white-space: break-spaces" class="welcome-text login-subtitle">
        {{ "APP.WELCOME_SUB_HEADER" | translate }}
      </p>
    </div>
  </div>
  <div class="ng-container">
    <top-nav></top-nav>
    <main id="main">
      <ng-content></ng-content>
    </main>
  </div>
</div>
