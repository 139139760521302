import { Injectable } from '@angular/core';
import { environment } from 'app/../environments/environment';

declare const gtag: any;

@Injectable()
export class GoogleAnalyticsService {

  constructor() { }

  pageView(path: string): void {
    let prefix = '';
    if (path !== '') {
      prefix = '/';
    }
    gtag('config', environment.gaId, {'page_path': prefix+path});
  }

  sendEvent(event: string, params?: {[name:string]: any}): void {
    if (params) {
      gtag('event', event, params);
    } else {
      gtag('event',event);
    }
  }
}
