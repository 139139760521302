import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppTranslatePipe } from './app-translate.pipe';

@NgModule({
    declarations: [
        AppTranslatePipe
    ],
    exports: [
        AppTranslatePipe
    ],
    imports: [
        TranslateModule.forChild()
    ]
})
export class PipesModule {}
