import { ValidatorFn, FormGroup, ValidationErrors, NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { INVALID_PASSWORDS } from '../../shared/constants/invalid-passwords.constant';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appPasswordValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidationDirective, multi: true }]
})

export class PasswordValidationDirective implements Validator {
  @Input() capitalLetters = false;
  @Input() commonPasswords = false;
  @Input() numbers = false;
  @Input() symbols = false;
  @Input() length = 8;

  constructor(
    private translateService: TranslateService
  ) {}

  validate(control: AbstractControl): ValidationErrors {
    if (!control || !control.value) {
      return null;
    }
    let isValid = true;
    let error = this.translateService.instant('PASSWORD_VALIDATOR.TITLE');
    if (this.commonPasswords && INVALID_PASSWORDS[control.value]) {
      isValid = false;
      error += `, ${this.translateService.instant('PASSWORD_VALIDATOR.COMMON')}`;
    }
    if (this.length && control.value.length < this.length) {
      error += `,${isValid ? ' ' + this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR') : ''} ${this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR_2')} ${this.length} ${this.translateService.instant('PASSWORD_VALIDATOR.LENGTH')}`;
      isValid = false;
    }
    if (this.capitalLetters && !/[A-Z]/.test(control.value)) {
      error += `,${isValid ? ' ' + this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR') : ''} ${this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR_2')} ${this.translateService.instant('PASSWORD_VALIDATOR.CAPITAL_LETTER')}`;
      isValid = false;
    }
    if (this.numbers && !/[0-9]/.test(control.value)) {
      error += `,${isValid ? ' ' + this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR') : ''} ${this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR_2')} ${this.translateService.instant('PASSWORD_VALIDATOR.NUMBERS')}`;
      isValid = false;
    }
    if (this.symbols && !/["|\\/~^:,;?!&%$@*+"]/.test(control.value)) {
      error += `,${isValid ? ' ' + this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR') : ''} ${this.translateService.instant('PASSWORD_VALIDATOR.AUXILIAR_2')} ${this.translateService.instant('PASSWORD_VALIDATOR.SYMBOLS')}`;
      isValid = false;
    }
    return isValid ? null : { invalidPassword: true, textError: error };
  }
}

