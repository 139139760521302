import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BASE_HREF, NgOptimizedImage } from "@angular/common";

/*
 * Platform and Environment providers/directives/pipes
 */

import { AppRouteModule } from "./app.routes";
// App is our top level component
import { AppComponent } from "./app.component";
import { TopNavModule } from "./top-nav/top-nav.module";
import { AccessModule } from "./access/access.module";
import { WidgetsModule } from "./widgets/widgets.module";
import { AppState } from "./app.service";
import { AccountService } from "./shared/services/account.service";
import { AccountCasesService } from "./shared/services/account-cases.service";
import { EventsService } from "./shared/services/events.service";
import { CaseService } from "./shared/services/case.service";
import { environment } from "../environments/environment";

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpLoaderFactory } from "./TranslateLoader";
import { AppTranslateService } from "./shared/services/app-translate.service";
import { UrlNotFoundComponent } from "./url-not-found/url-not-found.component";
import { AuthRedirect } from "./authRedirect";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";

import { OrganziationService } from "./shared/services/organization.service";
import { DisconnectionModule } from "./disconnection/disconnection.module";
import { NgxAnalyticsModule } from "@cariloop/analytics";
import { SignCallbackComponent } from "./sign-callback/sign-callback.component";
import { LoadingStateComponent } from "./loading-state/loading-state.component";
import { AccountRegistrationService } from "./shared/services/account-registration.service";
import { MatIconModule } from "@angular/material/icon";
import { GiftService } from "./shared/services/gift.service";
import { UIComponentsModule } from "@cariloop/ui-components";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

// Application wide providers
const APP_PROVIDERS: any = [
  AuthRedirect,
  AppState,
  AccountService,
  EventsService,
  AccountCasesService,
  AppTranslateService,
  CaseService,
  GoogleAnalyticsService,
  OrganziationService,
  AccountRegistrationService,
  { provide: APP_BASE_HREF, useValue: "/" },
  GiftService,
];
// Add custom error handler provider if the env is not local
if (["stage", "production", "develop"].includes(environment.envName)) {
  APP_PROVIDERS.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  );
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    UrlNotFoundComponent,
    SignCallbackComponent,
    LoadingStateComponent,
  ],
  imports: [
    // import Angular's modules
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    AppRouteModule,
    BrowserAnimationsModule,
    TopNavModule,
    AccessModule,
    WidgetsModule,
    DisconnectionModule,
    NgxAnalyticsModule.forRoot(
      {
        userId: "",
        appBasePath: "/auth",
        debug: environment.envName !== "production",
        google: {
          trackingCode: environment.gaId,
        },
        mixpanel: {
          token: environment.mixpanelToken,
        },
      },
      environment,
    ),
    MatIconModule,
    UIComponentsModule,
    NgOptimizedImage,
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
  ],
})
export class AppModule {
  constructor() {}
}
