import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class PasswordConfirmationErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const passwordsDoNotMatch = form && form.errors && form.errors.passwordsDoNotMatch;
    const isEmpty = control ? !control.value : true;
    return !!(control && (control.dirty || control.touched) && (isEmpty || passwordsDoNotMatch));
  }
}
