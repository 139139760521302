<div class="main-container">
  <section class="registration-header-top-row" *ngIf="!isMobileWebView">
    <img
      alt="Cariloop Logo"
      class="registration-header-cariloop-logo"
      height="32"
      src="assets/icon/Cariloop-Logo-Black.svg"
      width="109"
    />
    <lib-lang-dropdown
      (itemSelectedChange)="changeLanguage($event.value)"
      [itemSelected]="
        browserLanguage ? browserLanguage : { value: 'en', label: 'English' }
      "
    />
    <ng-content select="[slot-logo-or-counter]" />
  </section>
  <section class="sso-error-content">
    <h1>An error has occurred</h1>
    <div class="banner">
      <p class="text">
        <b style="font-weight: 600">
          We were unable to find your account at this time. Please try again
          soon.
        </b>
        if you still have issues, please contact your HR representative or
        benefits provider
      </p>
    </div>
  </section>
</div>

<!-- https://local-plan.cariloop.com/register/sso-error -->
