import { Component, OnInit } from "@angular/core";
import { AppStateType } from "../shared/types/app-state-type";
import { AccountService } from "../shared/services/account.service";
import { AppState } from "../app.service";
import { Location } from "@angular/common";

@Component({
  selector: "app-disconnection",
  templateUrl: "./disconnection.component.html",
  styleUrls: ["./disconnection.component.scss"],
})
export class DisconnectionComponent implements OnInit {
  public disconnectionInterval = null;
  public timerCounter = 0;

  constructor(
    private appService: AppState,
    private accountService: AccountService,
    private location: Location,
  ) {}

  ngOnInit() {
    const appState: AppStateType = {
      authenticated: false,
      path: {
        root: "disconnection",
      },
    };
    this.appService.appStateUpdate(appState);
    this.confirmServerStatus();
  }

  confirmServerStatus(): void {
    if (this.disconnectionInterval) {
      return;
    }
    this.disconnectionInterval = setInterval(() => {
      this.timerCounter += 1;
      if (this.timerCounter % 10 === 0) {
        this.checkServerStatus();
      }
    }, 1000);
  }

  checkServerStatus(): void {
    this.timerCounter = 0;
    this.accountService.getHeartbeat().subscribe(
      (res) => {
        this.clearDisconnectionTimeout();
      },
      (err) => {
        if (err.status === 0 || err.status >= 503) {
          console.log("No connection");
        } else {
          this.clearDisconnectionTimeout();
        }
      },
    );
  }

  clearDisconnectionTimeout(): void {
    if (this.disconnectionInterval) {
      clearTimeout(this.disconnectionInterval);
    }
    this.appService.status();
    this.location.back();
  }
}
