import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n', 'json');
}

class TranslateHttpLoader implements TranslateLoader {
  private http: HttpClient;
  prefix: string;
  suffix: string;

  constructor(http: HttpClient, prefix?: string, suffix?: string) {
    this.http = http;
    this.prefix = prefix;
    this.suffix = suffix;
  }

  getTranslation(lang: string) {
    const excludeEnv = ['local', 'alpha', 'beta', 'tnt'];
    const version = !excludeEnv.includes(environment.envName) ? `/${environment.version}` : '';
    const url = `${environment.AUTH_URL}${version}/${this.prefix}/${lang}.${this.suffix}`;
    return this.http.get(url).pipe(
      catchError(err => {
        console.log(err);
        return of({});
      })
    )
  };
}

