import { CanActivateFn, Router } from "@angular/router";
import { AccountService } from "../services/account.service";
import { inject } from "@angular/core";
import { map, tap } from "rxjs/operators";

export const inactiveEmployerGuard: CanActivateFn = (route, state) => {
  const accountService = inject(AccountService);
  const router = inject(Router);

  const currentDate = new Date();
  const account = accountService.getLogged();

  if (account instanceof Error) {
    router.navigate(["/login"]);
    return false;
  }

  return accountService.getAccountOrg(account.id).pipe(
    map((org) => {
      const contractStartDate = new Date(org?.contractStartDate);
      return (
        org?.contractStartDate !== undefined && contractStartDate > currentDate
      );
    }),
    tap((isContractInFuture) => {
      if (!isContractInFuture) {
        router.navigate(["/login"]);
      }
    }),
  );
};
