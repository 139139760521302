import { Component, OnInit } from "@angular/core";
import { Account } from "../../shared/types/account.type";
import { Credential } from "../../shared/types/credential.type";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "../../shared/services/account.service";
import { AppTranslateService } from "../../shared/services/app-translate.service";
import { environment } from "environments/environment";
import { INVALID_PASSWORDS } from "app/shared/constants/invalid-passwords.constant";
import { PasswordConfirmationErrorMatcher } from "app/shared/error-matchers/password-confirmation.matcher";
import { ErrorStateMatcher } from "@angular/material/core";
import { ErrorReportingService } from "app/shared/services/error-reporting.service";

@Component({
  selector: "complete-register",
  templateUrl: "./complete-register.html",
  styleUrls: ["./complete-register.scss"],
})
export class CompleteRegisterComponent implements OnInit {
  public model: Account = new Account();
  public credential: Credential = new Credential();
  public name = "";
  public errorMatcher: ErrorStateMatcher;
  public code: string;
  public caseId: string;
  public confirmation: string;
  public error: any;
  public isPasswordValid = false;
  public isLoading = false;
  public isLinkExpired = false;
  public ownerName = "";
  private userHasError = false;
  public termsAccepted = false;

  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private translate: AppTranslateService,
    private errorReportingService: ErrorReportingService,
    private router: Router,
  ) {
    this.errorMatcher = new PasswordConfirmationErrorMatcher();
  }

  public ngOnInit() {
    this.route.params.subscribe((params) => {
      this.caseId = params["id"];
      this.code = params["code"];
      this.getUser();
    });
  }

  public getUser() {
    const redirectUrl = encodeURIComponent(`/case/${this.caseId}`);
    if (!this.code && this.caseId) {
      this.router.navigate(["login"], { queryParams: { redirectUrl } });
      return;
    }
    this.accountService.getAccountByLoginCode(this.code).subscribe(
      (res: any) => {
        if (res && res.id) {
          this.model = res;
          this.setUserLanguage(res.language);
        } else {
          if (this.route.snapshot.queryParamMap.get("displayText")) {
            this.ownerName = window.atob(
              this.route.snapshot.queryParamMap.get("displayText"),
            );
          }
          this.isLinkExpired = true;
        }
      },
      () => {
        this.userHasError = true;
        this.router.navigate(["login"], { queryParams: { redirectUrl } });
      },
    );
  }

  public onSubmit(): void {
    if (this.userHasError) {
      const redirectUrl = encodeURIComponent(`/case/${this.caseId}`);
      this.router.navigate(["login"], { queryParams: { redirectUrl } });
      return;
    }
    const { provisioningProfile, orgHistory, ...model } = this.model;
    const [firstName, ...lastName] = this.name.trim().split(" ");
    model.firstName = firstName;
    if (lastName && lastName.length) {
      model.lastName = lastName.join(" ");
    }
    this.isLoading = true;
    this.error = "";
    model.email = model.email?.trim() ?? "";
    model.invited = false;
    model.state = "active";
    model.termsAcceptanceDate = new Date().toISOString();
    this.accountService.completeRegisterByCode(this.code, model).subscribe(
      () => this.doLogin(),
      (err) => this.errorHandler(err),
    );
  }

  public doLogin() {
    this.credential = {
      email: this.model.email,
      password: this.model.password,
    };
    this.accountService.login(this.credential).subscribe(() => {
      window.location.href = this.accountService.getHomeUrl();
    });
  }

  public validatePassword(): boolean {
    const { password } = this.model;
    return (this.isPasswordValid = password
      ? !INVALID_PASSWORDS[password]
      : true);
  }

  private setUserLanguage(lang = "en"): void {
    this.translate.updateLang(lang);
  }

  private errorHandler(err: any): void {
    this.isLoading = false;
    this.error = err;
    if (err?.error?.error?.message !== "Invalid Password") {
      this.errorReportingService.send(err);
    }
  }
}
