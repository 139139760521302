<app-toast
  [message]="toastProperties.toastMessage | translate"
  [showsToast]="toastProperties.showToast"
  [state]="toastProperties.toastType"
  toastPosition="right"
  toastVerticalPosition="top"
></app-toast>
<div class="title-container full-width">
  <ng-container *ngIf="screen === 'mfa'" [ngSwitch]="mfaType">
    <h6 class="mat-h6" *ngSwitchCase="'sms'">
      {{ "MFA.TITLE" | translate }}
    </h6>
    <h6 class="mat-h6" *ngSwitchCase="'email'">
      {{ "MFA.EMAIL.TITLE" | translate }}
    </h6>
  </ng-container>
  <h6 class="mat-h6" *ngIf="screen === 'mfaError'">
    {{ mfaErrorLabel | translate }}
  </h6>
</div>
<app-md-error-handler [error]="error"></app-md-error-handler>
<form
  autocomplete="off"
  (ngSubmit)="onSubmitMfa()"
  #mfaForm="ngForm"
  class="login-form"
  *ngIf="screen === 'mfa'"
>
  <div class="input-container">
    <ng-container [ngSwitch]="mfaType">
      <span *ngSwitchCase="'sms'">{{ "MFA.HINT" | translate }}</span>
      <span *ngSwitchCase="'email'">{{ "MFA.EMAIL.HINT" | translate }}</span>
    </ng-container>
    <br />
    &nbsp;
  </div>
  <div class="input-container">
    <mat-form-field
      appearance="outline"
      value="null"
      class="full-width"
      color="accent"
    >
      <mat-label>{{ "MFA.CODE" | translate }}</mat-label>
      <input
        type="text"
        matInput
        [attr.aria-label]="'MFA.CODE' | translate"
        [(ngModel)]="mfaCode"
        maxlength="6"
        name="mfaCode"
      />
    </mat-form-field>
  </div>
  <div class="input-container">
    <mat-checkbox name="mfaTrustDevice" [(ngModel)]="mfaTrustDevice">
      {{ "MFA.REMEMBER_DEVICE" | translate }}
    </mat-checkbox>
    <br />
    &nbsp;
  </div>
  <div class="forgot-password-container full-width">
    <button
      mat-raised-button
      class="full-width sign-in-button"
      [attr.aria-label]="'MFA.CONTINUE' | translate"
      [disabled]="this.mfaCode.length !== 6 || !mfaIsValid"
      color="primary"
    >
      {{ "MFA.CONTINUE" | translate }}
    </button>
  </div>
  <div class="forgot-password-container full-width">
    <button
      mat-button
      color="accent"
      class="full-width sign-in-button"
      [attr.aria-label]="'MFA.CANCEL' | translate"
      (click)="goBackToLogin('cancelPressed'); (false)"
    >
      {{ "MFA.CANCEL" | translate }}
    </button>
  </div>
  <ng-container *ngIf="mfaType === 'sms'; else emailMFAResendText">
    <div class="input-container" style="text-align: center">
      &nbsp;
      <br />
      <span>{{ "MFA.DID_NOT_RECEIVE" | translate }}</span>
      <br />
      &nbsp;
    </div>
    <div class="forgot-password-container full-width">
      <button
        mat-button
        color="accent"
        class="full-width sign-in-button"
        [attr.aria-label]="'MFA.REQUEST_NEW' | translate"
        (click)="requestNewCode(); (false)"
      >
        {{ "MFA.REQUEST_NEW" | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #emailMFAResendText>
    <div *ngIf="mfaType === 'email'" class="resend">
      {{ "MFA.EMAIL.RESEND.TEXT" | translate }}
      <span class="link" (click)="requestNewCode()">
        {{ "MFA.EMAIL.RESEND.LINK_1" | translate }}
      </span>
      {{ "MFA.EMAIL.RESEND.OR" | translate }}
      <a class="link" href="mailto:support@cariloop.com">
        {{ "MFA.EMAIL.RESEND.LINK_2" | translate }}
      </a>
      <span>.</span>
    </div>
  </ng-template>
  <div class="input-container" style="text-align: center">
    &nbsp;
    <br />
    &nbsp;
    <br />
    {{ "MFA.CODE_EXPIRES_IN" | translate }}
    <app-countdown
      (onEnd)="screen = 'mfaError'; mfaErrorLabel = 'MFA.TIMEOUT_ERROR'"
      [limit]="mfaLimit()"
    ></app-countdown>
    <br />
    &nbsp;
  </div>
</form>
<form
  (ngSubmit)="onSubmitMfa()"
  #mfaForm="ngForm"
  class="mfa-form"
  *ngIf="screen === 'mfaError'"
>
  <div class="forgot-password-container full-width">
    <button
      mat-button
      color="accent"
      class="full-width sign-in-button"
      [attr.aria-label]="'MFA.BACK' | translate"
      (click)="screen = 'mfa'; goBackToLogin('cancelPressed')"
    >
      {{ "MFA.BACK" | translate }}
    </button>
  </div>
</form>
