import { Injectable } from '@angular/core';
import { ToastState } from '@cariloop/ui-components';
import { Subject } from 'rxjs';

export interface ToastProperties {
  toastMessage: string;
  showToast: boolean;
  toastType: ToastState;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private $subject = new Subject<ToastProperties>();

  constructor() { }

  listenToastChange() {
    return this.$subject.asObservable();
  }

  newToast(properties: ToastProperties) {
    this.$subject.next(properties);
  }
}