export const INVALID_PASSWORDS = {
  password: true,
  123456789: true,
  12345678: true,
  sunshine: true,
  iloveyou: true,
  princess: true,
  football: true,
  aa123456: true,
  password1: true,
  qwerty123: true,
  baseball: true,
  jennifer: true,
  trustno1: true,
  superman: true,
  michelle: true,
  corvette: true,
  computer: true,
  xxxxxxxx: true,
  starwars: true,
  maverick: true,
  hardcore: true,
  66666666: true,
  666666669: true,
  butthead: true,
  samantha: true,
  firebird: true,
  steelers: true,
  mercedes: true,
  bigdaddy: true,
  marlboro: true,
  srinivas: true,
  internet: true,
  11111111: true,
  whatever: true,
  nicholas: true,
  midnight: true,
  startrek: true,
  einstein: true,
  dolphins: true,
  victoria: true,
  redskins: true,
  access14: true,
  mountain: true,
  qwertyui: true,
  danielle: true,
  swimming: true,
  redwings: true,
  cocacola: true,
  rush2112: true,
  scorpion: true,
  mistress: true,
  snowball: true,
  alexande: true,
  passw0rd: true,
  lasvegas: true,
  slipknot: true,
  kimberly: true,
  '1q2w3e4r': true,
  carolina: true,
  colorado: true,
  creative: true,
  bollocks: true,
  darkness: true,
  asdfghjk: true,
  poohbear: true,
  nintendo: true,
  november: true,
  lacrosse: true,
  paradise: true,
  maryjane: true,
  spitfire: true,
  anderson: true,
  cherokee: true,
  drowssap: true,
  marshall: true,
  '1qaz2wsx': true,
  caroline: true,
  franklin: true,
  snickers: true,
  courtney: true,
  westside: true,
  patricia: true,
  semperfi: true,
  freeuser: true,
  babygirl: true,
  champion: true,
  champions: true,
  softball: true,
  security: true,
  wildcats: true,
  veronica: true,
  abcd1234: true,
  wolverin: true,
  remember: true,
  freepass: true,
  pearljam: true,
  peekaboo: true,
  budlight: true,
  electric: true,
  stargate: true,
};
