import {
  Component,
  EventEmitter,
  inject,
  Input,
  type OnDestroy,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatLegacyFormField as MatFormField } from "@angular/material/legacy-form-field";
import { ToastState } from "@cariloop/ui-components";
import type { MfaType } from "app/access/login/mfa-login/mfa-type.type";
import {
  type LoginCredentials,
  MfaService,
} from "app/shared/services/mfa.service";
import { ToastProperties } from "app/shared/services/toast.service";
import type { Subscription } from "rxjs";
import { AppState } from "../../../app.service";
import { AccountService } from "../../../shared/services/account.service";
import { ResendMfaEmailService } from "./resend-mfa-email.service";
import { ErrorReportingService } from "../../../shared/services/error-reporting.service";

const MFA_LIMIT_MS = 300_000;

function getNewMfaLimit() {
  return new Date(new Date().getTime() + MFA_LIMIT_MS);
}

@Component({
  selector: "app-mfa-login",
  templateUrl: "./mfa-login.component.html",
  styleUrls: ["./mfa-login.component.scss"],
  providers: [MfaService, ResendMfaEmailService],
})
export class MfaLoginComponent implements OnDestroy {
  @ViewChild("mfaForm", { static: false }) mfaCodeForm: NgForm;
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  @Input({ required: true })
  loginCredentials: LoginCredentials;

  readonly #mfaTokenSignal = signal("");
  get mfaToken() {
    return this.#mfaTokenSignal();
  }
  @Input({ required: true })
  set mfaToken(value: string) {
    this.#mfaTokenSignal.set(value);
  }

  @Input({ required: true })
  mfaType: MfaType;

  @Output() backToLogin = new EventEmitter<string>();
  @Output() mfaSuccess = new EventEmitter<any>();
  public error: any;
  public message: any[] = [];
  loginMessage = "";
  mfaCode = "";

  mfaTrustDevice = false;
  mfaError = 0;
  mfaErrorLabel = "MFA.LOGIN_ERROR";
  screen: "mfa" | "mfaError" = "mfa";
  public mfaIsValid = false;
  toastProperties: ToastProperties = {
    toastMessage: "MFA.EMAIL.RESEND.TOAST",
    showToast: false,
    toastType: ToastState.success,
  };

  readonly #resendMfaEmailService = inject(ResendMfaEmailService);
  readonly #resendMfaEmailSubscription: null | Subscription = null;

  protected readonly mfaLimit = signal(getNewMfaLimit());
  #resetMfaLimit() {
    this.mfaLimit.set(getNewMfaLimit());
  }

  constructor(
    private appService: AppState,
    private accountService: AccountService,
    private errorReportingService: ErrorReportingService,
  ) {
    this.#resendMfaEmailSubscription =
      this.#resendMfaEmailService.resendCode$.subscribe();
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.formFields?.forEach((ff: MatFormField) => {
        ff.updateOutlineGap();
      });
    }, 500);
    this.mfaCodeForm.valueChanges.subscribe((b) => {
      setTimeout(() => {
        this.mfaIsValid = this.mfaCode.length === 6;
      }, 250);
    });
  }

  onSubmitMfa() {
    this.accountService
      .mfaLogin({
        code: this.mfaCode,
        mfaToken: this.#mfaTokenSignal(),
        trustDevice: this.mfaTrustDevice,
      })
      .subscribe(
        (res) => {
          this.mfaSuccess.emit(res);
        },
        (err) => {
          this.error = null;

          this.mfaError++;
          this.mfaCode = "";
          if (this.mfaError > 2) {
            this.mfaErrorLabel = "MFA.LOGIN_ERROR";
            this.screen = "mfaError";
            return;
          }
          this.error = err;
          if (
            err.status &&
            (err.status === 401 ||
              err.status === 441 ||
              err.status === 461 ||
              err.status === 422)
          ) {
            return;
          }
          this.errorHandler(err);
        },
      );
  }

  private errorHandler(error, custom?: any): void {
    this.appService.status(error);
    const errObj = { error: this.appService.formatError(error) };
    if (error.status !== 451) {
      this.errorReportingService.send(error, custom);
    }
  }

  goBackToLogin(value: string) {
    this.backToLogin.emit(value);
  }

  #resendCodeThroughSMS() {
    this.accountService.resendMfaCode(this.#mfaTokenSignal()).subscribe(
      () => {},
      (err) => {
        this.error = null;
        this.mfaError++;
        if (
          err?.error?.error?.code === "MFA_CODE_SENT_SMS" ||
          err?.error?.error?.code === "MFA_CODE_SENT_EMAIL"
        ) {
          this.#resetMfaLimit();
          this.mfaCode = "";
          this.#mfaTokenSignal.set(err?.error?.error?.name);
          this.screen = "mfa";
          return;
        }
        this.error = err;
        if (
          err.status &&
          (err.status === 401 || err.status === 441 || err.status === 461)
        ) {
          return;
        }
        this.errorHandler(err);
      },
    );
  }

  requestNewCode() {
    if (this.mfaError > 2) {
      this.mfaErrorLabel = "MFA.LOGIN_ERROR";
      this.screen = "mfaError";
      return;
    }
    this.toastProperties.showToast = true;
    setTimeout(() => {
      this.toastProperties.showToast = false;
    }, 3000);

    if (this.mfaType === "sms") {
      this.#resendCodeThroughSMS();
    }

    if (this.mfaType === "email") {
      this.#resendMfaEmailService.handleResendCode({
        loginCredentials: this.loginCredentials,
        mfaType: this.mfaType,
        onResendMfaEmailSuccess: (newAccountMfaCodeToken: string) => {
          this.#resetMfaLimit();

          this.#mfaTokenSignal.set(newAccountMfaCodeToken);
        },
      });
    }
  }

  ngOnDestroy() {
    this.#resendMfaEmailSubscription?.unsubscribe();
  }
}
