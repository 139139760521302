import { Component, OnInit } from "@angular/core";
import { AccountService } from "app/shared/services/account.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-choose-adventure",
  templateUrl: "./choose-adventure.component.html",
  styleUrls: ["./choose-adventure.component.scss"],
})
export class ChooseAdventureComponent implements OnInit {
  public roles;
  public isUser;
  public isEnterprise;
  public enterpriseURL;
  public memberPortalURL;

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.roles = this.accountService.getRoles();
    this.isUser = this.roles.includes("$user");
    this.isEnterprise = this.roles.some((role) => role === "$enterprise");

    if (this.isEnterprise) {
      this.enterpriseURL = `${environment.ENTERPRISE_URL}/`;
    }
    this.memberPortalURL = this.accountService.getHomeUrl();
  }

  navigateTo(portal) {
    window.location.href = this[portal];
  }
}
