import { Component, OnInit } from "@angular/core";
import { AppState } from "../../app.service";
import { AppStateType } from "../../shared/types/app-state-type";
import { AccountService } from "../../shared/services/account.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "app/../environments/environment";
import { Observable } from "rxjs";
import { CaseService } from "../../shared/services/case.service";
import { Credential } from "app/shared/types/credential.type";
import { Account } from "app/shared/types/account.type";
import { ErrorStateMatcher } from "@angular/material/core";
import { PasswordConfirmationErrorMatcher } from "app/shared/error-matchers/password-confirmation.matcher";
import { ErrorReportingService } from "app/shared/services/error-reporting.service";
import { StorageService } from "app/shared/services/storage.service";
import {
  ProvisioningProfile,
  ProvisioningService,
} from "app/shared/services/provisioning.service";

@Component({
  selector: "app-coach-update-password",
  templateUrl: "./coach-update-password.component.html",
  styleUrls: ["./coach-update-password.component.scss"],
})
export class CoachUpdatePasswordComponent implements OnInit {
  credentials: Credential = new Credential();
  coach: Account = new Account();
  isPasswordValid = true;
  redirectTo: string;
  error: Array<string>;
  isLoading = false;
  public errorMatcher: ErrorStateMatcher;
  mfaToken = this.route.snapshot.queryParams.mfaToken;
  mfaDeviceId = this.storageService.getItem("mfaDeviceId") || "";
  mfaCode = "";
  mfaLimit = new Date(new Date().getTime() + 120000);
  mfaTrustDevice = false;
  mfaError = 0;
  mfaErrorLabel = "MFA.LOGIN_ERROR";
  screen: "login" | "mfa" | "mfaError" = this.mfaToken ? "mfa" : "login";
  public provisioningProfile: ProvisioningProfile;

  constructor(
    private appService: AppState,
    private router: Router,
    private accountService: AccountService,
    private route: ActivatedRoute,
    public errorReportingService: ErrorReportingService,
    private caseService: CaseService,
    private storageService: StorageService,
    private provisioningService: ProvisioningService,
  ) {
    this.errorMatcher = new PasswordConfirmationErrorMatcher();
  }

  ngOnInit() {
    this.redirectTo = this.route.snapshot.queryParams["redirectUrl"];
    const coach = this.accountService.getLogged();
    const isCoach = this.accountService.getRoles().some((role) => {
      return (
        role === "$coach_admin" ||
        role === "$coach" ||
        role === "$coach_assistant" ||
        role === "$engagement" ||
        role === "$coach_intake"
      );
    });
    if (coach instanceof Error || !isCoach) {
      return this.router.navigate(["/"]);
    }
    this.coach = coach;
    const appState: AppStateType = {
      authenticated: false,
      path: {
        root: "reset",
      },
    };
    this.appService.appStateUpdate(appState);
    this.provisioningService
      .getProvisioningProfile(this.coach.id)
      .subscribe((p) => {
        this.provisioningProfile = p;
      });
  }

  public onSubmit(): void {
    this.error = null;
    this.isLoading = true;
    this.mfaError = 0;
    this.changePassword();
  }

  public changePassword(): void {
    this.accountService
      .changeCoachPassword(this.coach.id, this.credentials.password)
      .subscribe(
        (account) => {
          this.loginUser(account);
        },
        (error) => {
          this.errorHandler(error);
          if (Number(error.status) === 401) {
            this.accountService.cleanLocalStorage();
            this.router.navigate(["/"]);
          }
        },
      );
  }

  private loginUser(account) {
    const data = {
      email: account.email,
      password: this.credentials.password,
      deviceId: this.mfaDeviceId,
    };
    this.accountService.login(data).subscribe(
      () => this.redirectToApp(account),
      (err) => this.errorHandler(err),
    );
  }

  redirectToApp(account: Account): void {
    this.isLoading = false;
    const roles = this.accountService.getRoles();
    const isCoach = roles.some((role) => role === "$coach_admin");
    const isAdmin = roles.some((role) => role === "$admin");
    const isCoachIntake = roles.some((role) => role === "$coach_intake");
    const isEngagement = roles.some((role) => role === "$engagement");
    if (this.redirectTo) {
      this.getRedirectUrl(this.redirectTo).subscribe(
        (url) => (window.location.href = environment.COACH_URL + url),
      );
    } else if (isCoach || isCoachIntake) {
      window.location.href = environment.COACH_URL + "/dashboard";
    } else if (isAdmin) {
      window.location.href =
        "http://" + window.location.hostname + "/admin/dashboard";
    } else if (isEngagement) {
      window.location.href = `${environment.AUTH_URL}/engagement/`;
    } else {
      window.location.href = environment.COACH_URL + "/assigned-cases";
    }
  }

  getRedirectUrl(redirect): Observable<string> {
    return new Observable((observer) => {
      let url = "";
      const redirectParts = redirect.split("/");
      // Rewrite redirect to coach version of user portal page - discussions list
      if (/^\/case\/\w+$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/discussions";
        // Rewrite redirect to coach version of user portal page - documents
      } else if (/^\/case\/\w+\/files$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/documents";
        // Rewrite redirect to coach version of user portal page - care team
      } else if (/^\/case\/\w+\/share$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/team";
        // Rewrite redirect to coach version of user portal page - search
      } else if (/^\/case\/\w+\/search\/\w+$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/care/search";
        // Rewrite redirect to case notes for old case-details route - case details notes
      } else if (/^\/case-details\/\w+\/notes$/.test(redirect)) {
        url = "/case/" + redirectParts[2] + "/notes";
      } else {
        url = redirect;
      }
      // Rewrite redirect to coach version of user portal page - discussion
      if (/^\/discussion\/\w+/.test(redirect)) {
        this.caseService.getCaseIdFromPost(redirectParts[2]).subscribe(
          (caseId: string) => {
            url = "/case/" + caseId + "/care/discussion/" + redirectParts[2];
            observer.next(url);
            observer.complete();
          },
          (err) => {
            observer.next(url);
            observer.complete();
          },
        );
      } else {
        observer.next(url);
        observer.complete();
      }
    });
  }

  private errorHandler(err): void {
    this.isLoading = false;
    if (err?.error?.error?.code === "MFA_CODE_SENT_SMS") {
      this.mfaLimit = new Date(new Date().getTime() + 120000);
      this.mfaCode = "";
      this.mfaToken = err?.error?.error?.name;
      this.screen = "mfa";
      return;
    }
    this.error = err;
    this.errorReportingService.send(err);
  }
  onSubmitMfa() {
    this.accountService
      .mfaLogin({
        code: this.mfaCode,
        mfaToken: this.mfaToken,
        trustDevice: this.mfaTrustDevice,
      })
      .subscribe(
        (res) => {
          this.redirectToApp(res as Account);
        },
        (err) => {
          this.error = null;
          this.mfaError++;
          this.mfaCode = "";
          if (this.mfaError > 2) {
            this.mfaErrorLabel = "MFA.LOGIN_ERROR";
            this.screen = "mfaError";
            return;
          }
          this.error = err;
          if (
            err.status &&
            (err.status === 401 ||
              err.status === 441 ||
              err.status === 461 ||
              err.status === 422)
          ) {
            return;
          }
          this.errorHandler(err);
        },
      );
  }

  requestNewCode() {
    if (this.mfaError > 2) {
      this.mfaErrorLabel = "MFA.LOGIN_ERROR";
      this.screen = "mfaError";
      return;
    }
    this.accountService.resendMfaCode(this.mfaToken).subscribe(
      () => {},
      (err) => {
        this.error = null;
        this.mfaError++;
        if (err?.error?.error?.code === "MFA_CODE_SENT_SMS") {
          this.mfaLimit = new Date(new Date().getTime() + 120000);
          this.mfaCode = "";
          this.mfaToken = err?.error?.error?.name;
          this.screen = "mfa";
          return;
        }
        this.error = err;
        if (
          err.status &&
          (err.status === 401 ||
            err.status === 441 ||
            err.status === 461 ||
            err.status === 422)
        ) {
          return;
        }
        this.errorHandler(err);
      },
    );
  }
  back() {
    this.router.navigate(["login"]);
  }
}
