<div
  class="choose-adventure-container"
  style="background-image: url(&quot;assets/img/login-background.png&quot;)"
>
  <top-nav [isAdventureView]="true"></top-nav>
  <div class="welcome-text main-title centered-text">
    {{ "APP.WELCOME" | translate }}
  </div>
  <div class="welcome-text sub-title centered-text">
    {{ "APP.WELCOME_SUB_HEADER" | translate }}
  </div>

  <div class="navigation-options-container">
    <mat-card class="navigate-option customer-portal">
      <div class="circular-image">
        <img src="assets/img/customer_portal.png" alt="" />
      </div>
      <div class="mat-h5 navigation-title">
        {{ "CHOOSE_ADVENTURE.CUSTOMER_PORTAL" | translate }}
      </div>
      <div class="mat-caption navigation-description">
        {{ "CHOOSE_ADVENTURE.CUSTOMER_PORTAL_DESC" | translate }}
      </div>
      <div class="mat-button link-button" (click)="navigateTo('enterpriseURL')">
        {{ "CHOOSE_ADVENTURE.GO_TO_CUSTOMER_PORTAL" | translate }}
        <mat-icon>chevron_right</mat-icon>
      </div>
    </mat-card>
    <mat-card class="navigate-option">
      <div class="circular-image">
        <img src="assets/img/member_portal.png" alt="" />
      </div>
      <div class="mat-h5 navigation-title">
        {{ "CHOOSE_ADVENTURE.MEMBER_PORTAL" | translate }}
      </div>
      <div class="mat-caption navigation-description">
        {{ "CHOOSE_ADVENTURE.MEMBER_PORTAL_DESC" | translate }}
      </div>
      <div
        class="mat-button link-button"
        (click)="navigateTo('memberPortalURL')"
      >
        {{ "CHOOSE_ADVENTURE.GO_TO_MEMBER_PORTAL" | translate }}
        <mat-icon>chevron_right</mat-icon>
      </div>
    </mat-card>
  </div>
</div>
